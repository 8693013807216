import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from "../../CustomButtons/Button";
import {Icon} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));



export default function TableUserConnected(user) {
  const classes = useStyles();

  return (
    <Table className={classes.table} aria-label="caption table">
      <TableBody>
          <TableRow key={user.user.id}>
            <TableCell component="th" scope="row">
              {user.user.name}
            </TableCell>
            <TableCell component="th" scope="row" align="center">
              {user.user.email}
            </TableCell>
            <TableCell align="right">
              <Link to={'/admin/users/edit/' + user.user.id}>
              <Button type="button" color="warning" justIcon round>
                <Icon>edit</Icon>
              </Button>
              </Link>
              {/*<Button type="button" color="danger" justIcon round> /!*TODO convert to link *!/*/}
              {/*  <Icon>delete</Icon>*/}
              {/*</Button>*/}
            </TableCell>
          </TableRow>
      </TableBody>
    </Table>

  );
}