import React, {useEffect, useState} from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import {Icon} from "@material-ui/core";
import CardBody from "../../components/Card/CardBody";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import {makeStyles} from "@material-ui/core/styles";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import Card from "../../components/Card/Card";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import serviceCategories from "../../services/categories/serviceCategories";
import serviceSigns from "../../services/signs/signs";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "../../components/CustomButtons/Button";
import {DropzoneArea} from "material-ui-dropzone";
import serviceAddresses from "../../services/addresses/serviceAddresses";
import serviceProduct from "../../services/products/products";
import WarningAlert from "../../components/Alerts/WarningAlert";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
import serviceOdgs from "../../services/odgs/serviceOdgs";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import serviceCounties from "../../services/counties/serviceCounties";
import {createBrowserHistory} from "history";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const styles = {
    ...dashboardStyle,
    cardHeader: {
        color: "#999",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        textAlign: 'left',
        padingBottom: "3px"
    },
};

const useStyles = makeStyles(styles);
const hist = createBrowserHistory();

export default function NewProduct(props) {
    // style
    const classes = useStyles();
    // form data
    const [value, setValue] = useState('');
    const [descProd, setDescProd] = useState('');
    const [as, setAs] = useState('');
    const [terroir, setTerroir] = useState('');
    const [categorie, setCategorie] = useState([]);
    const [reconnu, setReconnu] = useState('');
    const [sv, setSv] = useState('');
    const [prodPictures, setProdPictures] = useState([]);
    const [geoJsonWeb, setGeoJsonWeb] = useState('');
    const [sign, setSign] = useState([]);
    const [odg, setOdg] = useState([]);
    const [address, setAddress] = useState('');
    const [addressName, setAddressName] = useState('');
    const [cp, setCp] = useState('');
    const [pays, setPays] = useState('France');
    const [city, setCity] = useState('');
    const [email, setMail] = useState('');
    const [tel, setTel] = useState('');
    const [url, setUrl] = useState('');

    const [county, setCounty] = useState([]);
    // objects lists for forms
    const [categories, setCategories] = useState([]);
    const [signs, setSigns] = useState([]);
    const [odgs, setOdgs] = useState([]);
    const [counties, setCounties] = useState([]);

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState(''); 
    // const [addresses, setAddresses] = useState([]);

    useEffect(() => {
        serviceCategories.getAllCategories().then(res => {
            setCategories(res.data.data);
        });
        serviceSigns.getAllSigns().then(res => {
            setSigns(res.data.data);
        });
        // serviceAddresses.getAllAddresses().then(res => {
        //     setAddresses(res.data.data);
        // });
        // serviceOdgs.getAllOdgs().then(res => {
        //     setOdgs(res.data.data);
        // })
        serviceCounties.getAllCounties().then(res => {
            setCounties(res.data.data);
        })
    }, []);

    const handleChangeCat = event => {
        let temp = categorie;
        if (event.target.checked) {
            temp.push(event.target.value);

        } else {
            let pos = temp.indexOf(event.target.value);
            if (pos !== undefined) {
                temp.splice(pos, 1);
            }
        }
        setCategorie(event.target.value);
    };

    const handleChangeOdg = event => {
        setOdg(event.target.value);
    };

    const handleChangeSigns = event => {
        let temp = sign;
        if (event.target.checked) {
            temp.push(event.target.value);

        } else {
            let pos = temp.indexOf(event.target.value);
            if (pos !== undefined) {
                temp.splice(pos, 1);
            }
        }
        setSign(temp);
    };

    const handleChangePictures = (files) => {
        setProdPictures(files);
    };

    const handleChangeGeo = (files) => {
        try {
            let reader = new FileReader();
            reader.readAsText(files[0]);
            reader.onload = function () {
                setGeoJsonWeb(reader.result);
            };
        } catch (error) {
            setGeoJsonWeb('');
        }

    };

    const isFormValid = () => {
        if (!value.length) {
            WarningAlert({
                message: "L'appellation doit être renseignée",
            });
            return false;
        }
        if (!title) {
            WarningAlert({
                message: "Le titre doit être renseigné",
            });
            return false;
        }
        if (!description) {
            WarningAlert({
                message: "La description doit être renseignée",
            });
            return false;
        }
        // if (!categories) {
        //     WarningAlert({
        //         message: "La catégorie doit être renseigné",
        //     });
        //     return false;
        // }
        if (!descProd) {
            WarningAlert({
                message: "La description produit doit être renseignée",
            });
            return false;
        }
        if (!as) {
            WarningAlert({
                message: "Arômes & saveurs doit être renseigné",
            });
            return false;
        }
        if (!terroir) {
            WarningAlert({
                message: "La description territoire doit être renseignée",
            });
            return false;
        }
        if (!reconnu) {
            WarningAlert({
                message: "La decription du produit reconnu doit être renseignée",
            });
            return false;
        }
        if (!sv) {
            WarningAlert({
                message: "La decription 'saviez-vous' du produit doit être renseignée",
            });
            return false;
        }
        // if (!prodPictures.length) {
        //     WarningAlert({
        //         message: "Au moins une image doit être importée",
        //     });
        //     return false;
        // }
        if (!geoJsonWeb.length) {
            WarningAlert({
                message: "Le geojson doit être importé",
            });
            return false;
        }
        if (!sign.length) {
            WarningAlert({
                message: "Au moins un signe doit être sélectionné",
            });
            return false;
        }
        if (!sign.length) {
            WarningAlert({
                message: "Au moins une région doit être sélectionnée",
            });
            return false;
        }
        if (
            !address
            || !addressName
            || !cp
            || !city
            || !email
        ) {
            WarningAlert({
                message: "Une adresse doit être complètement saisie",
            });
            return false;
        }
        // if (!odg.length) {
        //     WarningAlert({
        //         message: "Un ODG doit être sélectionné",
        //     });
        //     return false;
        // }
        return true;
    };

    const handleSave = async () => {
        // valid
        if (isFormValid()) {
            await serviceProduct.newProduct(value, tel, url, geoJsonWeb).then(async (res) => {

                const id = res.data.data.id;
                // product pictures
                await prodPictures.map( async (row) => {
                   await serviceProduct.uploadPicture(row.name, row, id).then();
                });
                // signs
                await sign.map(async (row) => {
                    await serviceSigns.linkSignProduct(row, id).then()
                });
                // categorie
                await serviceCategories.linkCatProduct(categorie, id).then();
                // producteurs / address
                await serviceAddresses.createAddress(addressName, address, city, pays, cp, tel, url, email, true, id, true).then( async (data) => {
                    console.log(data)
                    serviceAddresses.linkAddressProduct(data.data.data.id , id).then();
                });
                // description
                await serviceProduct.addDescription(id, descProd).then();
                await serviceProduct.addCertification(id, reconnu).then();
                await serviceProduct.addFlavor(id, as).then();
                await serviceProduct.addTerroir(id, terroir).then();
                await serviceProduct.addInfo(id, sv).then();

                await county.map(async (row) => {
                    await serviceCounties.linkCountiesProduct(row, id ).then();
                });

                await odg.map(async (row) => {
                    await serviceOdgs.createOdgProduct(id, row).then();
                });



                await serviceProduct.isProductValid(id).then(ressus => {
                    console.log(ressus.data.data)
                    if (ressus.data.data.status === true) {
                        SuccessAlert({message: "Produit créé avec succès"});
                        props.history.push('/admin/edit/products/' + ressus.data.data.data.product.id);
                    }
                    else {
                        SuccessAlert({message: "Envoi du produit impossible, veuillez vérifier les champs"});
                    }
                });

                await serviceProduct.createProductSEO(id, title, description).then();

            }).catch((err) => {
                    ErrorAlert({message: 'Une erreur est survenue'});
                    console.log(err);
                }
            );
        }
    };

    return (
        <>
            <Button onClick={hist.goBack}> Retour </Button>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={8}>
                    <GridContainer>
                        {/*General*/}
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color={"rose"} icon>
                                    <CardIcon color={"rose"}>
                                        <Icon>
                                            store
                                        </Icon>
                                    </CardIcon>
                                    <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                        Présentation générale
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField label="Appellation" value={value}
                                                   onChange={(event) => setValue(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <InputLabel>Catégorie</InputLabel>
                                        <Select
                                            // onChange={event => setCategories(event.target.value)}
                                            onChange={handleChangeCat}
                                            value={categorie}
                                            multiple={true}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {categories.map(cat => {
                                                return (
                                                    <MenuItem key={cat.id} value={cat.id}>{cat.value}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color={"warning"} icon>
                                    <CardIcon color={"warning"}>
                                        <Icon>
                                            account_box
                                        </Icon>
                                    </CardIcon>
                                    <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                        Tags SEO
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField multiline label="Titre" value={title}
                                                    onChange={(event) => setTitle(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField multiline label="Description" value={description}
                                                    onChange={(event) => setDescription(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                </CardBody>
                            </Card>
                        </GridItem>
                        {/*Descriptions*/}
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color={"info"} icon>
                                    <CardIcon color={"info"}>
                                        <Icon>
                                            short_text
                                        </Icon>
                                    </CardIcon>
                                    <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                        Descriptions du produit
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                            Le Produit
                                        </label>
                                        <CKEditor
                                          data={descProd}
                                          editor={ClassicEditor}
                                          onChange={(event, editor) => {
                                              const data = editor.getData();
                                              setDescProd(data);
                                          }}
                                          onBlur={(event, editor) => {
                                              setDescProd(editor.getData());
                                          }}
                                          onFocus={(event, editor) => {
                                              setDescProd(editor.getData());
                                          }}
                                        />
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                            Arômes et saveurs
                                        </label>
                                        <CKEditor
                                          data={as}
                                          editor={ClassicEditor}
                                          onChange={(event, editor) => {
                                              const data = editor.getData();
                                              setAs(data);
                                          }}
                                          onBlur={(event, editor) => {
                                              setAs(editor.getData());
                                          }}
                                          onFocus={(event, editor) => {
                                              setAs(editor.getData());
                                          }}
                                        />
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                            Un produit, un territoire
                                        </label>
                                        <CKEditor
                                          data={terroir}
                                          editor={ClassicEditor}
                                          onChange={(event, editor) => {
                                              const data = editor.getData();
                                              setTerroir(data);
                                          }}
                                          onBlur={(event, editor) => {
                                              setTerroir(editor.getData());
                                          }}
                                          onFocus={(event, editor) => {
                                              setTerroir(editor.getData());
                                          }}
                                        />
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                            Un produit reconnu
                                        </label>
                                        <CKEditor
                                          data={reconnu}
                                          editor={ClassicEditor}
                                          onChange={(event, editor) => {
                                              const data = editor.getData();
                                              setReconnu(data);
                                          }}
                                          onBlur={(event, editor) => {
                                              setReconnu(editor.getData());
                                          }}
                                          onFocus={(event, editor) => {
                                              setReconnu(editor.getData());
                                          }}
                                        />
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                            Le saviez-vous ?
                                        </label>
                                        <CKEditor
                                          data={sv}
                                          editor={ClassicEditor}
                                          onChange={(event, editor) => {
                                              const data = editor.getData();
                                              setSv(data);
                                          }}
                                          onBlur={(event, editor) => {
                                              setSv(editor.getData());
                                          }}
                                          onFocus={(event, editor) => {
                                              setSv(editor.getData());
                                          }}
                                        />
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                </CardBody>
                            </Card>
                        </GridItem>
                        {/* addresses */}
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color={"warning"} icon>
                                    <CardIcon color={"warning"}>
                                        <Icon>
                                            account_box
                                        </Icon>
                                    </CardIcon>
                                    <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                        Adresse
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField label="Intitulé" value={addressName}
                                                   onChange={(event) => setAddressName(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField label="Adresse" value={address}
                                                   onChange={(event) => setAddress(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField label="Ville" value={city}
                                                   onChange={(event) => setCity(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField label="Code Postal" value={cp}
                                                   onChange={(event) => setCp(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField label="Pays" value={pays}
                                                   onChange={(event) => setPays(event.target.value)}/>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField label="Email" value={email}
                                                   onChange={(event) => setMail(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField label="Site Web" value={url}
                                                   onChange={(event) => setUrl(event.target.value)}/>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField label="Téléphone" value={tel}
                                                   onChange={(event) => setTel(event.target.value)}/>
                                    </FormControl>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={4}>
                    <GridContainer>
                        {/*Signes*/}
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color={"primary"} icon>
                                    <CardIcon color={"primary"}>
                                        <Icon>
                                            local_offer
                                        </Icon>
                                    </CardIcon>
                                    <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                        Choix des signes
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    <FormControl component="fieldset" className={classes.formControl}>
                                        <FormGroup>
                                            {signs.map(data => {
                                                return (
                                                    <FormControlLabel
                                                        key={data.id}
                                                        control={<Checkbox onChange={handleChangeSigns}
                                                                           value={data.id}/>}
                                                        label={data.short_form}
                                                    />
                                                )
                                            })}
                                        </FormGroup>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                </CardBody>
                            </Card>
                        </GridItem>
                        {/*ODG*/}
                        {/*<GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color={"warning"} icon>
                                    <CardIcon color={"warning"}>
                                        <Icon>
                                            school
                                        </Icon>
                                    </CardIcon>
                                    <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                        Choix ODG
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <InputLabel>ODG</InputLabel>
                                        <Select
                                            onChange={handleChangeOdg}
                                            value={odg}
                                            multiple={true}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {odgs.map(data => {
                                                return (
                                                    <MenuItem key={data.id} value={data.id}>{data.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                </CardBody>
                            </Card>
                        </GridItem>
                        */}
                        {/*ODG*/}
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color={"warning"} icon>
                                    <CardIcon color={"warning"}>
                                        <Icon>
                                            map
                                        </Icon>
                                    </CardIcon>
                                    <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                        Choix Départements
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <InputLabel>Départements</InputLabel>
                                        <Select
                                            onChange={event => setCounty(event.target.value)}
                                            value={county}
                                            multiple={true}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {counties.map(data => {
                                                return (
                                                    <MenuItem key={data.id} value={data.id}>{data.value}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                </CardBody>
                            </Card>
                        </GridItem>
                        {/*Pictures*/}
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color={"success"} icon>
                                    <CardIcon color={"success"}>
                                        <Icon>
                                            add_photo_alternate
                                        </Icon>
                                    </CardIcon>
                                    <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                        Coin photos
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    <DropzoneArea
                                        acceptedFiles={["image/*"]}
                                        dropzoneText={"Déposer un fichier ici"}
                                        onChange={handleChangePictures}
                                        showPreviewsInDropzone={false}
                                        showPreviews={true}
                                        filesLimit={5}
                                    />
                                    <FormHelperText>*</FormHelperText>
                                </CardBody>
                            </Card>
                        </GridItem>
                        {/*Geo json*/}
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color={"rose"} icon>
                                    <CardIcon color={"rose"}>
                                        <Icon>
                                            location_on
                                        </Icon>
                                    </CardIcon>
                                    <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                        Géolocalisation du produit
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    <DropzoneArea
                                        acceptedFiles={['text/*', 'application/*', '']}
                                        dropzoneText={'Déposer un fichier ici'}
                                        onChange={handleChangeGeo}
                                        showPreviewsInDropzone={true}
                                        showPreviews={false}
                                        filesLimit={1}
                                    />
                                    <FormHelperText>*</FormHelperText>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </GridItem>
                {/*Submit button*/}
                <GridItem xs={12}>
                    <Button
                        fullWidth
                        color={'info'}
                        onClick={handleSave}>
                        <Icon>check</Icon>&nbsp;Sauvegarder
                    </Button>
                </GridItem>
            </GridContainer>
        </>
    );


}
