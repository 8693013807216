import axios from "axios";
import config from "../config/config";

class Global {

    /**
     *
     * @returns {string}
     */
    getAPI_URL() {
        return config.API_URL;
    }

    /**
     *
     * @returns {Promise<AxiosResponse<T>>}
     */
    getUser(){
        return axios.get(config.API_URL + "users/me/connected", this.getAuthHeader());
    }

    /**
     *
     * @returns {string}
     */
    getUserInfo(){
        return localStorage.getItem("userInfo");
    }
    disconnect(){
        return localStorage.removeItem("userInfo");
    }

    /**
     *
     * @returns {{headers: {Authorization: *}}}
     */
    getAuthHeader() {
        let Bearer = `Bearer ${this.getUserInfo()}`;
        return {headers: {Authorization: Bearer }};
    }

    /**
     *
     * @returns {{headers: {Authorization: string, "Content-Type": string}}}
     */
    getAuthHearderFormMultipart() {
        return {
            headers: {
            Authorization: `Bearer ${this.getUserInfo()}`,
            'Content-Type': 'multipart/form-data'
            }
        };
    }

}

export default Global;