import React, {useEffect, useState} from "react";

import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "../Card";
import CardHeader from "../CardHeader";
import CardBody from "../CardBody";
import serviceCategories from "../../../services/categories/serviceCategories";
import serviceSigns from "../../../services/signs/signs";
import products from "../../../services/products/products";

const styles = {
    ...dashboardStyle,
    cardTitle: {
        marginTop: "0",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        textAlign: 'center'
    },
    centered: {
        textAlign: 'center'
    }
};
const useStyles = makeStyles(styles);

export default function CardInfoGeneral({id}) {
    // style
    const classes = useStyles();
    const defaultEmpty = "Ce champ n'est pas renseigné";
    // states
    const [title, setTitle] = useState('');
    const [signs, setSigns] = useState([]);
    const [cats, setCats] = useState([]);
    // init
    useEffect(() => {

        products.getProduct(id).then(res => {
            setTitle(res.data.data.value);
        });
        serviceCategories.getListCategoryProductFromProduct(id).then(res => {
            setCats(res.data.data);
        });
        serviceSigns.getAllSignsForProduct(id).then(res => {
            setSigns(res.data.data);
        })
    }, []);
    return (
        <Card>
            <CardHeader color="rose">
                <h4 className={classes.cardTitle}>
                    Informations générales
                </h4>
            </CardHeader>
            <CardBody className={classes.centered}>
                <h6 className={classes.cardCategory}>
                    Appellation
                </h6>
                <h6>
                    {title}
                </h6>
                <h6 className={classes.cardCategory}>
                    SIGNE OFFICIEL
                </h6>

                {signs.length ? signs.map(res => {
                    return (
                        <h6 key={res.id}>
                            {res.value}
                        </h6>
                    )
                }) : defaultEmpty}

                <h6 className={classes.cardCategory}>
                    CATÉGORIE
                </h6>

                {cats.length ? cats.map(res => {
                    return (
                        <h6 key={res.id}>
                            {res.value}
                        </h6>
                    )
                }) : defaultEmpty}

            </CardBody>
        </Card>
    );
}