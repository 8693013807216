import React, {useEffect, useState} from "react";

import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "../Card";
import CardHeader from "../CardHeader";
import CardBody from "../CardBody";
import serviceProducts from "../../../services/products/products";
import AMap from "../../Map/AMap";
import CarrouselProductsPics from "../../Caroussels/CarrouselProductsPics";

const styles = {
    ...dashboardStyle,
    cardTitle: {
        marginTop: "0",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        textAlign: 'center'
    },
    centered: {
        textAlign: 'center'
    }
};
const useStyles = makeStyles(styles);

export default function CardDisplayProduct({id}) {
    // style
    const classes = useStyles();
    const defaultEmpty = "Ce champ n'est pas renseigné";
    // states
    const [title, setTitle] = useState('');
    const [geoJson, setGeoJson] = useState(null);
    const [certifacations, setCertifacations] = useState([]);
    const [infos, setInfos] = useState([]);
    const [flavors, setFlavors] = useState([]);
    const [descriptions, setDescriptions] = useState([]);
    const [terroirs, setTerroirs] = useState([]);

    // init
    useEffect(() => {
            serviceProducts.getProduct(id).then(res => {
                setTitle(res.data.data.value);
                setGeoJson(res.data.data.geojson_web);
            });
            serviceProducts.getAllCertificationsFromProduct(id).then(res => {
                setCertifacations(res.data.data);
            });
            serviceProducts.getAllInfosFromProduct(id).then(res => {
                setInfos(res.data.data);
            });
            serviceProducts.getAllFlavorsFromProduct(id).then(res => {
                setFlavors(res.data.data);
            });
            serviceProducts.getAllDescriptionsFromProduct(id).then(res => {
                setDescriptions(res.data.data);
            });
            serviceProducts.getAllTerroirFromProduct(id).then(res => {
                setTerroirs(res.data.data);
            });

        }, []
    )
    ;
    return (
        <Card>
            <CardHeader color="primary">
                <h4 className={classes.cardTitle}>
                    {title}
                </h4>
            </CardHeader>
            <CardBody className={classes.centered}>

                <CarrouselProductsPics id={id}/>

                <h6 className={classes.cardCategory}>
                    LE PRODUIT
                </h6>
                {descriptions.length ? descriptions.map(res => {
                    return (
                        <h6 key={res.id}>
                            {res.value}
                        </h6>
                    )
                }) : defaultEmpty}
                <h6 className={classes.cardCategory}>
                    ARÔMES & SAVEURS
                </h6>
                {flavors.length ? flavors.map(res => {
                    return (
                        <h6 key={res.id}>
                            {res.value}
                        </h6>
                    )
                }) : defaultEmpty}
                <h6 className={classes.cardCategory}>
                    UN PRODUIT, UN TERRITOIRE
                </h6>
                {terroirs.length ? terroirs.map(res => {
                    return (
                        <h6 key={res.id}>
                            {res.value}
                        </h6>
                    )
                }) : defaultEmpty}
                <h6 className={classes.cardCategory}>
                    UN PRODUIT RECONNU
                </h6>
                {certifacations.length ? certifacations.map(res => {
                    return (
                        <h6 key={res.id}>
                            {res.value}
                        </h6>
                    )
                }) : defaultEmpty}
                <h6 className={classes.cardCategory}>
                    LE SAVIEZ-VOUS ?
                </h6>
                {infos.length ? infos.map(res => {
                    return (
                        <h6 key={res.id}>
                            {res.value}
                        </h6>
                    )
                }) : defaultEmpty}
                <h6 className={classes.cardCategory}>
                    LA SITUATION GEOGRAPHIQUE
                </h6>
                {geoJson === null ? null : <AMap data={geoJson}/>}
            </CardBody>
        </Card>
    );
}