import React, {useEffect, useState} from "react";
import GridContainer from "../../Grid/GridContainer";
import TextField from "@material-ui/core/TextField";
import Button from "../../../components/CustomButtons/Button.js";
import SuccessAlert from "../../Alerts/SuccessAlert";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import GridItem from "../../Grid/GridItem.js";
import serviceCounties from "../../../services/counties/serviceCounties";

/*
* label
* code
* */

export default function FormEditCounty(props) {
  const id = props.props.match.params.id;

  const [value, setValue] = useState('');
  const [code, setCode] = useState('');
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    serviceCounties.getCounty(id).then(res => {
      const data = res.data.data;
      setValue(data.value);
      setCode(data.code);
    })
  }, [id]);

  useEffect(() => {
    IsFormValid();
  },[value, code]);

  const IsFormValid = () => {
    if (value !== '' && code !== '') {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  };

  const handleFormSubmit = () => {
    serviceCounties.editCounty(id, value, code).then(res => {
      SuccessAlert({message: res.data.message});
      props.props.history.push('/admin/counties');

    }).catch(() => {
      Swal.fire('Une erreur est survenue !');
    });

  };

  return (
    <>
      <GridContainer
        justify="center"
        alignItems="center"
      >
        <GridItem xs={12}>
          <TextField
            label="Label"
            style={{margin: 8}}
            placeholder="Label"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            value={value}
            onChange={async e => {
              setValue(e.target.value);
            }}
          />
          <TextField
            label="Code"
            style={{margin: 8}}
            placeholder="Code"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            value={code}
            onChange={async e => {
              setCode(e.target.value);
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer
        justify="center"
        alignItems="center"
      >
        <GridItem>
          <Button
            color={"success"}
            disabled={!formValid}
            onClick={handleFormSubmit}
          >
            Modifier
          </Button>
        </GridItem>
        <GridItem>
          <Link to={'/admin/counties'}>
            <Button
              color={'danger'}
            >
              Retour
            </Button>
          </Link>
        </GridItem>
      </GridContainer>
    </>
  );
}
