import React, {useEffect, useState} from "react";
import signsService from "../../../services/signs/signs"
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "../../CustomButtons/Button";
import {Icon} from "@material-ui/core";
import Swal from "sweetalert2";
import SuccessAlert from "../../Alerts/SuccessAlert";
import ErrorAlert from "../../Alerts/ErrorAlert";


export default function FormEditSignPictures({id}) {

    // form
    const [keys, setKeys] = useState([]);

    useEffect(() => {
        signsService.getKeysNumbersFor(id).then(res => {
            setKeys(res.data.data);
        });

    }, []);

    const deleteKeyNumber = (key_id) => {
        Swal.fire({
            title: 'Confirmez-vous ?',
            text: "Cette action est irréversible !",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui, supprimer !',
            cancelButtonText: 'Annuler'
        }).then((result) => {
            if (result.value) {
                signsService.deleteKeyNumber(key_id).then(() => {
                    SuccessAlert({message: 'Chiffre clé Supprimé !'});
                    signsService.getKeysNumbersFor(id).then(res => {
                        setKeys(res.data.data);
                    });
                }).catch(() => {
                    ErrorAlert({message: 'Une erreur est survenue'})
                });
            }
        });
    };
    const editKeyNumber = async (key_id, number, desc) => {
        const { value: formValues } = await Swal.fire({
            title: 'Modifier ' + desc,
            html:
                '<input id="swal-input1" class="swal2-input" type="number" value="' + number + '" />' +
                '<input id="swal-input2" class="swal2-input" type="text" value="' + desc + '" />',
            focusConfirm: false,
            preConfirm: () => {
                return [
                    document.getElementById('swal-input1').value,
                    document.getElementById('swal-input2').value
                ]
            }
        });

        if (formValues) {
            signsService.editSignKeyNumber(key_id, parseInt(formValues[0]), formValues[1], id ).then(() => {
                SuccessAlert({message: 'Chiffre clé modifié avec succès'});
                signsService.getKeysNumbersFor(id).then(res => {
                    setKeys(res.data.data);
                });
            }).catch(() => {
                ErrorAlert({message: 'Une Erreur est survenue'})
            });
        }
    };
    const addKeyNumber = () => {
        Swal.mixin({
            input: 'text',
            confirmButtonText: 'Suivant',
            cancelButtonText: 'Annuler',
            showCancelButton: true,
            progressSteps: ['1', '2',]
        }).queue([
            {
                title: 'Chiffre',
                text: 'Chiffre Clé'
            },
            {
                title: 'Description',
                text: 'description du chiffre clé'
            },
        ]).then((result) => {
            if (result.value) {
                signsService.addKeyNumber(id, result.value[1], parseInt(result.value[0])).then(() => {
                        SuccessAlert({message: 'Chiffre Clé ajouté avec succès'});
                        signsService.getKeysNumbersFor(id).then(res => {
                            setKeys(res.data.data);
                        });
                    }
                ).catch(
                    () => {
                        ErrorAlert({message: 'Une erreur est survenue'})
                    }
                )

            }
        })
    };

    return (
        <>
            <GridContainer>
                <GridItem xs={12}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Chiffre
                                </TableCell>
                                <TableCell>
                                    Description
                                </TableCell>
                                <TableCell>
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {keys ? keys.map(row => (
                                <TableRow key={row.id}>
                                    <TableCell>
                                        {row.key_number}
                                    </TableCell>
                                    <TableCell>
                                        {row.description}
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            justIcon
                                            round
                                            onClick={() => editKeyNumber(row.id, row.key_number , row.description)}
                                            color={'success'}
                                        >
                                            <Icon>edit</Icon>
                                        </Button>
                                        <Button
                                            justIcon
                                            round
                                            onClick={() => deleteKeyNumber(row.id)}
                                            color={'danger'}
                                        >
                                            <Icon>delete</Icon>
                                        </Button>

                                    </TableCell>
                                </TableRow>
                            )) : <TableRow/>}
                        </TableBody>
                    </Table>
                </GridItem>
                <GridItem xs={12}>
                    <Button
                        color={'success'}
                        onClick={() => addKeyNumber()}
                    >
                        Ajouter un chiffre clé
                    </Button>
                </GridItem>
            </GridContainer>
        </>
    );
}
