import React, {useEffect} from "react";
import TableImagesListSigns from "../../Table/Signs/TableImagesListSigns";


export default function FormEditSignPictures({id}) {

    useEffect(() => {


    }, []);

    return (
        <>
            <TableImagesListSigns Sign={id}/>
        </>
    );
}
