import Global from "../global";
import axios from 'axios';


class serviceMetaSEO extends Global {

    getMetaSEO() {
        return axios.get(this.getAPI_URL() + "metaseo", this.getAuthHeader());
    }

    updateMetaSEO(id, default_title, default_description, default_image_path, title_contact, title_map, contact_desc, map_desc) {
        let params = {
            id: id,
            default_title: default_title,
            default_description: default_description,
            default_image_path: default_image_path,
            title_contact: title_contact,
            title_map: title_map,
            contact_desc: contact_desc,
            map_desc: map_desc,
            /*
                        google_anlytics_tracker_id: google_anlytics_tracker_id,
            */
        };
        return axios.put(this.getAPI_URL() + "metaseo", params, this.getAuthHeader());
    }
}

export default new serviceMetaSEO();
