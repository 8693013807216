import React, {useEffect, useState} from "react";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "../../../components/CustomButtons/Button.js";
import {Icon} from "@material-ui/core";
import Swal from "sweetalert2";
import products from "../../../services/products/products";
import LinkVisuImage from "../../Images/LinkVisuImage";
import {Link} from "react-router-dom";

export default function TableImagesList({Product}) {

    const [images, setImages] = useState([]);

    useEffect(() => {

        products.getAllImagesFromProduct(Product).then(res => {
            setImages(res.data.data)});

    }, [Product]);


    const DeleteImage = (id) => {
        Swal.fire({
            title: 'Confirmez-vous ?',
            text: "Cette action est irréversible !",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui, supprimer !',
            cancelButtonText: 'Annuler'
        }).then((result) => {
            if (result.value) {
                products.deleteImage(id).then(() => {
                    Swal.fire(
                        'Image Supprimée !',
                        'Image Supprimée !',
                        'success'
                    ).then();
                    products.getAllImagesFromProduct(Product).then(res => setImages(res.data.data));
                }).catch(() => {
                    Swal.fire(
                        'Une erreur est survenue',
                        'error'
                    ).then();
                });

            }
        })

    };

    return (
        <Table aria-label="Terroirs">
            <TableHead>
                <TableRow>
                    <TableCell>
                        Nom Fichier
                    </TableCell>
                    <TableCell>
                        Crédit
                    </TableCell>
                    <TableCell>
                        Rang
                    </TableCell>
                    <TableCell align="right">
                        Actions
                    </TableCell>

                </TableRow>
            </TableHead>
            <TableBody>
                {images ? images.map(row => (
                    <TableRow key={row.id}>
{/*
                        max-width: 100px;
*/}
                        <TableCell component="th" scope="row" style={{maxWidth: "100px"}}>
                            {row.title}
                        </TableCell>
                        <TableCell component="th" scope="row" style={{maxWidth: "100px"}}>
                            {row.credit}
                        </TableCell>
                        <TableCell component="th" scope="row" style={{maxWidth: "100px"}}>
                            {row.ranking}
                        </TableCell>
                        <TableCell align="right">
                            <LinkVisuImage
                                text={row.title}
                                idImage={row.id}
                                credit={row.credit}
                            />
                            <Link to={'/admin/products/' + Product + '/pictures/' + row.id + '/edit'}>
                                <Button
                                    round
                                    color="success"
                                    justIcon
                                >
                                    <Icon>
                                        edit
                                    </Icon>
                                </Button>
                            </Link>
                            <Button
                                round
                                color="danger"
                                justIcon
                                onClick={() => DeleteImage(row.id)}
                            >
                                <Icon>
                                    delete
                                </Icon>
                            </Button>

                        </TableCell>
                    </TableRow>
                )) : <TableRow/>}
            </TableBody>
        </Table>
    );
}



