import Swal from "sweetalert2";

export default function WarningAlert({message = 'Warning', confirm = false, position='center'})  {
    Swal.fire({
        title:  message,
        position: position,
        icon: 'warning',
        showConfirmButton: confirm,
        timer: 3000
    }).then()
}