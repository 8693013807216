import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CardHeader from "../Card/CardHeader";
import React from "react";
import { Icon } from "@material-ui/core";
import CardIcon from "../Card/CardIcon";

const stylesBis = {
  ...dashboardStyle,
  cardTitle: {
    // marginTop: "15px",
    minHeight: "auto",
    paddingTop: "10px",
    // fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "auto",
    color: "#000000"
  }
};

const useStyles = makeStyles(stylesBis);

export default function CardTitleIconPlusTextRight(props) {

  const { color, icon, title } = props;
  const classes = useStyles();
  return (
    <>
      <CardHeader color={color} icon>
        <CardIcon color={color}>
          <Icon>{icon}</Icon>
        </CardIcon>
        <h5 className={classes.cardHeader} style={{color: '#999'}}>
          {title}
        </h5>
      </CardHeader>
    </>
  );
}
