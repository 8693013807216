import React, {useEffect, useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle";
import {makeStyles} from "@material-ui/core/styles";
import serviceCounties from "../../../services/counties/serviceCounties"
import serviceProduct from "../../../services/products/products";
import SuccessAlert from "../../Alerts/SuccessAlert";


const styles = {
    ...dashboardStyle,
    cardHeader: {
        color: "#999",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        textAlign: 'left',
        padingBottom: "3px"
    },
};

const useStyles = makeStyles(styles);


export default function FormEditCounties({id}) {
    const classes = useStyles();
    const [county, setCounty] = useState([]);
    // objects lists for forms
    const [counties, setCounties] = useState([]);

    useEffect(() => {
        serviceCounties.getAllCounties().then(res => {
            setCounties(res.data.data);
        });

        serviceCounties.getListCountiesProductFromProduct(id).then(res => {
            res.data.data.forEach(data => {
                setCounty(oldArray => [...oldArray, data.id ]); // TODO
            });
        });
    }, [id]);

    const handleChange = event => {
        county.forEach(element => {
            if (!event.target.value.includes(element)) {
                serviceProduct.deleteCounty(id, element).then((res) => {
                    setCounty(event.target.value);
                    if (res.status === 200){
                        SuccessAlert({message: "Département retiré avec succès"});
                    }
                });
            }
        });

        event.target.value.forEach(element => {
            if (!county.includes(element)) {
                serviceProduct.addCounty(id, element).then((res) => {
                    setCounty(event.target.value);
                    if (res.status === 201){
                        SuccessAlert({message: "Département ajouté avec succès"});
                    }
                });
            }
        })

        setCounty(event.target.value);

        /*setCounties.deleteOdgsFromProduct(id).then();

        serviceProduct.(id , event.target.value).then();*/

    };

    return (
        <>
            <FormControl fullWidth className={classes.formControl}>
                <InputLabel>Départements</InputLabel>
                <Select
                    onChange={handleChange}
                    value={county}
                    multiple
                >
                    <MenuItem value="">
                        <em>Aucune</em>
                    </MenuItem>
                    {counties.map(row => {
                        return (
                            <MenuItem key={row.id} value={row.id}>
                                {row.value}
                            </MenuItem>
                        )
                    })}
                </Select>
                <FormHelperText>*</FormHelperText>
            </FormControl>
        </>
    );
}
