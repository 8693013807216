import Global from "../global";
import axios from 'axios';


class serviceGeneral extends Global {

    getGeneral() {
        return axios.get(this.getAPI_URL() + "general", this.getAuthHeader());
    }

    updateGeneral(id, instagram_path, facebook_page, footer_address_path, footer_phone_path, footer_logo_five_signs_path, footer_mentions_path, menu_accueil, menu_tous_les_produits, menu_carte, menu_signes_officiels, menu_about, menu_contact, content_allow_signs, content_allow_categories, footer_logo) {
        let params = {
            id: id,
            instagram_path: instagram_path,
            facebook_page: facebook_page,
            footer_address_path: footer_address_path,
            footer_phone_path: footer_phone_path,
            footer_logo_five_signs_path: footer_logo_five_signs_path,
            footer_mentions_path: footer_mentions_path,
            menu_accueil: menu_accueil,
            menu_tous_les_produits: menu_tous_les_produits,
            menu_carte: menu_carte,
            menu_signes_officiels: menu_signes_officiels,
            menu_about: menu_about,
            menu_contact: menu_contact,
            content_allow_signs: content_allow_signs,
            content_allow_categories: content_allow_categories,
            footer_logo: footer_logo
        };
        return axios.put(this.getAPI_URL() + "general", params, this.getAuthHeader());
    }
}

export default new serviceGeneral();