import React from 'react'
import {Map as LeafletMap, TileLayer, GeoJSON} from 'react-leaflet';
import GJV from "geojson-validation";

export default function AMap({data}) {
    const geoJSONStyle = {
        color: '#1f2021',
        weight: 1,
        fillOpacity: 0.5,
        fillColor: '#fff2af',
    };
    const onEachFeature = (feature: Object, layer: Object) =>  {
        const popupContent = ` <Popup><p>Customizable Popups <br />with feature information.</p><pre>Borough: <br />${feature.properties.name}</pre></Popup>`
        layer.bindPopup(popupContent)
    };
    return (
        <>
            <LeafletMap
                center={[47.037554, 2.368631]}
                zoom={5}
                attributionControl={true}
                zoomControl={true}
                doubleClickZoom={true}
                scrollWheelZoom={true}
                dragging={true}
                animate={true}
                easeLinearity={0.35}
            >
                <TileLayer
                    url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
                />
                {GJV.valid(JSON.parse(data)) ?    <GeoJSON
                    data={JSON.parse(data)}
                    style={geoJSONStyle}
                    onEachFeature={onEachFeature}
                />:null }


            </LeafletMap>
        </>
    );

}