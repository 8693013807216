import React, {useEffect, useState} from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import {Icon} from "@material-ui/core";
import CardBody from "../../components/Card/CardBody";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import {makeStyles} from "@material-ui/core/styles";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import Card from "../../components/Card/Card";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "../../components/CustomButtons/Button";
import {DropzoneArea} from "material-ui-dropzone";
import WarningAlert from "../../components/Alerts/WarningAlert";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
import serviceOdgs from "../../services/odgs/serviceOdgs";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import serviceGeneral from "../../services/general/serviceGeneral";
import {createBrowserHistory} from "history";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import serviceRxSociaux from "services/rxSociaux/serviceRxSociaux.js";
import {Person} from "@material-ui/icons";

const styles = {
    ...dashboardStyle,
    cardHeader: {
        color: "#999",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        textAlign: 'left',
        padingBottom: "3px"
    },
};

const useStyles = makeStyles(styles);
const hist = createBrowserHistory();

export default function EditRxSociaux(props) {
// style
    const classes = useStyles();
// form data
    const [id, setId] = useState('');
    const [instagram_path, setInstagramPath] = useState('');
    const [facebook_page, setFacebookPage] = useState('');


    useEffect(() => {
        serviceRxSociaux.getRxSociaux().then(res => {
            setId(res.data.data.id);
            setInstagramPath(res.data.data.instagram_path);
            setFacebookPage(res.data.data.facebook_page);

        });
    }, []);

    const handleSave = async () => {
        await serviceRxSociaux.updateRxSociaux(id, instagram_path, facebook_page).then(async (res) => {
            SuccessAlert({message: res.data.message});
        }).catch((err) => {
                ErrorAlert({message: 'Une erreur est survenue'});
                console.log(err);
            }
        );
    };

    return (
        <>
            <Button onClick={hist.goBack}> Retour </Button>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={6}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color={"rose"} icon>
                                    <CardIcon color={"rose"}>
                                        <Icon>
                                            person
                                        </Icon>
                                    </CardIcon>
                                    <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                        Lien du réseau social Instagram
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField multiline label="Chemin du lien" value={instagram_path}
                                                   onChange={(event) => setInstagramPath(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>

                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </GridItem>

                <GridItem xs={12} sm={12} md={12} lg={6}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color={"rose"} icon>
                                    <CardIcon color={"rose"}>
                                        <Icon>
                                            person
                                        </Icon>
                                    </CardIcon>
                                    <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                        Lien du réseau social Facebook
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField multiline label="Chemin du lien" value={facebook_page}
                                                   onChange={(event) => setFacebookPage(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>

                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </GridItem>



                {/*Submit button*/}
                <GridItem xs={12}>
                    <Button
                        fullWidth
                        color={'info'}
                        onClick={handleSave}
                    >
                        <Icon>check</Icon>&nbsp;Sauvegarder
                    </Button>
                </GridItem>
            </GridContainer>
        </>
    );
}
