import React, {useEffect, useState} from "react";
import Swal from "sweetalert2";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
import Tooltip from "@material-ui/core/Tooltip";
import {Link} from "react-router-dom";
import Button from "../../components/CustomButtons/Button";
import {Icon} from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardTitleIconPlusTextRight from "../../components/CardHeader/CardTitleIconPlusTextRight";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import serviceOdgs from "../../services/odgs/serviceOdgs";

const columns = [
  {
    title: "Nom",
    field: "name"
  },
  {
    title: "Actions",
    field: "actions",
    headerStyle: {textAlign: 'right'},
    cellStyle: {textAlign: 'right'}
  }
];

export default function OdgsHomeView () {
    const [data, setData] = useState([{}]);

    useEffect(() => {
        serviceOdgs.getAllOdgs().then(res => {
          setData(generateTableContent(res.data.data));
        })
    }, []);


    const to_delete = (id) => {
        Swal.fire({
            title: 'Voulez-vous vraiment suprimer cette ODG ?',
            text: "Cette action est irreversible et peut impacter des produits !",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Suprimer!',
            cancelButtonText: 'Annuler',
        }).then((result) => {
            if (result.value) {
                serviceOdgs.deleteOdg(id).then(() => {
                    SuccessAlert({message: 'Odg supprimé avec succès !'});
                    serviceOdgs.getAllOdgs().then(res => {
                        setData(generateTableContent(res.data.data));
                    }).catch(() => {
                        ErrorAlert({message: 'Une erreur est survenue lors de la supression de l\'odg'});
                    });
                }).catch(() => {
                    Swal.fire('Une erreur est survenue !').then();
                })
            }
        })
    };

    const generateTableContent = (tab) => {
        let res = [];
        tab.forEach(row =>
            res.push({
                name: row.name,
                actions:
                    <>
                        <Tooltip
                            title="Éditer"
                        >
                            <Link to={'/admin/edit/odg/' + row.id}>
                                <Button justIcon={true} round={true} color={'primary'}>
                                    <Icon>
                                        edit
                                    </Icon>
                                </Button>
                            </Link>
                        </Tooltip>
                        <Tooltip
                            title="Supprimer"
                        >
                            <span>
                                <Button
                                    justIcon={true}
                                    round={true}
                                    onClick={() => to_delete(row.id)}
                                    color={'danger'}
                                >
                                    <Icon>
                                        delete
                                    </Icon>
                                </Button>
                            </span>
                        </Tooltip>
                    </>
            })
        );
        return res;
    };

    return (
        <>
            <GridContainer
                justify={'flex-end'}
            >
                <GridItem xs={4} md={4}>
                    <Link to={'/admin/new/odgs'}>
                        <Button type={"button"} color={"success"}>
                            <Icon>add</Icon>
                            Ajouter un ODG
                        </Button>
                    </Link>
                </GridItem>
                <GridItem xs={12} md={12} lg={12}>
                    <Card>
                        <CardTitleIconPlusTextRight
                            color={'primary'}
                            icon={'assignment'}
                            title={'Liste des ODG'}
                        />
                        <MaterialTable

                            components={{
                                Container: props => <Paper {...props} elevation={0}/>
                            }}
                            title={' '}
                            localization={{
                                toolbar: {
                                    searchPlaceholder: "Rechercher",
                                    searchTooltip: "Rechercher",
                                },
                            }}

                            showTitle={' '}
                            columns={columns}
                            data={data}
                            options={
                                {
                                    pageSize: 50,
                                    pageSizeOptions: [5, 10, 20, 50],
                                    sorting: true,
                                    searchPlaceholder: 'Rechercher'
                                }
                            }
                        />

                    </Card>
                </GridItem>
            </GridContainer>
        </>
    );
}