import axios from 'axios';
import Global from "../global";

class categoriesService extends Global {

    getListCategoryProductFromProduct(id) {
        return axios.get(this.getAPI_URL() + "products/" + id + "/categories" , this.getAuthHeader());
    }

    linkCatProduct(id, idProduct) {
        return axios.post(this.getAPI_URL() + "products/" + idProduct  + "/categories" ,{product_id : idProduct, categories: id} , this.getAuthHeader());
    }

    unLinkCatProduct(id, idProduct) {
        return axios.delete(this.getAPI_URL() + "products/" + idProduct  + "/categories/" + id, this.getAuthHeader());
    }

    getAllCategories() {
        return axios.get(this.getAPI_URL() + "categories", this.getAuthHeader());
    }

    newCategorie(label) {
        let params = {
            value : label,
        };
        return axios.post(this.getAPI_URL() + "categories/new", params, this.getAuthHeader());
    }

    setCatProduct(categories, idProduct) {
        console.log(categories);
        console.log('GROOT');
        return axios.post(this.getAPI_URL() + "products/" + idProduct  + "/categories",{product_id : idProduct, categories} , this.getAuthHeader());
    }

    deleteCategorie(id) {
        return axios.delete(this.getAPI_URL() + "categories/" + id, this.getAuthHeader());
    }


    editCategorie(id, label) {
        let params = {
            value : label,
            id : id
        };
        return axios.put(this.getAPI_URL() + "categories/" + id, params, this.getAuthHeader());
    }


    getCategorie(id) {
        return axios.get(this.getAPI_URL() + "categories/" + id , this.getAuthHeader());
    }
}

export default new categoriesService();