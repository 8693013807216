/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "../../components/Navbars/AdminNavbarLinks.js";

import styles from "../../assets/jss/material-dashboard-react/components/sidebarStyle.js";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import NavbarHomeLogoButton from "../CustomButtons/NavbarHomeLogoButton";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import dashboardSections from "../../sections";

const useStyles = makeStyles(styles);

/*const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));*/

export default function Sidebar(props) {

    const handleClick = (name) => {
        setOpen((prevState => ({...prevState, [name]: !prevState[name]})))
    };

    const [open, setOpen] = React.useState({});


    const classes = useStyles();

    // verifies if routeName is the one active (in browser input)
    function activeRoute(routeName) {
        return window.location.href.indexOf(routeName) > -1;
    }

    // verifies if routeName is the one active (in browser input)

    const {color, image, routes, sections} = props;

    const links = (

        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.list}
        >
            {sections.map((prop, key) => {
                // Pour différencier les véritables sections des liens
                if (!prop.path) {
                    let activePro = " ";
                    let listItemClasses;

                    listItemClasses = classNames({
                        [" " + classes[color]]: activeRoute(prop.layout + prop.path)
                    });
                    const whiteFontClasses = classNames({
                        [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
                    });
                    return (
                        <div
                            className={activePro + classes.item}
                            activeClassName="active"
                            exact={true}
                            key={key}
                        >
                            <ListItem button
                                      // className={classes.itemLink + listItemClasses}
                                      onClick={() => handleClick(prop.name)}>
                                {typeof prop.icon === "string" ? (
                                    <Icon
                                        className={classNames(classes.itemIcon, whiteFontClasses, {
                                            [classes.itemIconRTL]: props.rtlActive
                                        })}
                                    >
                                        {prop.icon}
                                    </Icon>
                                ) : (
                                    <prop.icon
                                        className={classNames(classes.itemIcon, whiteFontClasses, {
                                            [classes.itemIconRTL]: props.rtlActive
                                        })}
                                    />
                                )}
                                <ListItemText
                                    className={classNames(classes.itemText, whiteFontClasses, {
                                        [classes.itemTextRTL]: props.rtlActive
                                    })}
                                    disableTypography={true}
                                >
                                    {prop.nameDisplay}


                                </ListItemText>
                                {open[prop.name] ? <ExpandLess/> : <ExpandMore/>}
                            </ListItem>

                            <Collapse in={open[prop.name]} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding className={classes.list}>

                                    {routes.map((propt, keys) => {
                                        if (propt.layout === "/admin" && propt.display === true && propt.section === prop.name) {
                                            let activePro = " ";
                                            let listItemClasses;

                                            listItemClasses = classNames({
                                                [" " + classes[color]]: activeRoute(propt.layout + propt.path)
                                            });
                                            const whiteFontClasses = classNames({
                                                [" " + classes.whiteFont]: activeRoute(propt.layout + propt.path)
                                            });
                                            return (

                                                <ListItem button
                                                          className={classes.nested}
                                                >
                                                    {typeof propt.icon === "string" ? (
                                                        <Icon
                                                            className={classNames(classes.itemIcon, whiteFontClasses, {
                                                                [classes.itemIconRTL]: props.rtlActive
                                                            })}
                                                        >
                                                            {propt.icon}
                                                        </Icon>
                                                    ) : (
                                                        <propt.icon
                                                            className={classNames(classes.itemIcon, whiteFontClasses, {
                                                                [classes.itemIconRTL]: props.rtlActive
                                                            })}
                                                        />
                                                    )}
                                                    <NavLink
                                                        to={propt.layout + propt.path}
                                                        className={activePro + classes.item}
                                                        activeClassName="active"
                                                        exact={true}
                                                        keys={keys + "-" + key}
                                                    >
                                                        <ListItemText
                                                            primary={propt.name}
                                                            className={classNames(classes.itemText, whiteFontClasses, {
                                                                [classes.itemTextRTL]: props.rtlActive
                                                            })}
                                                            disableTypography={true}
                                                        />
                                                    </NavLink>
                                                </ListItem>
                                            );
                                        }
                                    })}

                                </List>

                            </Collapse>

                        </div>
                    )
                } else {
                    let activePro = " ";
                    let listItemClasses;

                    listItemClasses = classNames({
                        [" " + classes[color]]: activeRoute(prop.layout + prop.path)
                    });
                    const whiteFontClasses = classNames({
                        [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
                    });
                    return (
                        <NavLink
                            to={prop.layout + prop.path}
                            className={activePro + classes.item}
                            activeClassName="active"
                            exact={true}
                            key={key}
                        >
                            <ListItem button
                                      // className={classes.itemLink + listItemClasses}
                            >
                                {typeof prop.icon === "string" ? (
                                    <Icon
                                        className={classNames(classes.itemIcon, whiteFontClasses, {
                                            [classes.itemIconRTL]: props.rtlActive
                                        })}
                                    >
                                        {prop.icon}
                                    </Icon>
                                ) : (
                                    <prop.icon
                                        className={classNames(classes.itemIcon, whiteFontClasses, {
                                            [classes.itemIconRTL]: props.rtlActive
                                        })}
                                    />
                                )}
                                <ListItemText
                                    primary={prop.name}
                                    className={classNames(classes.itemText, whiteFontClasses, {
                                        [classes.itemTextRTL]: props.rtlActive
                                    })}
                                    disableTypography={true}
                                />
                            </ListItem>
                        </NavLink>
                    );
                }


                /*< ListItem
            button >
            < ListItemIcon >
            < SendIcon / >
            < /ListItemIcon>
            <ListItemText primary="Sent mail"/>
            < /ListItem>
            <ListItem button>
                <ListItemIcon>
                    <DraftsIcon/>
                </ListItemIcon>
                <ListItemText primary="Drafts"/>
            </ListItem>
            < ListItem
            button
            onClick = {handleClick} >
                < ListItemIcon >
                < InboxIcon / >
                < /ListItemIcon>
            <ListItemText primary="Inbox"/>
            {
                open ? <ExpandLess/> : <ExpandMore/>
            }
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <ListItem button className={classes.nested}>
        <ListItemIcon>
        <StarBorder />
        </ListItemIcon>
        <ListItemText primary="Starred" />
        </ListItem>*/
                /* </List>
                 </Collapse>*/
            })}
        </List>

        /*<List className={classes.list}>
            {routes.map((prop, key) => {
                if (prop.layout === "/admin" && prop.display === true) {
                    let activePro = " ";
                    let listItemClasses;

                    listItemClasses = classNames({
                        [" " + classes[color]]: activeRoute(prop.layout + prop.path)
                    });
                    const whiteFontClasses = classNames({
                        [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
                    });
                    return (
                        <NavLink
                            to={prop.layout + prop.path}
                            className={activePro + classes.item}
                            activeClassName="active"
                            exact={true}
                            key={key}
                        >
                            <ListItem button className={classes.itemLink + listItemClasses}>
                                {typeof prop.icon === "string" ? (
                                    <Icon
                                        className={classNames(classes.itemIcon, whiteFontClasses, {
                                            [classes.itemIconRTL]: props.rtlActive
                                        })}
                                    >
                                        {prop.icon}
                                    </Icon>
                                ) : (
                                    <prop.icon
                                        className={classNames(classes.itemIcon, whiteFontClasses, {
                                            [classes.itemIconRTL]: props.rtlActive
                                        })}
                                    />
                                )}
                                <ListItemText
                                    primary={prop.name}
                                    className={classNames(classes.itemText, whiteFontClasses, {
                                        [classes.itemTextRTL]: props.rtlActive
                                    })}
                                    disableTypography={true}
                                />
                            </ListItem>
                        </NavLink>
                    );
                }
            })}
        </List>*/
    );
    const brand = (
        <div className={classes.logo}>
            <GridContainer
                spacing={0}
                alignItems="center"
                justify="center"
            >
                <GridItem
                    xs={12}
                >
                    <NavbarHomeLogoButton/>
                </GridItem>
            </GridContainer>
        </div>
    );
    return (
        <div>
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor={"right"}
                    open={props.open}
                    classes={{
                        paper: classNames(classes.drawerPaper, {
                            [classes.drawerPaperRTL]: props.rtlActive
                        })
                    }}
                    onClose={props.handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                >
                    {brand}
                    <div className={classes.sidebarWrapper}>
                        <AdminNavbarLinks/>
                        {links}

                    </div>
                    {image !== undefined ? (
                        <div
                            className={classes.background}
                            style={{backgroundImage: "url(" + image + ")"}}
                        />
                    ) : null}
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer
                    anchor={"left"}
                    variant="permanent"
                    open
                    classes={{
                        paper: classNames(classes.drawerPaper, {
                            [classes.drawerPaperRTL]: props.rtlActive
                        })
                    }}
                >
                    {brand}
                    <div className={classes.sidebarWrapper}>
                        {links}
                    </div>
                    {image !== undefined ? (
                        <div
                            className={classes.background}
                            style={{backgroundImage: "url(" + image + ")"}}
                        />
                    ) : null}
                </Drawer>
            </Hidden>
        </div>
    );
}

Sidebar.propTypes = {
    rtlActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
    logo: PropTypes.string,
    image: PropTypes.string,
    logoText: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    sections: PropTypes.arrayOf(PropTypes.object),
};
