import Global from "../global";
import axios from 'axios';


class serviceRxSociaux extends Global {

    getRxSociaux() {
        return axios.get(this.getAPI_URL() + "general", this.getAuthHeader());
    }

    updateRxSociaux(id, instagram_path, facebook_page) {
        let params = {
            id: id,
            instagram_path: instagram_path,
            facebook_page: facebook_page,
        };
        return axios.put(this.getAPI_URL() + "general/rxsociaux", params, this.getAuthHeader());
    }
}

export default new serviceRxSociaux();
