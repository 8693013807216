import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Button from "../../../components/CustomButtons/Button.js";
import products from '../../../services/products/products';
import Swal from 'sweetalert2';
import SuccessAlert from "../../Alerts/SuccessAlert";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle";
import {makeStyles} from "@material-ui/core/styles";
import serviceCategories from "../../../services/categories/serviceCategories";

const styles = {
    ...dashboardStyle,
    cardHeader: {
        color: "#999",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        textAlign: 'left',
        padingBottom: "3px"
    },
};

const useStyles = makeStyles(styles);

export default function FormEditProduct({id}) {

    const classes = useStyles();

    const [label, setLabel] = useState('');
    const [uuid, setUuid] = useState('');
    const [nid, setNid] = useState('');
    const [id_siqo, setIdSiqo] = useState('');
    const [geo_json_web, setGeoJsonWeb] = useState('');
    // objects lists for forms
    const [categories, setCategories] = useState([]);
    const [categorie, setCategorie] = useState([]);

    useEffect(() => {
        serviceCategories.getAllCategories().then(res => {
            setCategories(res.data.data);
        });

        serviceCategories.getListCategoryProductFromProduct(id).then(res => {
            res.data.data.forEach(data => {
                setCategorie(oldArray => [...oldArray, data.category_id ]);
            });
        });

        products.getProduct(id).then(res => {
            setLabel( res.data.data.value);
            setNid( res.data.data.nid);
            setUuid( res.data.data.uuid);
            setIdSiqo( res.data.data.id_siqo);
            setGeoJsonWeb( res.data.data.geojson_web);
        });

    }, [id]); //

    const handleChangeCat = event => {
        let temp = categorie;
        console.log(categorie)
        if (event.target.checked) {
            temp.push(event.target.value);

        } else {
            let pos = temp.indexOf(event.target.value);
            if (pos !== undefined) {
                temp.splice(pos, 1);
            }
        }
        setCategorie(event.target.value);
    };


    const handleFormSubmit = () => {
        products.editProduct(id, label, id_siqo, geo_json_web, uuid, nid, "PENDING").then(res => {
            serviceCategories.setCatProduct(categorie, id).then();
            SuccessAlert({message: res.data.message});
        }).catch(() => {
            Swal.fire('Une erreur est survenue !').then();
        });

    };

    return (
        <>
            <FormControl fullWidth className={classes.formControl}>
                <TextField label="Appellation"
                           value={label}
                           onChange={(event) => setLabel(event.target.value)}/>
                <FormHelperText>*</FormHelperText>
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
                <InputLabel>Catégorie</InputLabel>
                <Select
                    onChange={handleChangeCat}
                    value={categorie}
                    multiple
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {categories.map(cat => {
                        return (
                            <MenuItem key={cat.id} value={cat.id}>{cat.value}</MenuItem>
                        )
                    })}
                </Select>
                <FormHelperText>*</FormHelperText>
            </FormControl>
            
            <Button
                color={"success"}
                disabled={!label && !categorie}
                onClick={handleFormSubmit}
            >
                Modifier
            </Button>
        </>
    );
}
