import React from "react";

import serviceImages from "../../services/images/serviceImages";
import Swal from "sweetalert2";
import Button from "../../components/CustomButtons/Button";
import {Icon} from "@material-ui/core";

export default function ButtonVisuImage({text, credit, idImage}) {

    const visuImage = (id) => {
        let url = serviceImages.getUrlVisuImage(id);
        Swal.fire({
            imageUrl: url,
            text: credit,
            imageAlt: text
        }).then();
    };

    return (
        <>
            <Button
                round
                justIcon={true}
                color={'info'}
                onClick={() => {visuImage(idImage);}}
            >
                <Icon>remove_red_eye</Icon>
            </Button>
        </>
    );
}



