class FieldFormat {
    phone(value) {
        let reg = new RegExp(/\d{10}|\+33\d{9}|\+33\s\d\s\d{2}\s\d{2}\s\d{2}\s\d{2}|\d{2}\s\d{2}\s\d{2}\s\d{2}\s\d{2}/g);

        return value && reg.test(value);
    }

    siret(value) { // int len 15
        let reg = new RegExp(/^[1-9][0-9]{14}$/);

        return value && reg.test(value);
    }

    url(value) { // url
        let reg = new RegExp(/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/);

        return value && reg.test(value);
    }

    email(value) { // email
        let reg = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return value && reg.test(value);
    }

    // json(value) { // json
    //     let reg = new RegExp(/((([a-zA-Z]+)(\s)*(:))){1}(\s)*(((?:[\[])?(?:["])?((.)*)(?:["])?(?:[\]]?))?)?([,]?)/g);
    //     return value && reg.test(value);
    // }

    code_postal(value) { // code_postal
        let reg = new RegExp( /^[0-9]{5}$/);
        return value && reg.test(value);
    }
}

export default new FieldFormat();
