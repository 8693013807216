import Global from "../global";
import axios from 'axios';


class serviceAddresses extends Global {

    getAddressFromProduct(id) {
        return axios.get(this.getAPI_URL() + "products/" + id + "/addresses", this.getAuthHeader());
    }

    getAllAddresses() {
        return axios.get(this.getAPI_URL() + "addresses", this.getAuthHeader());
    }

    linkAddressProduct(id_address, id_product) {
        return axios.post(this.getAPI_URL() + "products/" + id_product + "/addresses/" + id_address, {address_id :id_address, product_id:id_product}, this.getAuthHeader());
    }

    createAddress(name,address,city,country,postal_code,phone,website,email,headoffice,product_id, status) {
        let temp = Math.floor(Date.now() / 1000);
        let params = {
            nid: temp,
            uuid : `${temp}`,
            name: name,
            address: address,
            city: city,
            country: country === "" ? null : country,
            country_short: country.length > 2 ? country.substring(0, 2) : country.length === 0 ? "" : country,
            postal_code: postal_code,
            phone : phone === "" ? null : phone,
            website: website === "" ? null : website,
            email: email,
            headoffice: headoffice,
            product_id: product_id,
            status: status
        };
        return axios.post(this.getAPI_URL() + "products/" + product_id + "/addresses/new", params, this.getAuthHeader());
    }


    updateAddress(id , nid , uuid, name,address,city,country,postal_code,phone,website,email,headoffice, product_id, status) {
        let params = {
            id: id,
            nid: nid,
            uuid: uuid,
            name: name,
            address: address,
            city: city,
            country: country === "" ? null : country,
            country_short: country.length > 2 ? country.substring(0, 2) : country.length === 0 ? "" : country,
            postal_code: postal_code,
            phone : phone === "" ? null : phone,
            website: website === "" ? null : website,
            email: email,
            headoffice: headoffice,
            status: status
        };
        return axios.put(this.getAPI_URL() + "addresses/" + id , params, this.getAuthHeader());
    }
}

export default new serviceAddresses();