import React from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import CardInfoGeneral from "../../components/Card/Products/CardInfoGeneral";
import CardAPropos from "../../components/Card/Products/CardAPropos";
import CardDisplayProduct from "../../components/Card/Products/CardDisplayProduct";
import Button from "../../components/CustomButtons/Button";
import {Icon} from "@material-ui/core";
import {Link} from "react-router-dom";
import {createBrowserHistory} from "history";



export default function RecapProducts(props) {
    // param
    const id = props.match.params.id;
    const hist = createBrowserHistory();

    return (
        <>
            <Button onClick={hist.goBack}> Retour </Button>
            <GridContainer>
                <GridItem xs={12} md={4}>
                    <GridContainer>
                        {/*Inforation general*/}
                        <GridItem xs={12}>
                            <CardInfoGeneral id={id}/>
                        </GridItem>
                        {/*Inforation general*/}
                        <GridItem xs={12}>
                            <CardAPropos id={id}/>
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12} md={8}>
                    <GridContainer>
                        <GridItem xs={12}>
                            {/*Inforation general*/}
                            <CardDisplayProduct id={id}/>
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12} >
                    <Link to={'/admin/edit/products/' + id}>
                        <Button fullWidth={true} color={'primary'}>
                            <Icon>
                                edit
                            </Icon>
                            Editer
                        </Button>
                    </Link>
                </GridItem>
            </GridContainer>
        </>
    );


}
