import React, { useEffect, useState } from "react";
import GridItem from "../../Grid/GridItem.js";
import GridContainer from "../../Grid/GridContainer.js";
import { Link } from "react-router-dom";
import Button from "../../CustomButtons/Button";
import SuccessAlert from "../../Alerts/SuccessAlert";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import Authentication from "../../../services/authentication/Authentication";
import userService from "../../../services/users/users";

export default function FormEditUser(props) {

  const [id, setID] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    Authentication.getUser().then(res => {
      setName(res.data.data.name);
      setEmail(res.data.data.email);
      setID(res.data.data.id);
    })
  }, [id]);

  useEffect(() => {
    IsFormValid();
  },[name, email, password]);

  const IsFormValid = () => {
    if (email !== '' && name !== '') {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  };

  const handleFormSubmit = () => {
    if (password !== "")  {
      userService.updateUserWithPassword(name, email, password).then(res => {
        SuccessAlert({message: res.data.message});
        props.props.history.push('/admin/users/home');
      }).catch((e) => {
        console.log(e)
        Swal.fire('Une erreur est survenue !');
      });
    } else {
      userService.updateUserWithoutPassword(name, email).then(res => {
        SuccessAlert({message: res.data.message});
        props.props.history.push('/admin/users/home');
      }).catch((e) => {
        console.log(e)
        Swal.fire('Une erreur est survenue !');
      });
    }
  };

  return (
    <>
        <GridContainer
          justify="center"
          alignItems="center"
        >
          <GridItem xs={12}>
            <TextField
              label="Nom"
              error={!name}
              helperText={!name ? 'Le nom ne peut pas être vide' : ''}
              value={name}
              style={{margin: 8}}
              placeholder="Nom"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={async e => {
                setName(e.target.value);
              }}
            />
            <TextField
              label="Email"
              error={!email}
              helperText={!email ? "L'email ne peut pas être vide" : ''}
              value={email}
              style={{margin: 8}}
              placeholder="Email"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={async e => {
                setEmail(e.target.value);
              }}
            />
            <TextField
              label="Mot de Passe"
              type="password"
              value={password}
              style={{margin: 8}}
              placeholder="Mot de Passe (Optionnel)"
              fullWidth
              margin="normal"
              autoComplete="new-password"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={async e => {
                setPassword(e.target.value);
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer
          justify="center"
          alignItems="center"
        >
          <GridItem>
            <Button
              onClick={handleFormSubmit}
              disabled={!formValid}
              color={'success'}
            >
              Enregistrer
            </Button>
          </GridItem>
          <GridItem>
            <Link to={'/admin/users/home'}>
              <Button
                color={'danger'}
              >
                Retour
              </Button>
            </Link>
          </GridItem>
        </GridContainer>
      </>
  );
}
