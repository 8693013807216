import React, {useEffect, useState} from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import {Icon} from "@material-ui/core";
import CardBody from "../../components/Card/CardBody";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import {makeStyles} from "@material-ui/core/styles";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import Card from "../../components/Card/Card";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "../../components/CustomButtons/Button";
import {DropzoneArea} from "material-ui-dropzone";
import WarningAlert from "../../components/Alerts/WarningAlert";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
import serviceOdgs from "../../services/odgs/serviceOdgs";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import servicePages from "../../services/pages/servicePages";
import {createBrowserHistory} from "history";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {Link} from "react-router-dom";
import {LocalOffer} from "@material-ui/icons";

const styles = {
    ...dashboardStyle,
    cardHeader: {
        color: "#999",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        textAlign: 'left',
        padingBottom: "3px"
    },
};

const useStyles = makeStyles(styles);
const hist = createBrowserHistory();

export default function EditPageCarrousel(params) {
    const id = params.match.params.id;

    const classes = useStyles();

    const [idPage, setId] = useState('');
    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [title_header, setTitleHeader] = useState('');
    const [sub_title, setSubTitle] = useState('');
    const [link, setLink] = useState('');
    const [link_title, setLinkTitle ] = useState('');
    const [content, setContent] = useState('');
    const [title_seo, setTitleSEO] = useState('');
    const [description_seo, setDescriptionSEO] = useState('');

    useEffect(() => {
        servicePages.getPage(id).then(res => {
            setId(res.data.data.id);
            setName(res.data.data.name);
            setTitle(res.data.data.title);
            setTitleHeader(res.data.data.title);
            setSubTitle(res.data.data.sub_title);
            setLink(res.data.data.link);
            setLinkTitle(res.data.data.link_title);
            if(res.data.data.content !== null)
                setContent(res.data.data.content);
            setTitleSEO(res.data.data.title_seo);
            setDescriptionSEO(res.data.data.description_seo);
        });
    }, []);

    const DisplayButton = ({ available }) => {
        if (available === false) {
            return null;
        }
        else {
            return (
                <GridItem xs={12}>
                    <Link to={'/admin/signs'}>
                        <Button color={'info'}> Voir la liste des signes </Button>
                    </Link>
                </GridItem>
            );
        }
    };

    const DisplayTitle = ({show}) =>{
        if (show === true )
            return (
                <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                    Contenu
                </label>
            );
        else
            return (
                <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                    Accroche
                </label>
            );
    };

    const DisplayTextField = ({toShow}) =>{
        if (toShow === true)
            return (
                <FormControl fullWidth className={classes.formControl}>
                    <TextField label="Accroche carrousel" value={sub_title}
                               onChange={(event) => setSubTitle(event.target.value)}/>
                    <FormHelperText>*</FormHelperText>
                </FormControl>
            );
        else
            return (
                null
            );
    };

    const DisplayTitleCard = ({display}) =>{
        if (display === true)
            return(
                <h5 className={classes.cardHeader} style={{color: '#999'}}>
                    Contenu de la page
                </h5>
            );
        else
            return (
                <h5 className={classes.cardHeader} style={{color: '#999'}}>
                    Carrousel
                </h5>
            );
    };

    const DisplayCarrousel = ({visible}) =>{
        if (visible === true )
            return(
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card>
                            <CardHeader color={"info"} icon>
                                <CardIcon color={"info"}>
                                    <Icon>
                                        short_text
                                    </Icon>
                                </CardIcon>
                                <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                    Carrousel
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <FormControl fullWidth className={classes.formControl}>
                                    <TextField label="Accroche" value={sub_title}
                                               onChange={(event) => setSubTitle(event.target.value)}/>
                                    <FormHelperText>*</FormHelperText>
                                </FormControl>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        else
            return (null);
    }

    const handleSave = async () => {
        await servicePages.updatePage(id, name, title, sub_title, link, link_title, content, title_seo, description_seo, title_header).then(async (res) => {
            SuccessAlert({message: res.data.message});
        }).catch((err) => {
                ErrorAlert({message: 'Une erreur est survenue'});
                console.log(err);
            }
        );
    };

    return (
        <>
            <Button onClick={hist.goBack}> Retour </Button>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={6}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color={"rose"} icon>
                                    <CardIcon color={"rose"}>
                                        <Icon>
                                            store
                                        </Icon>
                                    </CardIcon>
                                    <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                        Page
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField label="Titre" value={title}
                                                   onChange={(event) => setTitle(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>

                                    {/*<FormControl fullWidth className={classes.formControl}>
                                        <TextField label="Chemin" value={name}
                                                   onChange={(event) => setName(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>*/}
                                </CardBody>
                            </Card>
                        </GridItem>
                        {/*Menu*/}
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color={"rose"} icon>
                                    <CardIcon color={"rose"}>
                                        <Icon>
                                            store
                                        </Icon>
                                    </CardIcon>
                                    <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                        Tags SEO
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField label="Titre" value={title}
                                                   onChange={(event) => setTitleSEO(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField label="Description" value={description_seo}
                                                   onChange={(event) => setDescriptionSEO(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </GridItem>

                <GridItem xs={12} sm={12} md={12} lg={6}>

                    <DisplayCarrousel visible={idPage === 4}></DisplayCarrousel>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color={"info"} icon>
                                    <CardIcon color={"info"}>
                                        <Icon>
                                            short_text
                                        </Icon>
                                    </CardIcon>
                                    <DisplayTitleCard display = {idPage === 4}></DisplayTitleCard>
                                </CardHeader>

                                <CardBody>
                                    {/*
                                    <DisplayTextField toShow={idPage === 4}></DisplayTextField>
    */}

                                    <FormControl fullWidth className={classes.formControl}>

                                        <DisplayTitle show={idPage === 4}></DisplayTitle>

                                        <CKEditor
                                            data={content}
                                            editor={ClassicEditor}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setContent(data);
                                            }}
                                            onBlur={(event, editor) => {
                                                setContent(editor.getData());
                                            }}
                                            onFocus={(event, editor) => {
                                                setContent(editor.getData());
                                            }}
                                        />
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>

                                    <DisplayButton available={idPage === 4} />

                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </GridItem>
                {/*Submit button*/}
                <GridItem xs={12}>
                    <Button
                        fullWidth
                        color={'info'}
                        onClick={handleSave}
                    >
                        <Icon>check</Icon>&nbsp;Sauvegarder
                    </Button>
                </GridItem>
            </GridContainer>
        </>
    )
}


