import React, {useEffect, useState} from "react";
import signsService from "../../../services/signs/signs"
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle";
import Button from "../../CustomButtons/Button";
import SuccessAlert from "../../Alerts/SuccessAlert";
import ErrorAlert from "../../Alerts/ErrorAlert";

const useStyles = makeStyles(dashboardStyle);

export default function FormEditSign({id}) {
    // style
    const classes = useStyles();
    // form
    const [nid, setNid] = useState('');
    const [uuid, setUuid] = useState('');
    const [value, setValue] = useState('');
    const [short_form, setShort_form] = useState('');
    // desc
    const [idSignDesc, setIdSignDesc] = useState('');
    const [signDesc, setSignDesc] = useState('');


    useEffect(() => {
        signsService.getSign(id).then(res => {
            setNid(res.data.data.nid);
            setUuid(res.data.data.uuid);
            setValue(res.data.data.value);
            setShort_form(res.data.data.short_form);
        });
        signsService.getAllSignsDescriptions(id).then(res => {
            if (res.data.data) {
                setIdSignDesc(res.data.data.id);
                setSignDesc(res.data.data.value);
            }
        })
    }, [id]);

    const handleSave = () => {
        signsService.editSign(id, nid,uuid, value, short_form).then(()=>{
            signsService.editSignDesc(idSignDesc, signDesc, null, "  ", signDesc, id).then(() => {
                SuccessAlert({message: 'Signe modifié avec succès'});
            }).catch(() => {
                ErrorAlert({message: 'Une erreur est survenue'});
            });
        }).catch(() => {
            ErrorAlert({message: 'Une erreur est survenue'});
        });
    };

    return (
        <>
            <FormControl fullWidth className={classes.formControl} style={{marginBottom: '10px'}}>
                <TextField
                    label="Titre"
                    value={value}
                    onChange={(event) => setValue(event.target.value)}
                />
            </FormControl>
            <FormControl fullWidth className={classes.formControl} style={{marginBottom: '10px'}}>
                <TextField
                    label="Abréviation"
                    value={short_form}
                    onChange={(event) => setShort_form(event.target.value)}
                />
            </FormControl>

            <p>
                Description
            </p>
            <CKEditor
                data={signDesc}
                editor={ClassicEditor}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    setSignDesc(data);
                }}
                onBlur={(event, editor) => {
                    setSignDesc(editor.getData());
                }}
                onFocus={(event, editor) => {
                    setSignDesc(editor.getData());
                }}
            />
            <Button
                color={"rose"}
                onClick={handleSave}
            >
                Modifier
            </Button>

        </>
    );
}
