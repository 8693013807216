import React, {useEffect, useState} from "react";
import Card from "../../components/Card/Card"
import CardTitleIconPlusTextRight from "../../components/CardHeader/CardTitleIconPlusTextRight";
import CardBody from "../../components/Card/CardBody";
import {Link} from "react-router-dom";
import Button from "../../components/CustomButtons/Button";
import signs from "../../services/signs/signs";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import {DropzoneArea} from "material-ui-dropzone";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
import SuccessAlert from "../../components/Alerts/SuccessAlert";

const useStyles = makeStyles(dashboardStyle);

export default function SignAddPictureView(props) {
    const classes = useStyles();

    const id = props.match.params.id;
    const id_picture = props.match.params.id_picture;

    const [title, setTitle] = useState('');
    const [alt, setAlt] = useState('');
    const [newFile, setNewFile] = useState({});
    const [imageUri, setImageUri] = useState(signs.getPiuctureVisuUrl(id_picture));


    useEffect(() => {
        signs.getPiucture(id_picture).then(res => {
            setTitle(res.data.data.title);
            setAlt(res.data.data.alt);
        })
    }, [id]);

    const handleChange = (files) => {
        if (files[0] !== undefined) {
            let reader = new FileReader();
            reader.readAsText(files[0], "UTF-8");
            reader.onload = () => {
                setNewFile(files[0]);
            };
        }
    };

    const handleSubmit = () => {

        if (newFile.name !== undefined) {
            console.log(newFile)
            signs.updatePictureWitContent(id,id_picture, title, alt, newFile).then(() => {
                SuccessAlert({message:"image modifiée avec succès"})
                setImageUri(imageUri+' ')
            }).catch(() => ErrorAlert({message: 'Une erreur est survenue'}))
        }
        else {
            console.log("nofile")
            signs.updatePictureWithoutContent(id,id_picture, title, alt).then(() => {
                SuccessAlert({message:"image modifiée avec succès"})
            }).catch(() => ErrorAlert({message: 'Une erreur est survenue'}))
        }
    };


    return (
        <>
            <Card>
                <CardTitleIconPlusTextRight
                    color={"success"}
                    icon={"add_photo_alternate"}
                    title={"Edition Image"}
                />
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} md={8}>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField label="Titre" value={title}
                                           onChange={(event) => setTitle(event.target.value)}/>
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl} style={{marginTop: '1%'}}>
                                <TextField label="Alt" value={alt}
                                           onChange={(event) => setAlt(event.target.value)}/>
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl} style={{marginTop: '1%'}}>
                                <DropzoneArea
                                    acceptedFiles={["image/*"]}
                                    dropzoneText={"Déposer une nouvelle image ici"}
                                    onChange={handleChange}
                                    showPreviewsInDropzone={false}
                                    showPreviews={true}
                                    filesLimit={1}
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem xs={12} md={4}>
                            <img src={imageUri} alt={imageUri}/>
                        </GridItem>
                        <GridItem xs={12} md={12} style={{marginTop: '1%'}}>
                            <GridContainer>
                                <GridItem xs={6} >
                                    <Button
                                        color={"success"}
                                        onClick={() => handleSubmit()}
                                    >
                                        Enregistrer
                                    </Button>
                                </GridItem>
                                <GridItem xs={6} >
                                    <Link to={"/admin/edit/signs/" + id}>
                                        <Button
                                            color={"danger"}
                                        >
                                            Annuler
                                        </Button>
                                    </Link>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>


                </CardBody>
            </Card>
        </>
)
};