import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import {autoPlay} from 'react-swipeable-views-utils';
import serviceImages from "../../services/images/serviceImages";
import products from "../../services/products/products";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


const useStyles = makeStyles(theme => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        // height: 255,
        display: 'block',
        maxWidth: '100%',
        overflow: 'hidden',
        width: '100%',
    },
}));

function CarrouselProductsPics({id}) {

    const [tutorialSteps, setTutorialSteps] = useState([]);
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);

    useEffect(() => {
        products.getAllImagesFromProduct(id).then(res => {
            setTutorialSteps(res.data.data);
        });
    }, []);

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleStepChange = step => {
        setActiveStep(step);
    };

    return (
        <>
            <Paper square elevation={0} className={classes.header}>
                <Typography>{tutorialSteps.length ? tutorialSteps[activeStep].value : null}</Typography>
            </Paper>
            <AutoPlaySwipeableViews
                axis={'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {tutorialSteps.map((step, index) => (
                    <div key={step.id}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <img className={classes.img} src={serviceImages.getUrlVisuImage(step.id)} alt={step.alt}/>
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
                steps={tutorialSteps.length}
                position="static"
                variant="text"
                activeStep={activeStep}
                nextButton={
                    <Button size="small" onClick={handleNext} disabled={activeStep === tutorialSteps.length - 1}>
                        Next
                         <KeyboardArrowRight/>
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                         <KeyboardArrowLeft/>
                        Back
                    </Button>
                }
            />
        </>
    );
}

export default CarrouselProductsPics;