import React, {useEffect, useState} from "react";
import Button from "../../../components/CustomButtons/Button.js";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import products from "../../../services/products/products";
import SuccessAlert from "../../Alerts/SuccessAlert";
import Swal from "sweetalert2";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import TableTerroirEditList from '../../Table/Terroir/TableTerroirEditList';
import CardHeader from "../../../components/Card/CardHeader";
import CardIcon from "../../../components/Card/CardIcon";
import Card from "../../../components/Card/Card";
import {Icon} from "@material-ui/core";
// import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import {makeStyles} from "@material-ui/core/styles";
import CardBody from "../../../components/Card/CardBody";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import WarningAlert from "../../../components/Alerts/WarningAlert";


const styles = {
    // ...dashboardStyle,
    cardHeader: {
        color: "#999",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        textAlign: 'left',
        padingBottom: "3px"
    },
};

const useStyles = makeStyles(styles);

export default function FormEditProductSEO({id}) {

    const classes = useStyles();

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        products.getProductSEO(id).then(res => {
            setTitle(res.data.data.title);

            if (res.data.data.description){
                setDescription(res.data.data.description);
            }
            else
                setDescription('En savoir plus sur ce produit sous signe officiel d’origine et de qualité.');
        });
    }, [id]);

    const isFormValid = () => {
        if (!title) {
            WarningAlert({
                message: "Le titre doit être renseigné",
            });
            return false;
        }
        if (!description) {
            WarningAlert({
                message: "La description doit être renseignée",
            });
            return false;
        }
        return true;
    };

    const handleFormSubmit = () => {
        if (isFormValid()) {
            products.updateProductSEO(id, title, description).then(res => {
                SuccessAlert({message: res.data.message});
            }).catch(() => {
                Swal.fire('Une erreur est survenue !');
            });
        }
    };

    return (
        <>
            <FormControl fullWidth className={classes.formControl}>
                <TextField multiline label="Titre" value={title}
                           onChange={(event) => setTitle(event.target.value)}/>
                <FormHelperText>*</FormHelperText>
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
                <TextField multiline label="Description" value={description}
                           onChange={(event) => setDescription(event.target.value)}/>
                <FormHelperText>*</FormHelperText>
            </FormControl>
            <Button color={"warning"} onClick={handleFormSubmit}>
                Modifier
            </Button>
        </>
    );
}
