import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Button from "../../../components/CustomButtons/Button.js";
import {Link} from 'react-router-dom';
import userService from '../../../services/users/users';
import fieldFormat from '../../../validators/fieldFormat';
import Swal from 'sweetalert2';
import SuccessAlert from "../../Alerts/SuccessAlert";


export default function FormNewUser(props) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [c_password, setCPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    setIsEmailValid(fieldFormat.email(email));
    IsFormValid();
  }, [email]);

  useEffect(() => {
    IsFormValid();
  },[name, email, password, c_password]);

  const IsFormValid = () => {
    if (name &&
      isEmailValid &&
      password === c_password) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  };
  const handleFormSubmit = () => {
    userService.newUser(name, email, password, c_password).then(res => {
      SuccessAlert({message: res.data.message});
      props.props.history.push('/admin/users/home');
    }).catch((e) => {
      console.log(e)
      Swal.fire('Une erreur est survenue !').then();
    });
  };

  return (
    <>
      <TextField
        label="Nom"
        error={!name}
        style={{margin: 8}}
        placeholder="Nom"
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={e => {
          setName(e.target.value);
        }}
      />
      <TextField
        type={"text"}
        label="Email"
        error={!isEmailValid}
        helperText={!isEmailValid ? 'Renseigner un Email valide' : ''}
        value={email}
        style={{margin: 8}}
        placeholder="Email"
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={e => {
          setEmail(e.target.value);
        }}
      />
      <TextField
        type={"password"}
        error={!password }
        label="Mot de passe"
        style={{margin: 8}}
        placeholder="Mot de Passe"
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={e => {
          setPassword(e.target.value);
        }}
      />
      <TextField
        type={"password"}
        error={!c_password }
        label="Confirmation Mot de Passe"
        style={{margin: 8}}
        placeholder="Confirmation Mot de Passe"
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={e => {
          setCPassword(e.target.value);
        }}
      />
      <br />
      <Button
        color={"success"}
        disabled={!formValid}
        onClick={handleFormSubmit}
      >
        Ajouter
      </Button>
      <Link to={'/admin/users/home'}>
        <Button
          color={"danger"}
        >
          Annuler
        </Button>
      </Link>
    </>
  );
}
