import React from "react";

import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card";
import CardTitleIconPlusTextRight from "../../components/CardHeader/CardTitleIconPlusTextRight";
import CardBody from "../../components/Card/CardBody";
import FormModifFlavors from "../../components/Forms/Flavors/FormModifFlavors";

export default function EditFlavors(params) {

    const id = params.match.params.id;


    return (
        <>
            <br />
            <GridContainer>
                <GridItem xs={12} md={6}>
                    <Card>
                        <CardTitleIconPlusTextRight
                            color={'primary'}
                            icon={'local_dining'}
                            title={'Modification Saveur'}
                        />
                        <CardBody>
                            <FormModifFlavors id={id} />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    )
}


