import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
//core components
import styles from "../../../assets/jss/material-dashboard-react/checkboxAdnRadioStyle.js";
import {Check} from "@material-ui/icons";
import signsService from '../../../services/signs/signs'
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SuccessAlert from "../../Alerts/SuccessAlert";

const useStyles = makeStyles(styles);


export default function CheckBoxSignEdit({idProduct, sign}) {

    const classes = useStyles();


    const [checked, setChecked] = React.useState([]);
    const [isChecked, setIsChecked] = React.useState(false);


    useEffect(() => {
        signsService.getAllSignsForProduct(idProduct).then(res => {

            res.data.data.forEach(row => {

                if (row.sign_id === sign.id) {
                    setIsChecked(true);
                }
            })
        });
    }, [idProduct, sign]);


    const handleToggle = value => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            signsService.linkSignProduct(sign.id, idProduct).then((res) => {
                newChecked.push(value);
                setIsChecked(true);
                if (res.status === 201){
                    SuccessAlert({message: "Signe ajouté avec succès"});
                }
            });
        } else {
            signsService.unLinkSignProduct(sign.id, idProduct).then((res) => {

                newChecked.splice(currentIndex, 1);
                setIsChecked(false);

                if (res.status === 200){
                    SuccessAlert({message: "Signe retiré avec succès"});
                }
            });
        }
        setChecked(newChecked);
    };

    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isChecked}
                        onClick={() => handleToggle(1)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked
                        }}
                    />
                }
                label={sign.short_form} />

        </FormGroup>


    );
}
