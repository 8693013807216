import React, {useEffect, useState} from "react";
import signsService from '../../../services/signs/signs';
import CheckBoxSignEdit from "./CheckboxForSign";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";

export default function FormEditSigns({id}) {

    const [signs, setSigns] = useState([]);


    useEffect(() => {

        signsService.getAllSigns().then(allSigns => {
            setSigns(allSigns.data.data);
        });

    }, [id]);

    return (
        <>
            <GridContainer>
                {
                    signs.map(row => {
                            return (
                                <GridItem key={row.id} xs={12} md={12} lg={6}>
                                    <FormControl>
                                        <CheckBoxSignEdit idProduct={id} sign={row}/>
                                    </FormControl>
                                </GridItem>
                            )
                        }
                    )
                }
            </GridContainer>
        </>
    );
}
