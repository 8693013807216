import React from "react";
// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";
import Login from "../views/Login/Login";
import PerfectScrollbar from "perfect-scrollbar";

import {makeStyles} from "@material-ui/core";
import styles from "../assets/jss/material-dashboard-react/layouts/loginStyle";
import Background from "../assets/img/background/sidebar-4.jpg";

let ps;

const useStyles = makeStyles(styles);

function Utilities({...rest}) {
    // styles
    const classes = useStyles();
    // ref to help us initialize PerfectScrollbar on windows devices
    const mainPanel = React.createRef();
    // states and functions
    const [, setMobileOpen] = React.useState(false);

    const resizeFunction = () => {
        if (window.innerWidth >= 960) {
            setMobileOpen(false);
        }
    };
    // initialize and destroy the PerfectScrollbar plugin
    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current, {
                suppressScrollX: true,
                suppressScrollY: false
            });
            document.body.style.overflow = "hidden";
        }
        window.addEventListener("resize", resizeFunction);
        // Specify how to clean up after this effect:
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
            window.removeEventListener("resize", resizeFunction);
        };
    }, [mainPanel]);

    return (
        <div className={classes.wrapper} style={
            {
                backgroundImage: `url(${Background})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',

            }
        }
        >
            <div className={classes.mainPanel} ref={mainPanel}>
                <Login {...rest} />
            </div>
        </div>
    );
}


export default function LoginLayout({...rest}) {
    return (
        <Utilities {...rest}/>
    );
}
