import Swal from "sweetalert2";

export default function ErrorAlert({message = 'Une erreur est survenue', confirm = false, position='center'})  {
    Swal.fire({
        title:  message,
        position: position,
        icon: 'error',
        showConfirmButton: confirm,
        timer: 3000
    }).then()
}