import React, {useEffect, useState} from "react";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "../Card";
import CardHeader from "../CardHeader";
import CardBody from "../CardBody";
import serviceAddresses from "../../../services/addresses/serviceAddresses";

const styles = {
    ...dashboardStyle,
    cardTitle: {
        marginTop: "0",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        textAlign: 'center'
    },
    centered: {
        textAlign: 'center'
    }
};
const useStyles = makeStyles(styles);

export default function CardInfoGeneral({id}) {
    // style
    const classes = useStyles();
    const defaultEmpty = "Ce champ n'est pas renseigné";
    // states
    const [addresses, setAddresses] = useState([]);
    // init
    useEffect(() => {
        serviceAddresses.getAddressFromProduct(id).then(res => {
            setAddresses(res.data.data);
        })
    }, []);
    return (
        <Card>
            <CardHeader color="warning">
                <h4 className={classes.cardTitle}>
                    À propos
                </h4>
            </CardHeader>
            <CardBody className={classes.centered}>
                <h6 className={classes.cardCategory}>
                    NOM
                </h6>
                {addresses.length ? addresses.map(res => {
                    return (
                        <h6 key={res.id} className={classes.cardTitle}>
                            {res.name }
                        </h6>
                    )
                }) : defaultEmpty}
                <h6 className={classes.cardCategory}>
                    ADRESSE
                </h6>
                {addresses.length ? addresses.map(res => {
                    return (
                        <h6 key={res.id} className={classes.cardTitle}>
                            {res.address} {res.postal_code} {res.city} {res.country}
                        </h6>
                    )
                }) : defaultEmpty}
                <h6 className={classes.cardCategory}>
                    TÉLÉPHONE
                </h6>
                {addresses.length ? addresses.map(res => {
                    return (
                        <h6 key={res.id} className={classes.cardTitle}>
                            {res.phone}
                        </h6>
                    )
                }) : defaultEmpty}

                <h6 className={classes.cardCategory}>
                    SITE WEB
                </h6>
                {addresses.length ? addresses.map(res => {
                    return (
                        <h6 key={res.id} className={classes.cardTitle}>
                            <a target={'blank'} href={res.website}>{res.website}</a>
                        </h6>
                    )
                }) : defaultEmpty}
            </CardBody>
        </Card>
    );
}