import React, {useEffect, useState} from "react";
import Button from "../../../components/CustomButtons/Button.js";
import {Link} from 'react-router-dom';
import products from '../../../services/products/products';
import Swal from 'sweetalert2';
import SuccessAlert from "../../Alerts/SuccessAlert";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";




export default function FormModifDescriptions({id }) {

    const [label, setLabel] = useState('');

    const [object, setObject] = useState({product_id: null});

    const handleFormSubmit = () => {
        products.updateDescription(id, object.product_id, label).then(res => {
            SuccessAlert({message: res.data.message});
            products.getDescription(id).then( res => {
                setObject(res.data.data);
                setLabel(res.data.data.label);
            });
        }).catch(() => {
            Swal.fire('Une erreur est survenue !');
        });

    };

    useEffect(() => {
        products.getDescription(id).then( res => {
            setObject(res.data.data);
            setLabel(res.data.data.label);
        })
    }, [id]);

    return (
        <>
            <CKEditor
                editor={ClassicEditor}
                data={label}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    setLabel(data);
                }}
                onBlur={(event, editor) => {
                    setLabel(editor.getData());
                }}
                onFocus={(event, editor) => {
                    setLabel(editor.getData());
                }}
            />
            <Button
                color={"warning"}
                // disabled={!formValid}
                onClick={handleFormSubmit}
            >
                Modifier
            </Button>
            <Link to={'/admin/edit/products/' + object.product_id}>
                <Button
                    color={"danger"}
                >
                    Annuler
                </Button>
            </Link>
        </>
    );
}
