import React from "react";
import Authentication from "../../services/authentication/Authentication";

const Logout = ({history}) => {

  React.useEffect(() => {
    console.log(history)
    disco();
  },[history]);

  const disco = async () => {
    await Authentication.disconnect();
    await history.push('/login');
  };
  return(
    <>

    </>
  );
};

export default Logout