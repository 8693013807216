import Global from "../global";
import axios from 'axios';


class servicePages extends Global {

    getPage(id) {
        return axios.get(this.getAPI_URL() + "pages/" + id, this.getAuthHeader());
    }

    getAllPages() {
        return axios.get(this.getAPI_URL() + "pages", this.getAuthHeader());
    }

    updatePage(id, name, title, sub_title, link, link_title, content, title_seo, description_seo, title_header) {
        let params = {
            id: id,
            name: name,
            // template_id: template_id,
            title: title,
            title_header: title_header,
            sub_title: sub_title,
            link: link,
            link_title: link_title,
            // background_image: country.length > 2 ? country.substring(0, 2) : country.length === 0 ? "" : country,
            content: content,
            title_seo: title_seo,
            description_seo: description_seo
        };
        return axios.put(this.getAPI_URL() + "pages", params, this.getAuthHeader());
    }
}

export default new servicePages();