import React, {useEffect, useState} from "react";
import GridContainer from "../../Grid/GridContainer";
import TextField from "@material-ui/core/TextField";
import Button from "../../../components/CustomButtons/Button.js";
import SuccessAlert from "../../Alerts/SuccessAlert";
import {Link} from "react-router-dom";
import GridItem from "../../Grid/GridItem.js";
import serviceOdgs from "../../../services/odgs/serviceOdgs";
import ErrorAlert from "../../Alerts/ErrorAlert";


export default function FormEditOdg({id}) {

    const [label, setLabel] = useState('');
    const [uuid, setUuid] = useState('');
    const [target_id, settTrget_id] = useState('');

    useEffect(() => {
        serviceOdgs.getOdg(id).then(res => {
            setLabel(res.data.data.name);
            setUuid(res.data.data.uuid);
            settTrget_id(res.data.data.target_id);
        })
    }, [id]);



    const handleFormSubmit = () => {
        serviceOdgs.editOdg(id, label, uuid, target_id).then(res => {
            SuccessAlert({message: res.data.message});
        }).catch(() => {
            ErrorAlert({});
        });

    };

    return (
        <>
            <GridContainer
                justify="center"
                alignItems="center"
            >
                <GridItem xs={12}>
                    <TextField
                        label="Nom "
                        style={{margin: 8}}
                        placeholder="Nom"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={label}
                        onChange={async e => {
                            setLabel(e.target.value);
                        }}
                    />
                </GridItem>
            </GridContainer>
            <GridContainer
                justify="center"
                alignItems="center"
            >
                <GridItem>
                    <Button
                        color={"success"}
                        disabled={!label}
                        onClick={handleFormSubmit}
                    >
                        Modifier
                    </Button>
                </GridItem>
                <GridItem>
                    <Link to={'/admin/odg'}>
                        <Button
                            color={'danger'}
                        >
                            Retour
                        </Button>
                    </Link>
                </GridItem>
            </GridContainer>
        </>
    );
}
