import axios from 'axios';
import qs from 'qs';
import Global from '../global';


class Authentication extends Global {
    login(credentials){
        return axios.post(this.getAPI_URL() + "login", qs.stringify(credentials));
    }

    newPassword(credentials){
    	return axios.put(this.getAPI_URL() + "users/me/forgotten_password", qs.stringify(credentials));
    }
}

export default new Authentication();