import React, {useEffect, useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle";
import {makeStyles} from "@material-ui/core/styles";
import products from "../../../services/products/products";
import Button from "../../CustomButtons/Button";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import SuccessAlert from "../../Alerts/SuccessAlert";

const styles = {
    ...dashboardStyle,
    cardHeader: {
        color: "#999",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        textAlign: 'left',
        padingBottom: "3px"
    },
};

const useStyles = makeStyles(styles);


export default function FormEditDescsProduct({idProduct}) {
    // style
    const classes = useStyles();
    // form
    const [descProd, setDescProd] = useState('');
    const [descProdId, setDescProdId] = useState('');
    const [as, setAs] = useState('');
    const [asId, setAsId] = useState('');
    const [terroir, setTerroir] = useState('');
    const [terroirId, setTerroirId] = useState('');
    const [reconnu, setReconnu] = useState('');
    const [reconnuid, setReconnuId] = useState('');
    const [svId, setSvId] = useState('');
    const [sv, setSv] = useState('');

    useEffect(() => {
        products.getAllTerroirFromProduct(idProduct).then(res =>
        {
            setTerroir(res.data.data[0].value);
            setTerroirId(res.data.data[0].id);
        });
        products.getAllDescriptionsFromProduct(idProduct).then(res =>
        {
            setDescProd(res.data.data[0].value);
            setDescProdId(res.data.data[0].id);
        });
        products.getAllFlavorsFromProduct(idProduct).then(res =>
        {
            setAs(res.data.data[0].value);
            setAsId(res.data.data[0].id);
        });
        products.getAllCertificationsFromProduct(idProduct).then(res =>
        {
            setReconnu(res.data.data[0].value);
            setReconnuId(res.data.data[0].id);
        });
        products.getAllInfosFromProduct(idProduct).then(res =>
        {
            setSv(res.data.data[0].value);
            setSvId(res.data.data[0].id);
        });
    }, [idProduct]);

    const handleFormSubmit = () => {
        products.updateDescription(descProdId, idProduct, descProd).then(() => {
          products.updateCertification(reconnuid, idProduct, reconnu).then(() => {
            products.updateFlavor(asId, idProduct, as).then(() => {
              products.updateInfo(svId, idProduct, sv).then(() => {
                products.updateTerroir(terroirId, idProduct, terroir).then(() => {
                  SuccessAlert({message: 'Descriptions modifiées avec succès'});
                })
              })
            })
          })
        })
    };

    return (
        <>
            <FormControl fullWidth className={classes.formControl}>
                <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                    Le Produit
                </label>
                <CKEditor
                  data={descProd}
                  editor={ClassicEditor}
                  onChange={(event, editor) => {
                      const data = editor.getData();
                      setDescProd(data);
                  }}
                  onBlur={(event, editor) => {
                      setDescProd(editor.getData());
                  }}
                  onFocus={(event, editor) => {
                      setDescProd(editor.getData());
                  }}
                />
                <FormHelperText>*</FormHelperText>
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
                <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                    Arômes et saveurs
                </label>
                <CKEditor
                  data={as}
                  editor={ClassicEditor}
                  onChange={(event, editor) => {
                      const data = editor.getData();
                      setAs(data);
                  }}
                  onBlur={(event, editor) => {
                      setAs(editor.getData());
                  }}
                  onFocus={(event, editor) => {
                      setAs(editor.getData());
                  }}
                />
                <FormHelperText>*</FormHelperText>
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
                <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                    Un produit, un territoire
                </label>
                <CKEditor
                  data={terroir}
                  editor={ClassicEditor}
                  onChange={(event, editor) => {
                      const data = editor.getData();
                      setTerroir(data);
                  }}
                  onBlur={(event, editor) => {
                      setTerroir(editor.getData());
                  }}
                  onFocus={(event, editor) => {
                      setTerroir(editor.getData());
                  }}
                />
                <FormHelperText>*</FormHelperText>
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
                <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                    Un produit reconnu
                </label>
                <CKEditor
                  data={reconnu}
                  editor={ClassicEditor}
                  onChange={(event, editor) => {
                      const data = editor.getData();
                      setReconnu(data);
                  }}
                  onBlur={(event, editor) => {
                      setReconnu(editor.getData());
                  }}
                  onFocus={(event, editor) => {
                      setReconnu(editor.getData());
                  }}
                />
                <FormHelperText>*</FormHelperText>
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
                <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                   Le saviez-vous ?
                </label>
                <CKEditor
                  data={sv}
                  editor={ClassicEditor}
                  onChange={(event, editor) => {
                      const data = editor.getData();
                      setSv(data);
                  }}
                  onBlur={(event, editor) => {
                      setSv(editor.getData());
                  }}
                  onFocus={(event, editor) => {
                      setSv(editor.getData());
                  }}
                />
                <FormHelperText>*</FormHelperText>
            </FormControl>
            <Button
                color={"success"}
                disabled={!as && !sv && !descProd && !terroir && !reconnu }
                onClick={handleFormSubmit}
            >
                Modifier
            </Button>
        </>
    );
}