import React from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import Navbar from "../components/Navbars/Navbar.js";
import Footer from "../components/Footer/Footer.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import sections from "../sections.js";
import routes from "../routes.js";
import styles from "../assets/jss/material-dashboard-react/layouts/adminStyle.js";
import bgImage from "../assets/img/background/sidebar-4.jpg";
import logo from "../assets/img/logo/logo.svg";
import Authentication from "../services/authentication/Authentication";
import {withRouter} from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(styles);
let ps;

function Admin({...rest}) {
    const [image] = React.useState(bgImage);

    const switchRoutes = (
      <Switch>
          {routes.map((prop, index) => {
              if (prop.layout === "/admin") {
                  if (Authentication.getUserInfo() == null) {
                      return <Redirect to={"/login"} key={"/login"}/>;
                  }
                  else {
                      return (
                        <Route
                          exact
                          path={prop.layout + prop.path}
                          component={e => <prop.component {...e}/>}
                          key={index}
                        />
                      );
                  }
              }
              return null;
          })}
          <Redirect from="/admin" to="/admin/dashboard" key={"/akey"}/>
      </Switch>
    );


    // styles
    const classes = useStyles();
    // ref to help us initialize PerfectScrollbar on windows devices
    const mainPanel = React.createRef();
    // states and functions
    const color = "blue";
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const getRoute = () => {
        return window.location.pathname;
    };
    const resizeFunction = () => {
        if (window.innerWidth >= 960) {
            setMobileOpen(false);
        }
    };
    // initialize and destroy the PerfectScrollbar plugin
    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current, {
                suppressScrollX: true,
                suppressScrollY: false,
            });
            document.body.style.overflow = "hidden";
        }
        window.addEventListener("resize", resizeFunction);
        // Specify how to clean up after this effect:
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
            window.removeEventListener("resize", resizeFunction);
        };
    }, [mainPanel]);
    return (
      <>
          <div className={classes.wrapper}>
              <Sidebar
                routes={routes}
                sections={sections}
                logo={logo}
                image={image}
                handleDrawerToggle={handleDrawerToggle}
                open={mobileOpen}
                color={color}
                {...rest}
              />
              <div className={classes.mainPanel} ref={mainPanel}>
                  <Navbar
                    routes={routes}
                    handleDrawerToggle={handleDrawerToggle}
                    {...rest}
                  />
                  {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
                  {getRoute() ? (
                    <div className={classes.content}>
                        <div className={classes.container}>{switchRoutes}</div>
                    </div>
                  ) : (
                    <div className={classes.map}>{switchRoutes}</div>
                  )}
                  {getRoute() ? <Footer/> : null}
              </div>
          </div>
      </>
    );
}

export default Admin;
