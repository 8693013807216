import React, { useEffect, useState } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { Card } from "@material-ui/core";
import CardTitleIconPlusTextRight from "../../components/CardHeader/CardTitleIconPlusTextRight";
import CardBody from "../../components/Card/CardBody";
import { DropzoneArea } from "material-ui-dropzone";
import serviceImages from "../../services/images/serviceImages";
import { Link } from "react-router-dom";
import Button from "../../components/CustomButtons/Button";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import axios from "axios";

export default function ViewNewPictures(params) {
    const id = params.match.params.id;
    const id_product = params.match.params.id_product;

    const [image, setImage] = useState({
        alt: "",
        credit: "",
        product_id: null,
        ranking: "",
        title: "",
        content: ""
    });

    const [credit, setCredit] = useState("");
    const [ranking, setRanking] = useState("");

    const [preview, setPreview] = useState(<img style={{maxWidth: '100%'}} src={serviceImages.getUrlVisuImage(id)}
                                                alt={image.alt}/>);
    const [file, setFile] = useState({});

    useEffect(() => {
        serviceImages.getPictures(id).then(res => {
            setImage(
                {
                    alt: res.data.data.alt,
                    credit: res.data.data.credit,
                    product_id: res.data.data.product_id,
                    ranking: res.data.data.ranking,
                    title: res.data.data.title,
                }
            );
            setCredit(res.data.data.credit);
            setRanking(res.data.data.ranking);
        })


    }, [id]);

    const handleChange = (e) => {
        if (e[0] !== undefined) {
            let reader = new FileReader();
            reader.readAsText(e[0], "UTF-8");
            reader.onload = () => {
                setFile(e[0]);
            }
        }
    };

    const handleUpdate = () => {
        if (file.name !== undefined) {
            // update image
            let reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            reader.onload = () => {
              // console.log(file)
                serviceImages.updatePictures(id_product, id, credit, file, file.name, ranking).then(res => {
                        SuccessAlert({message: res.data.message});
                        setPreview(<img style={{maxWidth: '100%'}}
                                        src={serviceImages.getUrlVisuImage(id) + '?' + new Date().getTime()}
                                        alt={image.alt}/>);
                    }
                ).catch(
                    (err) => {
                        console.log(err);
                        Swal.fire('Une erreur est survenue', '', "error").then();
                    }
                );
            }
        } else {
            // keep same image
            axios.get(serviceImages.getUrlVisuImage(id)).then(
                res => {

                    serviceImages.updatePicturesNoContent(id_product, id, credit, image.title, ranking).then(retur => {
                            SuccessAlert({message: retur.data.message});
                            setPreview(<img style={{maxWidth: '100%'}}
                                            src={serviceImages.getUrlVisuImage(id) + '?' + new Date().getTime()}
                                            alt={image.alt}/>);
                        }
                    ).catch(
                        () => {
                            Swal.fire('Une erreur est survenue', '', "error").then();
                        }
                    );
                }
            ).catch(
                () => {
                    Swal.fire('Une erreur est survenue', '', "error").then();
                }
            );
        }
    };

    return (
        <Card style={{overflow: 'unset'}}>
            <CardTitleIconPlusTextRight
                color={'warning'}
                icon={'image'}
                title={"Edition Image"}
            />
            <CardBody>
                <br/>
                <br/>
                <GridContainer
                    justify="center"
                    alignItems="center"
                >
                    <GridItem xs={6}>
                        <DropzoneArea
                            acceptedFiles={['image/*']}
                            dropzoneText={'Déposer un fichier ici'}
                            open={handleChange}
                            onChange={handleChange}
                            onDelete={() => setFile({})}
                            showPreviewsInDropzone={false}
                            showPreviews={true}
                            filesLimit={1}
                        />
                    </GridItem>
                    <GridItem xs={6}>
                        {preview}
                    </GridItem>
                    <GridItem xs={12}>
                        <TextField
                            label="Crédit"
                            error={!credit}
                            helperText={!credit ? 'Le crédit ne peut pas être vide' : ''}
                            value={credit}
                            style={{margin: 8}}
                            placeholder="Crédit"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={e => setCredit(e.target.value)}
                        />
                        <TextField
                          label="Rang"
                          error={!ranking}
                          helperText={!ranking ? 'Le rang ne peut pas être vide' : ''}
                          value={ranking}
                          style={{margin: 8}}
                          placeholder="Rang"
                          fullWidth
                          margin="normal"
                          InputLabelProps={{
                              shrink: true,
                          }}
                          onChange={e => setRanking(e.target.value)}
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer
                    justify="center"
                    alignItems="center"
                >
                    <GridItem>
                        <Button
                            onClick={handleUpdate}
                            color={'success'}
                        >
                            Enregistrer
                        </Button>
                    </GridItem>
                    <GridItem>
                        <Link to={'/admin/edit/products/' + id_product}>
                            <Button
                                color={'danger'}
                            >
                                Retour
                            </Button>
                        </Link>
                    </GridItem>
                </GridContainer>
            </CardBody>

        </Card>
    );
}
