import React, {useEffect, useState} from "react";

// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CardBody from "../../components/Card/CardBody";
import TextField from "@material-ui/core/TextField";
import {Mail} from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "../../components/CustomButtons/Button.js";
import Authentication from '../../services/authentication/Authentication'
import CardLogin from "../../components/CardHeader/CardTitleFullWidth";
import SnackbarContent from "../../components/Snackbar/SnackbarContent";
import Swal from "sweetalert2";

export default function Login (props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    localStorage.setItem("userInfo", null);

    useEffect(()=> {
        localStorage.clear();
    },[]);



    const login = async e => {
        e.preventDefault();
        const credentials = {email: username, password: password};
        await Authentication.login(credentials).then(res => {
            console.log(props);
            if (res.status === 200) {
                localStorage.setItem("userInfo", res.data.data.token);
                props.history.push("/admin/dashboard");
            } else {
                setMessage(res.data.message);
            }
        }).catch(() => {
            setMessage('Une erreur est survenue');
        });
    };

    const forgetPassword = async e => {
        e.preventDefault();

        Swal.fire({
            title: 'Mot de passe oublié',
            text: 'Renseigner votre adresse mail',
            input: 'email',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Envoyer',
            cancelButtonText: 'Annuler',
            showLoaderOnConfirm: true,
            validationMessage: "Saisir un email valide",
            allowOutsideClick: () => !Swal.isLoading(),
            preConfirm: (email) => {
                const credentials = {email: email};
                Authentication.newPassword(credentials).then(res => {
                    Swal.fire(res.data.message);
                }).catch(() => {
                    Swal.fire('Une erreur est survenue');
                });
            }
        }).then( /*= async*/ {
            // console.log(result);
        //     const credentials = {email: result};
        //     await Authentication.newPassword(credentials).then(res => {
        //     console.log(props);
        //     setMessage(res.data.message);
        // }).catch(() => {
        //     setMessage('Une erreur est survenue');
        // });
    })

    };


    const handleMessage = () => {
        setMessage('');
    };


        return (
            <div >

                <GridContainer
                    spacing={0}
                    alignItems="center"
                    justify="center"
                    style={{minHeight: '100vh'}}
                >
                    <GridItem
                        xs={12}
                        sm={6}
                        md={6}
                    >
                        <form>
                            <Card>
                                <CardLogin
                                    color={'warning'}
                                    title={'Interface administrateur de'}
                                    subTitle={'Nos produits de qualité'}
                                />
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <TextField
                                                id="input-with-icon-email"
                                                label="Adresse e-mail"
                                                fullWidth
                                                name="username"
                                                autoComplete={'username'}
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Mail/>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <TextField
                                                type={'password'}
                                                id="input-with-icon-password"
                                                label="Mot de passe"
                                                fullWidth
                                                margin="normal"
                                                name="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                autoComplete={'current-password'}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <VpnKeyIcon/>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem
                                            xs={12}

                                        >
                                            <GridContainer
                                                justify="center"
                                            >
                                                <GridItem>
                                                    <Button
                                                        color="warning"
                                                        onClick={login}
                                                    >
                                                        SE CONNECTER
                                                    </Button>
                                                </GridItem>
                                                <GridItem>
                                                    <Button
                                                        color="link"
                                                        onClick={forgetPassword}
                                                    >
                                                        MOT DE PASSE OUBLIÉ
                                                    </Button>
                                                </GridItem>
                                            </GridContainer>

                                        </GridItem>
                                        <GridItem
                                            xs={12}>
                                            <GridContainer
                                                justify="center"
                                            >
                                                <GridItem>
                                                    {
                                                        message
                                                            ?
                                                            <SnackbarContent
                                                                message={message}
                                                                color="danger"
                                                                close
                                                                open={!!message}
                                                                closeNotification={handleMessage}
                                                            />
                                                            : null
                                                    }
                                                </GridItem>
                                            </GridContainer>

                                        </GridItem>
                                    </GridContainer>
                                </CardBody>

                            </Card>
                        </form>
                    </GridItem>
                </GridContainer>
            </div>
        )
}


