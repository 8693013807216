import React, {useEffect} from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import {Card, Icon} from "@material-ui/core";
import CardTitleIconPlusTextRight from "../../components/CardHeader/CardTitleIconPlusTextRight";
import {Link, withRouter} from "react-router-dom";
import MaterialTable from "material-table";
import Button from "../../components/CustomButtons/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Swal from "sweetalert2";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import serviceCounties from "../../services/counties/serviceCounties";
import Paper from "@material-ui/core/Paper";

export default function CountiesHomeView() {
  const [products, setProducts] = React.useState([]);

  const deleteIt = (id) => {
    Swal.fire({
      title: 'Voulez-vous vraiment suprimer ce département ?',
      text: "Cette action est irreversible !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Suprimer!',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.value) {
        serviceCounties.deleteCounty(id).then(() => {
          SuccessAlert({message: 'Département supprimé avec succès !'});
          serviceCounties.getAllCounties().then(res => {
            setProducts(res.data.data)
          }).catch(() => {
            setProducts([]);
          });
        }).catch(() => {
          Swal.fire('Une erreur est survenue !').then();
        })
      }
    })
  };

  useEffect(() => {
    serviceCounties.getAllCounties().then(res => {
      setProducts(res.data.data)
    }).catch(err => {
      setProducts([]);
      console.log(err);
    });
  }, []);


  const columns = [
    {title: "Nom", field: "value"},
    {title: "Code", field: "code", defaultSort: "asc"},
    {
      title: "Actions",
      field: "actions",
      headerStyle: {textAlign: 'right'},
      cellStyle: {textAlign: 'right'},
      render: (row) =>
        <>
          <Tooltip
            title="Éditer"
          >
            <Link to={'/admin/edit/counties/' + row.id}>
              <Button justIcon={true} round={true} color={'primary'}>
                <Icon>
                  edit
                </Icon>
              </Button>
            </Link>
          </Tooltip>
          <Tooltip
            title="Suprimer"
          >
                <span>
                    <Button
                      justIcon={true}
                      round={true}
                      onClick={() => deleteIt(row.id)}
                      color={'danger'}
                    >
                        <Icon>
                            delete
                        </Icon>
                    </Button>
                </span>
          </Tooltip>
        </>
    }
  ];

  return (
    <>
      <GridContainer
        justify={"flex-end"} style={{paddingBottom: '2%'}}
      >
        <GridItem xs={4} md={4}>
          <Link to={'/admin/new/county'}>
            <Button type={"button"} color={"success"}>
              <Icon>add</Icon>
              Ajouter un département
            </Button>
          </Link>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} md={12}>
          <Card style={{overflow: "unset"}}>
            <CardTitleIconPlusTextRight color={"success"} icon={"assignment"}
                                        title={"Liste des départements"}/>

            <MaterialTable
              components={{
                Container: props => <Paper {...props} elevation={0}/>
              }}
              localization={{
                toolbar: {
                  searchPlaceholder: "Rechercher",
                  searchTooltip: "Rechercher",
                },
              }}
              title={' '}
              columns={columns}
              data={products}
              options={
                {
                  pageSize: 50,
                  pageSizeOptions: [5, 10, 20, 50],
                  sorting: true
                }
              }
            />

          </Card>
        </GridItem>
      </GridContainer>
    </>
  );

}
