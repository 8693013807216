import React, {useEffect} from "react";

import {Link, withRouter} from "react-router-dom";
import {Card, Icon} from "@material-ui/core";
import CardBody from "../../components/Card/CardBody";
import CardTitleIconPlusTextRight from "../../components/CardHeader/CardTitleIconPlusTextRight";
import Button from "../../components/CustomButtons/Button.js";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import userService from "../../services/users/users";
import Authentication from "../../services/authentication/Authentication";
import TableUserConnected from "../../components/Table/Users/TableUserConnected";
import MaterialTable from "material-table";
import Tooltip from "@material-ui/core/Tooltip";
import Swal from "sweetalert2";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import Paper from "@material-ui/core/Paper";


export default function UsersView(props) {
  const [connected, setConnected] = React.useState({});
  const [users, setUsers] = React.useState([]);

  const deleteIt = (id) => {
    Swal.fire({
      title: 'Voulez-vous vraiment supprimer cet utilisateur ?',
      text: "Cette action est irreversible !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Supprimer !',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.value) {
        userService.deleteUser(id).then(() => {
          SuccessAlert({message: 'Utilisateur supprimé avec succès !'});
          userService.getOthersUsers().then(res => {
            let tab = this.generateTableContent(res.data.data);
            this.setState({
              users: tab
            });
          }).catch(() => {
            this.setState({
              users: []

            });
          });
        }).catch(() => {
          Swal.fire('Une erreur est survenue !').then();
        })
      }
    })
  };

  useEffect(() => {
    Authentication.getUser().then(res => {
      setConnected(res.data.data);
    }).catch(() => {
      setConnected([]);
    });

    userService.getOthersUsers().then(res => {
      setUsers(res.data.data)
    }).catch(() => {
      setUsers([]);
    });
  }, []);


  const columns = [
    {title: 'Nom', field: 'name'},
    {
      title: 'Email',
      field: 'email',
      headerStyle: {textAlign: 'center'},
      cellStyle: {textAlign: 'center'}
    },
    {
      title: 'Actions', field: 'actions', render: row =>
        <Tooltip
          title="Supprimer"
        >
                    <span>
                        <Button
                          justIcon={true}
                          round={true}
                          onClick={() => deleteIt(row.id)}
                          color={'danger'}
                        >
                            <Icon>
                                delete
                            </Icon>
                        </Button>
                    </span>
        </Tooltip>,
      export: false,
      headerStyle: {textAlign: 'right'},
      cellStyle: {textAlign: 'right'}
    },
  ];

  return (
    <>
      <GridContainer
        justify={"flex-end"} style={{paddingBottom: '2%'}}
      >
        <GridItem xs={4} md={4}>
          <Link to={'/admin/users/new'}>
            <Button type={"button"} color={"success"}>
              <Icon>add</Icon>
              Ajouter un utilisateur
            </Button>
          </Link>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{overflow: 'unset'}}> {/* "unset" is important */}
            <CardBody>
              <TableUserConnected user={connected} props={props}/>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} md={12}>
          <Card style={{overflow: 'unset'}}>
            <CardTitleIconPlusTextRight
              color={'warning'}
              icon={'person'}
              title={'Liste des utlisateurs'}
            />
            <MaterialTable
              components={{
                Container: props => <Paper {...props} elevation={0}/>
              }}
              searchText={'Rechercher'}
              searchPlaceholder={'Rechercher'}
              title={' '}
              columns={columns}
              data={users}
              options={
                {
                  sorting: true
                }
              }
            />

          </Card>
        </GridItem>
      </GridContainer>
    </>
  )
}
