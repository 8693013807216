
import React, {useEffect, useState} from "react";
import serviceAccueil from "../../services/accueil/serviceAccueil"

import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card";
import CardTitleIconPlusTextRight from "../../components/CardHeader/CardTitleIconPlusTextRight";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import ErrorAlert from "../../components/Alerts/ErrorAlert";

import {createBrowserHistory} from "history";
import Button from "../../components/CustomButtons/Button";
import { Icon } from "@material-ui/core";

import SuccessAlert from "../../components/Alerts/SuccessAlert";
import WarningAlert from "../../components/Alerts/WarningAlert";

import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import {makeStyles} from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import CardBody from "../../components/Card/CardBody";

const hist = createBrowserHistory();
const styles = {
    ...dashboardStyle,
    cardHeader: {
        color: "#999",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        textAlign: 'left',
        padingBottom: "3px"
    },
};
const useStyles = makeStyles(styles);

export default function AccueilView(props) {

    const classes = useStyles();
    const [id, setID] = useState('');
    const [accueil_header_intro, setAccueil_header_intro] = useState('');
    const [accueil_social_title, setAccueil_social_title] = useState('');
    const [accueil_key_numbers_background, setAccueil_key_numbers_background] = useState('');
    const [accueil_key_numbers_one, setAccueil_key_numbers_one] = useState('');
    const [accueil_key_numbers_two, setAccueil_key_numbers_two] = useState('');
    const [accueil_key_numbers_three, setAccueil_key_numbers_three] = useState('');
    const [accueil_key_numbers_title, setAccueil_key_numbers_title] = useState('');
    const [accueil_search_map_sub_title, setAccueil_search_map_sub_title] = useState('');
    const [accueil_search_form_sub_title, setAccueil_search_form_sub_title] = useState('');
    const [accueil_search_title, setAccueilSearchTitle] = useState('');
    const [accueil_tooltip_label_aop, setAccueil_tooltip_label_aop] = useState('');
    const [accueil_tooltip_label_igp, setAccueil_tooltip_label_igp] = useState('');
    const [accueil_tooltip_label_stg, setAccueil_tooltip_label_stg] = useState('');
    const [accueil_tooltip_label_lr, setAccueil_tooltip_label_lr] = useState('');
    const [accueil_tooltip_label_ab, setAccueil_tooltip_label_ab] = useState('');
    const [accueil_key_numbers_one_info_text, setAccueil_key_numbers_one_info_text] = useState('');
    const [accueil_key_numbers_two_info_text, setAccueil_key_numbers_two_info_text] = useState('');
    const [accueil_key_numbers_three_info_text, setAccueil_key_numbers_three_info_text] = useState('');
    const [content_title_slider, setContentTitleSlider] = useState('');
    const [title_accueil, setTitleAccueil] = useState('');
    const [desc_seo_accueil, setDescSeoAccueil] = useState('');

    useEffect(() => {
            serviceAccueil.getAccueil().then(res => {
                setID(res.data.data.id);
                setAccueil_header_intro(res.data.data.accueil_header_intro);
                setAccueil_social_title(res.data.data.accueil_social_title);
                setAccueil_key_numbers_background(res.data.data.accueil_key_numbers_background);
                setAccueil_key_numbers_one(res.data.data.accueil_key_numbers_one);
                setAccueil_key_numbers_two(res.data.data.accueil_key_numbers_two);
                setAccueil_key_numbers_three(res.data.data.accueil_key_numbers_three);
                setAccueil_key_numbers_title(res.data.data.accueil_key_numbers_title);
                setAccueil_search_map_sub_title(res.data.data.accueil_search_map_sub_title);
                if (res.data.data.accueil_search_form_sub_title)
                    setAccueil_search_form_sub_title(res.data.data.accueil_search_form_sub_title);
                if (res.data.data.accueil_search_title)
                    setAccueilSearchTitle(res.data.data.accueil_search_title);
                setAccueil_tooltip_label_aop(res.data.data.accueil_tooltip_label_aop);
                setAccueil_tooltip_label_igp(res.data.data.accueil_tooltip_label_igp);
                setAccueil_tooltip_label_stg(res.data.data.accueil_tooltip_label_stg);
                setAccueil_tooltip_label_lr(res.data.data.accueil_tooltip_label_lr);
                setAccueil_tooltip_label_ab(res.data.data.accueil_tooltip_label_ab);
                setAccueil_key_numbers_one_info_text(res.data.data.accueil_key_numbers_one_info_text);
                setAccueil_key_numbers_two_info_text(res.data.data.accueil_key_numbers_two_info_text);
                setAccueil_key_numbers_three_info_text(res.data.data.accueil_key_numbers_three_info_text);
                setContentTitleSlider(res.data.data.content_title_slider);
                setTitleAccueil(res.data.data.title_accueil);
                setDescSeoAccueil(res.data.data.desc_seo_accueil);
            });
        },
        []);

    const isFormValid = () => {
        if (!accueil_header_intro) {
            WarningAlert({
                message: "Le header doit être renseigné",
            });
            return false;
        }
        if (!accueil_social_title) {
            WarningAlert({
                message: "Le accueil_social_title doit être renseigné",
            });
            return false;
        }
        if (!accueil_key_numbers_background) {
            WarningAlert({
                message: "L'adresse doit être renseignée",
            });
            return false;
        }
        if (!accueil_key_numbers_one) {
            WarningAlert({
                message: "Le numéro de téléphone doit être renseigné",
            });
            return false;
        }
        if (!accueil_key_numbers_two) {
            WarningAlert({
                message: "Le chemin de l'image doit être renseigné",
            });
            return false;
        }
        if (!accueil_key_numbers_three) {
            WarningAlert({
                message: "Le chemin des mentions doit être renseigné",
            });
            return false;
        }
        if (!accueil_key_numbers_title) {
            WarningAlert({
                message: "Le nom du menu 'Accueil' doit être renseigné",
            });
            return false;
        }
        if (!accueil_search_map_sub_title) {
            WarningAlert({
                message: "Le nom du menu 'Carte' doit être renseigné",
            });
            return false;
        }
        if (!accueil_tooltip_label_aop) {
            WarningAlert({
                message: "Le nom du menu '5 Signes officiels' doit être renseigné",
            });
            return false;
        }
        if (!accueil_tooltip_label_igp) {
            WarningAlert({
                message: "Le nom du menu 'Tous les produits' doit être renseigné",
            });
            return false;
        }
        if (!accueil_tooltip_label_stg) {
            WarningAlert({
                message: "Le nom du menu 'Contact' doit être renseigné",
            });
            return false;
        }
        if (!accueil_tooltip_label_lr) {
            WarningAlert({
                message: "L'Id du tracker de google analytics doit être renseigné",
            });
            return false;
        }
        if (!accueil_tooltip_label_ab) {
            WarningAlert({
                message: "L'Id du tracker de google analytics doit être renseigné",
            });
            return false;
        }
        if (!accueil_key_numbers_one_info_text) {
            WarningAlert({
                message: "L'Id du tracker de google analytics doit être renseigné",
            });
            return false;
        }
        if (!accueil_key_numbers_two_info_text) {
            WarningAlert({
                message: "L'Id du tracker de google analytics doit être renseigné",
            });
            return false;
        }
        if (!accueil_key_numbers_three_info_text) {
            WarningAlert({
                message: "L'Id du tracker de google analytics doit être renseigné",
            });
            return false;
        }
        return true;
    };


    const handleSave = async () => {
        // valid
        if (isFormValid()) {
            await serviceAccueil.updateAccueil(id, accueil_header_intro, accueil_social_title, accueil_key_numbers_background, accueil_key_numbers_one, accueil_key_numbers_two, accueil_key_numbers_three, accueil_key_numbers_title,
                accueil_search_map_sub_title, accueil_search_title, accueil_search_form_sub_title   ,accueil_tooltip_label_aop, accueil_tooltip_label_igp, accueil_tooltip_label_stg,accueil_tooltip_label_lr, accueil_tooltip_label_ab,
                accueil_key_numbers_one_info_text, accueil_key_numbers_two_info_text, accueil_key_numbers_three_info_text, content_title_slider, title_accueil, desc_seo_accueil).then(async (res) => {
                SuccessAlert({message: res.data.message});
            }).catch((err) => {
                    ErrorAlert({message: 'Une erreur est survenue'});
                    console.log(err);
                }
            );
        }
    };

    return (
        <>
            <Button onClick={hist.goBack}> Retour </Button>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={6}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color={"info"} icon>
                                    <CardIcon color={"info"}>
                                        <Icon>
                                            short_text
                                        </Icon>
                                    </CardIcon>
                                    <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                        Carrousel
                                    </h5>
                                </CardHeader>

                                <CardBody>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                            Titre carrousel
                                        </label>
                                        <TextField multiline value={content_title_slider}
                                                   onChange={(event) => setContentTitleSlider(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                </CardBody>

                                <CardBody>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                            Accroche du carrousel
                                        </label>
                                        <CKEditor
                                            data={accueil_header_intro}
                                            editor={ClassicEditor}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setAccueil_header_intro(data);
                                            }}
                                            onBlur={(event, editor) => {
                                                setAccueil_header_intro(editor.getData());
                                            }}
                                            onFocus={(event, editor) => {
                                                setAccueil_header_intro(editor.getData());
                                            }}
                                        />
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                </CardBody>
                                {/* <FormControl fullWidth className={classes.formControl}>
                                    <TextField multiline label="Titre des réseaux sociaux" value={accueil_social_title}
                                               onChange={(event) => setAccueil_social_title(event.target.value)}/>
                                    <FormHelperText>*</FormHelperText>
                                </FormControl> */}

                            </Card>
                        </GridItem>
                        {/*Menu*/}

                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color={"info"} icon>
                                    <CardIcon color={"info"}>
                                        <Icon>
                                            short_text
                                        </Icon>
                                    </CardIcon>
                                    <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                        Info-bulle signes
                                    </h5>
                                </CardHeader>

                                <CardBody>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                            AOP
                                        </label>
                                        <TextField value={accueil_tooltip_label_aop}
                                                   onChange={(event) => setAccueil_tooltip_label_aop(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>

                                    <FormControl fullWidth className={classes.formControl}>
                                        <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                            IGP
                                        </label>
                                        <TextField value={accueil_tooltip_label_igp}
                                                   onChange={(event) => setAccueil_tooltip_label_igp(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>

                                    <FormControl fullWidth className={classes.formControl}>
                                        <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                            STG
                                        </label>
                                        <TextField value={accueil_tooltip_label_stg}
                                                   onChange={(event) => setAccueil_tooltip_label_stg(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>

                                    <FormControl fullWidth className={classes.formControl}>
                                        <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                            Label Rouge
                                        </label>
                                        <TextField value={accueil_tooltip_label_lr}
                                                   onChange={(event) => setAccueil_tooltip_label_lr(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>

                                    <FormControl fullWidth className={classes.formControl}>
                                        <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                            AB
                                        </label>
                                        <TextField value={accueil_tooltip_label_ab}
                                                   onChange={(event) => setAccueil_tooltip_label_ab(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>

                                    {/*<FormControl fullWidth className={classes.formControl}>
                                    <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                    AB
                                    </label>
                                    <CKEditor
                                        data={accueil_tooltip_label_ab}
                                        editor={ClassicEditor}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setAccueil_tooltip_label_ab(data);
                                        }}
                                        onBlur={(event, editor) => {
                                            setAccueil_tooltip_label_ab(editor.getData());
                                        }}
                                        onFocus={(event, editor) => {
                                            setAccueil_tooltip_label_ab(editor.getData());
                                        }}
                                    />
                                    <FormHelperText>*</FormHelperText>
                                </FormControl>*/}
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={6}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color={"info"} icon>
                                    <CardIcon color={"info"}>
                                        <Icon>
                                            short_text
                                        </Icon>
                                    </CardIcon>
                                    <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                        Recherche
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                            Intertitre
                                        </label>
                                        <TextField multiline value={accueil_search_map_sub_title}
                                                   onChange={(event) => setAccueil_search_map_sub_title(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                            Sous-titre carte
                                        </label>
                                        <CKEditor
                                            data={accueil_search_title}
                                            editor={ClassicEditor}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setAccueilSearchTitle(data);
                                            }}
                                            onBlur={(event, editor) => {
                                                setAccueilSearchTitle(editor.getData());
                                            }}
                                            onFocus={(event, editor) => {
                                                setAccueilSearchTitle(editor.getData());
                                            }}
                                        />
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                            Sous-titre moteur de recherche
                                        </label>
                                        <CKEditor
                                            data={accueil_search_form_sub_title}
                                            editor={ClassicEditor}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setAccueil_search_form_sub_title(data);
                                            }}
                                            onBlur={(event, editor) => {
                                                setAccueil_search_form_sub_title(editor.getData());
                                            }}
                                            onFocus={(event, editor) => {
                                                setAccueil_search_form_sub_title(editor.getData());
                                            }}
                                        />
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color={"info"} icon>
                                    <CardIcon color={"info"}>
                                        <Icon>
                                            short_text
                                        </Icon>
                                    </CardIcon>
                                    <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                        Chiffres-clés
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                            Chiffre-clé 1 (Valeur)
                                        </label>
                                        <TextField multiline value={accueil_key_numbers_one}
                                                   onChange={(event) => setAccueil_key_numbers_one(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                            Chiffre-clé 1 (Texte)
                                        </label>
                                        <CKEditor
                                            data={accueil_key_numbers_one_info_text}
                                            editor={ClassicEditor}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setAccueil_key_numbers_one_info_text(data);
                                            }}
                                            onBlur={(event, editor) => {
                                                setAccueil_key_numbers_one_info_text(editor.getData());
                                            }}
                                            onFocus={(event, editor) => {
                                                setAccueil_key_numbers_one_info_text(editor.getData());
                                            }}
                                        />
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                            Chiffre-clé 2 (Valeur)
                                        </label>
                                        <TextField multiline value={accueil_key_numbers_two}
                                                   onChange={(event) => setAccueil_key_numbers_two(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                            Chiffre-clé 2 (Texte)
                                        </label>
                                        <CKEditor
                                            data={accueil_key_numbers_two_info_text}
                                            editor={ClassicEditor}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setAccueil_key_numbers_two_info_text(data);
                                            }}
                                            onBlur={(event, editor) => {
                                                setAccueil_key_numbers_two_info_text(editor.getData());
                                            }}
                                            onFocus={(event, editor) => {
                                                setAccueil_key_numbers_two_info_text(editor.getData());
                                            }}
                                        />
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                            Chiffre-clé 3 (Valeur)
                                        </label>
                                        <TextField multiline value={accueil_key_numbers_three}
                                                   onChange={(event) => setAccueil_key_numbers_three(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                            Chiffre-clé 3 (Texte)
                                        </label>
                                        {/*<TextField multiline value={accueil_key_numbers_three_info_text}
                                            onChange={(event) => setAccueil_key_numbers_three_info_text(event.target.value)}/>*/}
                                        <CKEditor
                                            data={accueil_key_numbers_three_info_text}
                                            editor={ClassicEditor}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setAccueil_key_numbers_three_info_text(data);
                                            }}
                                            onBlur={(event, editor) => {
                                                setAccueil_key_numbers_three_info_text(editor.getData());
                                            }}
                                            onFocus={(event, editor) => {
                                                setAccueil_key_numbers_three_info_text(editor.getData());
                                            }}
                                        />
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    {/*<FormControl fullWidth className={classes.formControl}>
                                    <TextField multiline label="Chemin image" value={accueil_key_numbers_background}
                                               onChange={(event) => setAccueil_key_numbers_background(event.target.value)}/>
                                    <FormHelperText>*</FormHelperText>
                                </FormControl>*/}
                                </CardBody>
                            </Card>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12} lg={12}>

                            <Card>
                                <CardHeader color={"rose"} icon>
                                    <CardIcon color={"rose"}>
                                        <Icon>
                                            store
                                        </Icon>
                                    </CardIcon>
                                    <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                        Tags SEO
                                    </h5>
                                </CardHeader>

                                <CardBody>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                            Titre SEO
                                        </label>
                                        <TextField multiline value={title_accueil}
                                                   onChange={(event) => setTitleAccueil(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                            Description
                                        </label>
                                        <TextField value={desc_seo_accueil}
                                                   onChange={(event) => setDescSeoAccueil(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                </CardBody>
                            </Card>
                        </GridItem>

                    </GridContainer>
                </GridItem>

                {/*<GridItem xs={12} sm={12} md={12} lg={6}>
                <GridContainer>
                    Footer
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card>
                            <CardHeader color={"info"} icon>
                                <CardIcon color={"info"}>
                                    <Icon>
                                        short_text
                                    </Icon>
                                </CardIcon>
                                <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                    Informations Footer
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <FormControl fullWidth className={classes.formControl}>
                                    <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                        Adresse
                                    </label>
                                    <CKEditor
                                        data={footer_address_path}
                                        editor={ClassicEditor}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setFooterAddressPath(data);
                                        }}
                                        onBlur={(event, editor) => {
                                            setFooterAddressPath(editor.getData());
                                        }}
                                        onFocus={(event, editor) => {
                                            setFooterAddressPath(editor.getData());
                                        }}
                                    />
                                    <FormHelperText>*</FormHelperText>
                                </FormControl>
                                <FormControl fullWidth className={classes.formControl}>
                                    <TextField multiline label="Téléphone" value={footer_phone_path}
                                               onChange={(event) => setFooterPhonePath(event.target.value)}/>
                                    <FormHelperText>*</FormHelperText>
                                </FormControl>
                                <FormControl fullWidth className={classes.formControl}>
                                    <TextField multiline label="Chemin du logo NOS" value={footer_logo}
                                               onChange={(event) => setFooterLogo(event.target.value)}/>
                                    <FormHelperText>*</FormHelperText>
                                </FormControl>
                                <FormControl fullWidth className={classes.formControl}>
                                    <TextField multiline label="Chemin du logo 5 signes" value={footer_logo_five_signs_path}
                                               onChange={(event) => setFooterLogoFiveSignsPath(event.target.value)}/>
                                    <FormHelperText>*</FormHelperText>
                                </FormControl>
                                 <FormControl fullWidth className={classes.formControl}>
                                    <TextField multiline label="Chemin" value={footer_mentions_path}
                                                   onChange={(event) => setFooterMentionsPath(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </GridItem>*/}


                {/*Submit button*/}
                <GridItem xs={12}>
                    <Button
                        fullWidth
                        color={'info'}
                        onClick={handleSave}
                    >
                        <Icon>check</Icon>&nbsp;Sauvegarder
                    </Button>
                </GridItem>
            </GridContainer>
        </>
    );
}
