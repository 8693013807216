import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CardHeader from "../Card/CardHeader";
import React from "react";

const stylesBis = {
    ...dashboardStyle,
    cardTitle: {
        marginTop: "0",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        textAlign: "center",
        justifyContent: "center"
    },
    center: {
        textAlign: "center",
        justifyContent: "center"
    }
};

const useStyles = makeStyles(stylesBis);

export default function CardHeaderFullWidth(props) {
    const {color, title, subTitle} = props;
    const classes = useStyles();
    return (
        <>
            <CardHeader color={color}>
                <h4 className={classes.cardTitle}>
                   {title}
                    <br/>
                    <b>{subTitle}</b>
                </h4>
            </CardHeader>
        </>
    );
}



