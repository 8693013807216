import React from "react";

import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import {Card} from "@material-ui/core";
import CardTitleIconPlusTextRight from "../../components/CardHeader/CardTitleIconPlusTextRight";
import CardBody from "../../components/Card/CardBody";
import FormNewSign from "../../components/Forms/Signs/FormNewSign";

class NewSign extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <>
                <br/>
                <GridContainer>
                    <GridItem xs={12} md={12}>
                        <Card style={{overflow: 'unset'}}>
                            <CardTitleIconPlusTextRight color={'rose'} icon={'local_offer'} title={'Création du nouveau Signe'} />
                            <CardBody>
                                <FormNewSign props={this.props}/>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </>
        );
    }
}

export default NewSign;
