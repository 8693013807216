import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "../../../components/CustomButtons/Button.js";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import SuccessAlert from "../../Alerts/SuccessAlert";
import GridItem from "../../Grid/GridItem.js";
import GridContainer from "../../Grid/GridContainer";
import serviceCounties from "../../../services/counties/serviceCounties";

export default function FormNewCounty(props) {
  const [label, setLabel] = useState("");
  const [code, setCode] = useState("");
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    IsFormValid();
  }, [label, code]);

  const IsFormValid = () => {
    if (label !== "" && code !== "") {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  };

  const handleFormSubmit = () => {
    serviceCounties.newCounty(label, code).then(res => {
      SuccessAlert({ message: res.data.message });
      props.props.history.push("/admin/counties");
    }).catch((e) => {
      Swal.fire("Une erreur est survenue !").then();
    });
  };

  return (
    <>
      <GridContainer
        justify="center"
        alignItems="center"
      >
        <GridItem xs={12}>
          <TextField
            label="Label"
            error={!label}
            style={{ margin: 8 }}
            placeholder="Label"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            onChange={e => {
              setLabel(e.target.value);
            }}
          />
          <TextField
            label="Code"
            error={!code}
            style={{ margin: 8 }}
            placeholder="Code"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            onChange={e => {
              setCode(e.target.value);
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer
        justify="center"
        alignItems="center"
      >
        <GridItem>
          <Button
            color={"success"}
            disabled={!formValid}
            onClick={handleFormSubmit}
          >
            Ajouter
          </Button>
        </GridItem>
        <GridItem>
          <Link to={"/admin/counties"}>
            <Button
              color={"danger"}
            >
              Annuler
            </Button>
          </Link>
        </GridItem>
      </GridContainer>
    </>
  );
}
