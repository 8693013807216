import React from "react";
import { Card } from "@material-ui/core";
import CardTitleIconPlusTextRight from "../../components/CardHeader/CardTitleIconPlusTextRight";
import CardBody from "../../components/Card/CardBody";
import { DropzoneArea } from "material-ui-dropzone";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import products from "../../services/products/products";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Button from "../../components/CustomButtons/Button";

export default function ViewNewPictures(params) {
  const id = params.match.params.id;

  const handleChange = (files) => {
    if (files[0] !== undefined) {
      let reader = new FileReader();
      reader.readAsText(files[0], "UTF-8");
      reader.onload = () => {
        products.uploadPicture(files[0].name, files[0], id).then((res) => {
          SuccessAlert({ message: res.data.message });
          params.history.push("/admin/products/" + id + "/pictures/" + res.data.data.id + "/edit");
        }).catch(() => {
          Swal.fire("Une erreur est survenue !").then();
        });
      };
    }
  };


  return (

    <Card style={{ overflow: "unset", width: "100%" }}>
      <CardTitleIconPlusTextRight
        color={"warning"}
        icon={"image"}
        title={"Nouvelle Image"}
      />
      <CardBody>
        <br/>
        <br/>
        <DropzoneArea
          // initialFiles={files}
          acceptedFiles={["image/*"]}
          dropzoneText={"Déposer un fichier ici"}
          onChange={handleChange}
          showPreviewsInDropzone={false}
          showPreviews={true}
          filesLimit={1}
        />
        <Link to={"/admin/edit/products/" + id}>
          <Button
            color={"danger"}
          >
            Annuler
          </Button>
        </Link>
      </CardBody>
    </Card>

  );
}
