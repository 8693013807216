import React, {useEffect, useState} from "react";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "../../../components/CustomButtons/Button.js";
import {Icon} from "@material-ui/core";
import Swal from "sweetalert2";
import {Link} from "react-router-dom";
import serviceSigns from "../../../services/signs/signs";
import LinkVisuImageSign from "../../Images/LinkVisuImageSign";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";

export default function TableImagesListSigns({Sign}) {

    const [images, setImages] = useState([]);

    useEffect(() => {

        serviceSigns.getAllImagesFromSign(Sign).then(res => {
            setImages(res.data.data)
        });

    }, [Sign]);


    const DeleteImage = (id) => {
        Swal.fire({
            title: 'Confirmez-vous ?',
            text: "Cette action est irréversible !",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui, supprimer !',
            cancelButtonText: 'Annuler'
        }).then((result) => {
            if (result.value) {
                serviceSigns.deleteImage(id).then(() => {
                    Swal.fire(
                        'Image Supprimée !',
                        'Image Supprimée !',
                        'success'
                    ).then();
                    serviceSigns.getAllImagesFromSign(Sign).then(res => setImages(res.data.data));
                }).catch(() => {
                    Swal.fire(
                        'Une erreur est survenue',
                        '',
                        'error',
                    ).then();
                });

            }
        })

    };

    return (
        <GridContainer

        >
            <GridItem xs={12}>
                {images.length === 0 ? <Link to={'/admin/signs/' + Sign + '/pictures/new'}>
                    <Button
                        color={'success'}
                    >
                        Ajouter une nouvelle Image
                    </Button>
                </Link> : null}
            </GridItem>
            <GridItem xs={12}>
                <Table aria-label="Terroirs">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Nom Fichier
                            </TableCell>
                            <TableCell align="right">
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {images ? images.map(row => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    {row.title}
                                </TableCell>
                                <TableCell align="right">
                                    <LinkVisuImageSign
                                        text={row.title ? row.title : row.alt}
                                        idImage={row.id}
                                    />
                                    <Link to={'/admin/signs/' + Sign + '/pictures/' + row.id + '/edit'}>
                                        <Button
                                            round
                                            color="success"
                                            justIcon
                                        >
                                            <Icon>
                                                edit
                                            </Icon>
                                        </Button>
                                    </Link>
                                    <Button
                                        round
                                        color="danger"
                                        justIcon
                                        onClick={() => DeleteImage(row.id)}
                                    >
                                        <Icon>
                                            delete
                                        </Icon>
                                    </Button>

                                </TableCell>
                            </TableRow>
                        )) : <TableRow/>}
                    </TableBody>
                </Table>
            </GridItem>
        </GridContainer>
    );
}



