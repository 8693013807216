// @material-ui/icons
import {
  Person,
  Dashboard,
  Store,
  Folder,
  Map,
  ListAlt,
  LocalOffer, Fastfood, Check, Forum, InfoOutlined, MeetingRoom, AccountBox, HomeRounded, SupervisorAccount, VideoLabel
} from "@material-ui/icons";

import Login from "views/Login/Login.js";
import ViewAdresses from "views/Adresses/ViewAdresses";
import DashboardPage from "views/Dashboard/Dashboard.js";
import NewProduct from "./views/Product/NewProduct";
import EditProduct from "./views/Product/EditProduct";
import SignsHomeView from "./views/Signs/SignsHomeView";
import NewSign from "./views/Signs/NewSign";
import EditTerroir from "./views/Terroirs/EditTerroir";
import EditFlavors from "./views/Flavors/EditFlavors";
import EditCertification from "./views/Certifications/EditCertification";
import EditDescription from "./views/Decriptions/EditDescription";
import EditInfo from "./views/Infos/EditInfo";
import ViewEditPictures from "./views/Pictures/ViewEditPictures";
import ViewNewPictures from "./views/Pictures/ViewNewPictures";
import RecapProducts from "./views/Product/RecapProducts";
import EditSign from "./views/Signs/EditSign";
import UsersView from "./views/Users/UsersView";
import NewUser from "./views/Users/NewUser";
import EditUser from "./views/Users/EditUser";
import CategoriesHomeView from "./views/Categories/CategoriesHomeView";
import NewCategorie from "./views/Categories/NewCategorie";
import EditCategorie from "./views/Categories/EditCategorie";
import CountiesHomeView from "./views/Counties/CountiesHomeView";
import NewCounty from "./views/Counties/NewCounty";
import EditCounty from "./views/Counties/EditCounty";
import OdgsHomeView from "./views/Odgs/OdgsHomeView";
import NewOdg from "./views/Odgs/NewOdg";
import EditOdg from "./views/Odgs/EditOdg";
import SignAddPictureView from "./views/Signs/SignAddPictureView";
import EditSignPictureView from "./views/Signs/EditSignPictureView";
import Logout from "./views/Logout/Lougout";
import ProductsHome from "./views/Product/ProductsHome";
import AccueilView from "./views/Accueil/AccueilView";
import EditGeneral from "./views/General/EditGeneral";
import PagesHome from "./views/Pages/PagesHome";
import EditPage from "./views/Pages/EditPage";
import EditMetaSEO from "./views/MetaSEO/EditMetaSEO";
import EditRxSociaux from "./views/RxSociaux/EditRxSociaux";
import EditPageCarrousel from "./views/Pages/EditPageCarrousel";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    desc: "Dashboard",
    display: true,
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    section : "",
  },
  {
    path: "/accueil",
    name: "ACCUEIL",
    icon: HomeRounded,
    desc: "Gestion de la page d'accueil",
    display: true,
    component: AccueilView,
    layout: "/admin",
    section : ""
  },
  {
    path: "/pages",
    name: "Pages",
    desc: "Gestion des Pages",
    display: true,
    icon: Dashboard,
    component: PagesHome,
    layout: "/admin",
    section : "pagesDisplay"
  },
  {
    path: "/edit/pages/:id",
    name: "Pages sans carrousel",
    desc: "Gestion des Pages sans carrousel",
    display: false,
    icon: Dashboard,
    component: EditPage,
    layout: "/admin",
    section : ""
  },
  {
    path: "/edit/pages/:id/pagecarrousel",
    name: "Pages avec Carrousel",
    desc: "Gestion des Pages avec carrousel",
    display: false,
    icon: "",
    component: EditPageCarrousel,
    layout: "/admin",
    section : ""
  },
  {
    path: "/products",
    name: "FICHES PRODUITS",
    desc: "Gestion des Produits",
    icon: Store,
    display: true,
    component: ProductsHome,
    layout: "/admin",
    section : "FicheProduits"
  },
  {
    path: "/signs",
    name: "SIQO",
    desc: "Gestion des Signes",
    display: true,
    icon: LocalOffer,
    component: SignsHomeView,
    layout: "/admin",
    section : "FicheProduits"
  },
  {
    path: "/products/new",
    name: "Produits",
    desc: "Nouveau Produit",
    icon: Store,
    display: false,
    component: NewProduct,
    layout: "/admin",
    section : ""
  },
  {
    path: "/products/:id_product/pictures/:id/edit",
    name: "Edition Image",
    desc: "Edition Image",
    icon: Store,
    display: false,
    component: ViewEditPictures,
    layout: "/admin",
    section : ""
  },
  {
    path: "/products/:id/pictures/new",
    name: "Nouvelle Image",
    desc: "Nouvelle Image",
    icon: Store,
    display: false,
    component: ViewNewPictures,
    layout: "/admin",
    section : ""
  },
  {
    path: "/edit/products/:id",
    name: "Produits",
    desc: "Edition Produit",
    icon: Store,
    display: false,
    component: EditProduct,
    layout: "/admin",
    section : ""
  },
  {
    path: "/show/:id/product",
    name: "Recapitulatifs Produit",
    desc: "Recapitulatifs Produit",
    icon: Store,
    display: false,
    component: RecapProducts,
    layout: "/admin",
    section : ""
  },
  {
    path: "/counties",
    name: "Départements",
    desc: "Gestion des Départements",
    display: true,
    icon: Map,
    component: CountiesHomeView,
    layout: "/admin",
    section : "FicheProduits"
  },
  {
    path: "/new/county",
    name: "Départements",
    desc: "Nouveau Département",
    display: false,
    icon: Map,
    component: NewCounty,
    layout: "/admin",
    section : "",
  },
  {
    path: "/edit/counties/:id",
    name: "Départements",
    desc: "Edition Département",
    icon: Map,
    display: false,
    component: EditCounty,
    layout: "/admin",
    section : ""
  },
  {
    path: "/categories",
    name: "Catégories",
    desc: "Gestion des Catégories",
    display: true,
    icon: Folder,
    component: CategoriesHomeView,
    layout: "/admin",
    section : "FicheProduits"
  },
  {
    path: "/new/categorie",
    name: "Catégories",
    desc: "Nouvelle Catégorie",
    display: false,
    icon: Folder,
    component: NewCategorie,
    layout: "/admin",
    section : ""
  },
  {
    path: "/edit/categories/:id",
    name: "Catégories",
    desc: "Edition Catégorie",
    icon: Folder,
    display: false,
    component: EditCategorie,
    layout: "/admin",
    section : ""
  },
  {
    path: "/users/home",
    name: "Utilisateurs",
    desc: "Gestion des Utilisateurs",
    display: true,
    icon: Person,
    component: UsersView,
    layout: "/admin",
    section : "Parametres"
  },
  {
    path: "/users/edit/:id",
    name: "Utilisateurs",
    desc: "Édition Utilisateur",
    display: false,
    icon: Person,
    component: EditUser,
    layout: "/admin",
    section : ""
  },
  {
    path: "/rx_sociaux",
    name: "Réseaux sociaux",
    desc: "Gestion des Réseaux sociaux",
    display: true,
    icon: SupervisorAccount,
    component: EditRxSociaux,
    layout: "/admin",
    section : "Parametres",
  },
  {
    path: "/meta_seo",
    name: "MetaSEO",
    desc: "Gestion des MetaSEO",
    display: true,
    icon: Dashboard,
    component: EditMetaSEO,
    layout: "/admin",
    section : "Parametres",
  },
  {
    path: "/editgeneral",
    name: "Footer",
    desc: "Gestion du footer",
    display: true,
    icon: VideoLabel,
    component: EditGeneral,
    layout: "/admin",
    section : "Parametres",
  },
  {
    path: "/new/sign",
    name: "Nouveau Signe",
    desc: "Nouveau Signe",
    display: false,
    icon: LocalOffer,
    component: NewSign,
    layout: "/admin",
    section : ""
  },
  {
    path: "/edit/signs/:id",
    name: "Signe",
    desc: "Edition Signe",
    icon: LocalOffer,
    display: false,
    component: EditSign,
    layout: "/admin",
    section : ""
  },
  {
    path: "/odgs",
    name: "ODG",
    desc: "Gestion des ODG",
    display: false,
    icon: ListAlt,
    component: OdgsHomeView,
    layout: "/admin",
    section : ""
  },
  {
    path: "/new/odgs",
    name: "ODG",
    desc: "Nouvel ODG",
    display: false,
    icon: ListAlt,
    component: NewOdg,
    layout: "/admin",
    section : ""
  },
  {
    path: "/edit/odg/:id",
    name: "ODG",
    desc: "Edition Département",
    icon: ListAlt,
    display: false,
    component: EditOdg,
    layout: "/admin",
    section : ""
  },
  {
    path: "/login",
    name: "Se Connecter",
    icon: Person,
    display: false,
    component: Login,
    layout: "/login",
    section : ""
  },
  {
    path: "/users/new",
    name: "Utilisateurs",
    desc: "Nouvel Utilisateur",
    icon: Person,
    display: false,
    component: NewUser,
    layout: "/admin",
    section : ""
  },
  {
    path: "/edit/terroir/:id",
    name: "Terroir",
    desc: "Edition Terroir",
    icon: Fastfood,
    display: false,
    component: EditTerroir,
    layout: "/admin",
    section : ""
  },
  {
    path: "/edit/flavors/:id",
    name: "Saveur",
    desc: "Edition Saveur",
    icon: LocalOffer,
    display: false,
    component: EditFlavors,
    layout: "/admin",
    section : ""
  },
  {
    path: "/edit/certifications/:id",
    name: "Certification",
    desc: "Edition Certification",
    icon: Check,
    display: false,
    component: EditCertification,
    layout: "/admin",
    section : ""
  },
  {
    path: "/edit/descriptions/:id",
    name: "Description",
    desc: "Edition Description Label",
    icon: Forum,
    display: false,
    component: EditDescription,
    layout: "/admin",
    section : ""
  },
  {
    path: "/edit/infos/:id",
    name: "Infos",
    desc: "Edition Infos Label",
    icon: InfoOutlined,
    display: false,
    component: EditInfo,
    layout: "/admin",
    section : ""
  },

  {
    path: "/addresses",
    name: "Producteurs",
    desc: "Gestion des Producteurs",
    icon: AccountBox,
    display: false,
    component: ViewAdresses,
    layout: "/admin",
    section : ""
  },
  {
    path: "/signs/:id/pictures/new",
    name: "Nouvelle Image Signe",
    desc: "Nouvelle Image Signe",
    icon: AccountBox,
    display: false,
    component: SignAddPictureView,
    layout: "/admin",
    section : ""
  },
  {
    path: "/signs/:id/pictures/:id_picture/edit",
    name: "Edition Image Signe",
    desc: "Edition Image Signe",
    icon: AccountBox,
    display: false,
    component: EditSignPictureView,
    layout: "/admin",
    section : ""
  },
  {
    path: "/logout",
    name: "Se déconnecter",
    icon: MeetingRoom,
    display: true,
    component: Logout,
    layout: "/admin",
    section : ""
  },

];

export default dashboardRoutes;
