import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "../../../components/CustomButtons/Button.js";
import { Link } from "react-router-dom";
import categoriesServices from "../../../services/categories/serviceCategories";
import Swal from "sweetalert2";
import SuccessAlert from "../../Alerts/SuccessAlert";
import GridItem from "../../Grid/GridItem.js";
import GridContainer from "../../Grid/GridContainer";

export default function FormNewCategorie(props) {
  const [label, setLabel] = useState("");



  const handleFormSubmit = () => {
    categoriesServices.newCategorie(label).then(res => {
      SuccessAlert({ message: res.data.message });
      props.props.history.push("/admin/categories");
    }).catch(() => {
      Swal.fire("Une erreur est survenue !").then();
    });
  };

  return (
    <>
      <GridContainer
        justify="center"
        alignItems="center"
      >
        <GridItem xs={12}>
          <TextField
            label="Label"
            error={!label}
            style={{ margin: 8 }}
            placeholder="Label"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            onChange={e => {
              setLabel(e.target.value);
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer
        justify="center"
        alignItems="center"
      >
        <GridItem>
          <Button
            color={"success"}
            disabled={!label}
            onClick={handleFormSubmit}
          >
            Ajouter
          </Button>
        </GridItem>
        <GridItem>
          <Link to={"/admin/categories"}>
            <Button
              color={"danger"}
            >
              Annuler
            </Button>
          </Link>
        </GridItem>
      </GridContainer>
    </>
  );
}
