import React, {useEffect, useState} from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import {Icon} from "@material-ui/core";
import CardBody from "../../components/Card/CardBody";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import {makeStyles} from "@material-ui/core/styles";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import Card from "../../components/Card/Card";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "../../components/CustomButtons/Button";
import {DropzoneArea} from "material-ui-dropzone";
import WarningAlert from "../../components/Alerts/WarningAlert";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
import serviceOdgs from "../../services/odgs/serviceOdgs";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import serviceGeneral from "../../services/general/serviceGeneral";
import {createBrowserHistory} from "history";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import serviceMetaSEO from "services/meta_seo/serviceMetaSEO.js";

const styles = {
    ...dashboardStyle,
    cardHeader: {
        color: "#999",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        textAlign: 'left',
        padingBottom: "3px"
    },
};

const useStyles = makeStyles(styles);
const hist = createBrowserHistory();

export default function EditMetaSEO(props) {
// style
    const classes = useStyles();
// form data
    const [id, setId] = useState('');
    const [default_title, setDefaultTitle] = useState('');
    const [default_description, setDefaultDescription] = useState('');
    const [default_image_path, setDefaultImagePath] = useState('');
    const [title_contact, setTitleContact] = useState('');
    const [title_map, setTitleMap] = useState('');
    const [contact_desc, setContactDesc] = useState('');
    const [map_desc, setMapDesc] = useState('');
    const [google_anlytics_tracker_id, setGoogleAnlyticsTrackerId] = useState('');

    useEffect(() => {
        serviceMetaSEO.getMetaSEO().then(res => {
            setId(res.data.data.id);
            setDefaultTitle(res.data.data.default_title);
            setDefaultDescription(res.data.data.default_description);
            setDefaultImagePath(res.data.data.default_image_path);
            setTitleContact(res.data.data.title_contact);
            setTitleMap(res.data.data.title_map);
            setContactDesc(res.data.data.contact_desc);
            setMapDesc(res.data.data.map_desc);
            /*
                    setGoogleAnlyticsTrackerId(res.data.data.google_anlytics_tracker_id);
            */
        });
    }, []);

    const handleSave = async () => {
        await serviceMetaSEO.updateMetaSEO(id, default_title, default_description, default_image_path, title_contact, title_map, contact_desc, map_desc).then(async (res) => {
            SuccessAlert({message: res.data.message});
        }).catch((err) => {
                ErrorAlert({message: 'Une erreur est survenue'});
                console.log(err);
            }
        );
    };

    return (
        <>
            <Button onClick={hist.goBack}> Retour </Button>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={6}>
                    <GridContainer>
                        {/* <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card>
                            <CardHeader color={"rose"} icon>
                                <CardIcon color={"rose"}>
                                    <Icon>
                                        store
                                    </Icon>
                                </CardIcon>
                                <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                    Google Analytics
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <FormControl fullWidth className={classes.formControl}>
                                    <TextField multiline label="Tracker Id" value={google_anlytics_tracker_id}
                                            onChange={(event) => setGoogleAnlyticsTrackerId(event.target.value)}/>
                                    <FormHelperText>*</FormHelperText>
                                </FormControl>
                            </CardBody>
                        </Card>
                    </GridItem>*/}
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color={"rose"} icon>
                                    <CardIcon color={"rose"}>
                                        <Icon>
                                            store
                                        </Icon>
                                    </CardIcon>
                                    <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                        Valeurs par défaut
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField multiline label="Titre" value={default_title}
                                                   onChange={(event) => setDefaultTitle(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField multiline label="Description" value={default_description}
                                                   onChange={(event) => setDefaultDescription(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    {/*<FormControl fullWidth className={classes.formControl}>
                                    <TextField multiline label="Chemin de l'image" value={default_image_path}
                                            onChange={(event) => setDefaultImagePath(event.target.value)}/>
                                    <FormHelperText>*</FormHelperText>
                                </FormControl>*/}
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </GridItem>

                <GridItem xs={12} sm={12} md={12} lg={6}>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color={"rose"} icon>
                                    <CardIcon color={"rose"}>
                                        <Icon>
                                            store
                                        </Icon>
                                    </CardIcon>
                                    <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                        Contact
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField multiline label="Titre" value={title_contact}
                                                   onChange={(event) => setTitleContact(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField multiline label="Description" value={contact_desc}
                                                   onChange={(event) => setContactDesc(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                </CardBody>
                            </Card>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color={"rose"} icon>
                                    <CardIcon color={"rose"}>
                                        <Icon>
                                            store
                                        </Icon>
                                    </CardIcon>
                                    <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                        Carte
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField multiline label="Titre" value={title_map}
                                                   onChange={(event) => setTitleMap(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField multiline label="Description" value={map_desc}
                                                   onChange={(event) => setMapDesc(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                </CardBody>
                            </Card>
                        </GridItem>

                        {/* <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card>
                            <CardHeader color={"info"} icon>
                                <CardIcon color={"info"}>
                                    <Icon>
                                        short_text
                                    </Icon>
                                </CardIcon>
                                <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                    Descriptions
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <FormControl fullWidth className={classes.formControl}>
                                    <TextField multiline label="Contact" value={contact_desc}
                                               onChange={(event) => setContactDesc(event.target.value)}/>
                                    <FormHelperText>*</FormHelperText>
                                </FormControl>
                                <FormControl fullWidth className={classes.formControl}>
                                    <TextField multiline label="Carte" value={map_desc}
                                               onChange={(event) => setMapDesc(event.target.value)}/>
                                    <FormHelperText>*</FormHelperText>
                                </FormControl>
                            </CardBody>
                        </Card>
                    </GridItem>*/}
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color={"info"} icon>
                                    <CardIcon color={"info"}>
                                        <Icon>
                                            info
                                        </Icon>
                                    </CardIcon>
                                    <p className={classes.cardHeader} style={{color: '#4c4c4c'}}><i>
                                        À l'exception des pages Contact et Carte, la gestion des données SEO (référencement) s'opère directement depuis <a href="/admin/pages"><b>les pages dédiées.</b></a></i>
                                    </p>
                                </CardHeader>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </GridItem>



                {/*Submit button*/}
                <GridItem xs={12}>
                    <Button
                        fullWidth
                        color={'info'}
                        onClick={handleSave}
                    >
                        <Icon>check</Icon>&nbsp;Sauvegarder
                    </Button>
                </GridItem>
            </GridContainer>
        </>
    );
}
