import Global from "../global";
import axios from "axios";


class imagesServices extends Global {

    getUrlVisuImage(id) {
        return this.getAPI_URL() + "pictures/" + id + "/content";
    }

    getUrlVisuImageSigns(id) {
        return this.getAPI_URL() + "sign_pictures/" + id + "/content";
    }


    getPictures(id) {
        return axios.get(this.getAPI_URL() + "pictures/" + id + "/infos", this.getAuthHeader());
    }


    updatePictures(products_id, id, credit, file, filename, ranking) {
        let formData = new FormData();

        formData.append('content', file);
        formData.append('alt', filename);
        formData.append('product_id', products_id);
        formData.append('credit', credit);
        formData.append('title', filename);
        formData.append('picture_id', id);
        formData.append('ranking', ranking ? ranking : 0);

       return axios.post(this.getAPI_URL() + "pictures/" + id, formData, this.getAuthHeader());
    }


    updatePicturesNoContent(products_id, id, credit, filename, ranking) {

        let formData = {
            alt: filename,
            product_id: products_id,
            credit: credit,
            title: filename,
            picture_id: id,
            ranking: ranking ? ranking : 0,
        };

        console.log(formData);

        return axios.put(this.getAPI_URL() + "pictures/" + id, formData, this.getAuthHeader());
    }


}

export default new imagesServices();