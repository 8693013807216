import axios from 'axios';
import Global from "../global";


class signsServices extends Global{

    getAllSignsForProduct(id) {
        return axios.get(this.getAPI_URL() + "products/" + id +"/signs" , this.getAuthHeader());
    }

    getAllSignsDescriptions(id) {
        return axios.get(this.getAPI_URL() + `signs/${id}/sign_desc` , this.getAuthHeader());
    }


    getSign(id) {
        return axios.get(this.getAPI_URL() + "signs/" + id , this.getAuthHeader());
    }

    getAllSigns() {
        return axios.get(this.getAPI_URL() + "signs", this.getAuthHeader());
    }

    getKeysNumbersFor(id) {
        return axios.get(this.getAPI_URL() + `signs/${id}/key_numbers`, this.getAuthHeader());
    }


    linkSignProduct(idSign, idProduct) {
        return axios.post(this.getAPI_URL() + "products/" + idProduct + "/signs/" + idSign ,{product_id : idProduct, sign_id: idSign}, this.getAuthHeader());
    }


    unLinkSignProduct(idSign, idProduct) {
        return axios.delete(this.getAPI_URL() + "products/" + idProduct + "/signs/" + idSign , this.getAuthHeader());
    }


    deleteSign(id) {
        return axios.delete(this.getAPI_URL() + "signs/" + id, this.getAuthHeader());
    }

    deleteKeyNumber(id) {
        return axios.delete(this.getAPI_URL() + "sign_key_number/" + id, this.getAuthHeader());
    }

    addKeyNumber(sign_id, key_number, description  ) {

        const params = {
            'key_number' : description,
            'description' : key_number,
            'sign_id' :sign_id
        };

        return axios.post(this.getAPI_URL() + "sign_key_number/new", params , this.getAuthHeader());
    }

    editSign(id, nid, uuid, value, short_form) {
        let params = {
            id: id,
            value: value,
            short_form: short_form,
        };
        return axios.put(this.getAPI_URL() + "signs/" + id, params, this.getAuthHeader());
    }

    editSignDesc(id, value, format, summary, process, sign_id) {
        let params = {
            id: id,
            value: value,
            // format: null,
            // summary: null,
            process: process,
            sign_id: sign_id,
        };
        return axios.put(this.getAPI_URL() + "sign_desc/" + sign_id, params, this.getAuthHeader());
    }


    newSign(label, abreviation) {
        let params = {
            value : label,
            short_form : abreviation,
        };
        return axios.post(this.getAPI_URL() + "signs/new", params, this.getAuthHeader());
    }

    editSignKeyNumber(id, number, desc , sign_id) {
        let params = {
            id: id ,
            key_number: number ,
            description: desc ,
            sign_id: sign_id ,
        };
        return axios.put(this.getAPI_URL() + "sign_key_number/" + id, params, this.getAuthHeader());
    }

    getAllImagesFromSign(id) {
        return axios.get(this.getAPI_URL() + `signs/${id}/pictures` , this.getAuthHeader());
    }

    getPiucture(id) {
        return axios.get(this.getAPI_URL() + `sign_pictures/${id}/infos` , this.getAuthHeader());
    }

    updatePictureWithoutContent(sign_id, id, title , alt) {
        let params = {
            title: title ,
            alt: alt ,
            id: parseInt(id) ,
            sign_id: parseInt(sign_id) ,
        };

        return axios.put(this.getAPI_URL() + `sign_pictures/${id}` , params, this.getAuthHeader());
    }

    updatePictureWitContent(sign_id, id, title, alt, file) {

        let formData = new FormData();

        formData.append('content', file);
        formData.append('title', title);
        formData.append('alt', alt);
        formData.append('id', parseInt(id));
        formData.append('sign_id', parseInt(sign_id));

        return axios.post(this.getAPI_URL() + `sign_pictures/${id}` , formData, this.getAuthHearderFormMultipart());
    }
    getPiuctureVisuUrl(id) {
        return this.getAPI_URL() + `sign_pictures/${id}/content`;
    }

    deleteImage(id) {
        return axios.delete(this.getAPI_URL() + `sign_pictures/${id}`, this.getAuthHeader());
    }

    uploadPicture(filename, file, id) {
        let formData = new FormData();

        formData.append('content', file);
        formData.append('title', filename);
        formData.append('alt', filename);
        formData.append('sign_id', id);

        return axios.post(this.getAPI_URL() + "signs/" + id + "/pictures/upload", formData, this.getAuthHearderFormMultipart());
    }
}

export default new signsServices();