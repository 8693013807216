import React, { useEffect, useState } from "react";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";

import TableImagesList from "../../Table/Images/TableImagesList";
import {Link} from "react-router-dom";
import Button from "../../CustomButtons/Button";
import products from "../../../services/products/products";

export default function FormUploadImages({id}) {
    const [images, setImages] = useState([]);

    useEffect(() => {

        products.getAllImagesFromProduct(id).then(res => {
            setImages(res.data.data)});

    }, [id]);

    return (
        <GridContainer

        >
            <GridItem>
                { images.length < 4 ?
                    <Link to={'/admin/products/' + id + '/pictures/new'}>
                        <Button
                          color={'success'}
                        >
                            Ajouter une nouvelle Image
                        </Button>
                    </Link>
                  :
                  null
                }
            </GridItem>
            <GridItem>
                <TableImagesList Product={id}/>
            </GridItem>
        </GridContainer>
    )
}


