import React, {useEffect, useState} from "react";
import servicePages from "../../services/pages/servicePages";
import Button from "../../components/CustomButtons/Button";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import {Icon, makeStyles} from "@material-ui/core";
import {Link} from 'react-router-dom';
import MaterialTable from "material-table";
import Tooltip from "@material-ui/core/Tooltip";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import Swal from "sweetalert2";
import Paper from "@material-ui/core/Paper";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import {Assignment} from "@material-ui/icons";
import CardIcon from "../../components/Card/CardIcon";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import CardBody from "../../components/Card/CardBody";

const styles = {
  ...dashboardStyle,
  cardTitle: {
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    color: "#999"
  }
};

const useStyles = makeStyles(styles);

export default function PagesHome() {
  const [pages, setPages] = useState([]);
  const classes = useStyles();
  const columns = [

    {
      title: 'Nom',
      field: 'title',
      render: (row) => row.title.replace(/['"'{'}]+/g, ''),
      defaultSort: "asc"
    },
    {
      title: 'Actions',
      field: 'id',
      export: false,
      render: (row) => {
        return (
            <>
              {/* <Tooltip
              title="Visualiser"
            >
              <Link to={'/admin/show/' + row.id + '/page'}>
                <Button justIcon={true} round={true} color={'info'}>
                  <Icon>
                    remove_red_eye
                  </Icon>
                </Button>
              </Link>
            </Tooltip> */}
              <Tooltip
                  title="Éditer"
              >
                <Link to={row.title === 'Accueil' ? '/admin/accueil' : '/admin/edit/pages/' + row.id + (row.id === 4 || row.id === 3 ? '/pagecarrousel' : " ")}>

                  <Button justIcon={true} round={true} color={'primary'}>
                    <Icon>
                      edit
                    </Icon>
                  </Button>
                </Link>
              </Tooltip>
              {/* <Tooltip
              title="Supprimer"
            >
               <span>
                   <Button
                     justIcon={true}
                     round={true}
                     onClick={() => deleteAction(row.id)}
                     color={'danger'}
                   >
                       <Icon>
                           delete
                       </Icon>
                   </Button>
               </span>
            </Tooltip> */}
            </>
        )
      },
      headerStyle: {textAlign: 'right'},
      cellStyle: {textAlign: 'right'},
    },
  ];
//   const deleteAction = (id) => {
//     Swal.fire({
//       title: 'Voulez-vous vraiment supprimer ce produit?',
//       text: "Cette action est irreversible !",
//       icon: 'warning',
//       showCancelButton: true,
//       confirmButtonColor: '#3085d6',
//       cancelButtonColor: '#d33',
//       confirmButtonText: 'Supprimer!',
//       cancelButtonText: 'Annuler',
//     }).then((result) => {
//       if (result.value) {
//         servicePages.deleteProduct(id).then(() => {
//           SuccessAlert({message: 'Produit supprimé avec succès !'});
//           servicePages.getAllProductsForTable().then(res => {
//             setProducts(
//               res.data.data
//             );
//           }).catch(() => {
//             setProducts(
//               []
//             );
//           });
//         }).catch(() => {
//           Swal.fire('Une erreur est survenue !').then();
//         })
//       }
//     })
//   };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {

    servicePages.getAllPages().then(res => {

      setPages([{title:'Accueil', id: 0},...res.data.data]);
    }).catch(() => {
      /*const fetchData = {"data":[{"title":"Mentions l\u00e9galessss","id":2},{"title":"Tous les produitss","id":3},{"title":"Qui sommes-nous ?","id":1},{"title":"LES SIGNES OFFICIELS","id":4}],"message":"SUCCESS","status":"SUCCESS"};
      console.log(fetchData);*/

      setPages([]);
    });
  };


  return (
      <>
        <GridContainer
            justify={'flex-end'}
        >
          {/* <GridItem xs={12} md={4} lg={4}>
              <Link to={'/admin/products/new'}>
                  <Button type={"button"} color={"success"}>
                      <Icon>add</Icon>
                      Ajouter un produit
                  </Button>
              </Link>
          </GridItem> */}
          <GridItem xs={12} md={12} lg={12}>
            <Card>
              <CardHeader
                  icon
                  color={'primary'}
              >
                <CardIcon color="primary">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardTitle}>Liste des pages</h4>
              </CardHeader>
              <CardBody>
                <MaterialTable
                    components={{
                      Container: props => <Paper {...props} elevation={0}/>
                    }}
                    title={''}
                    localization={{
                      toolbar: {
                        searchPlaceholder: "Rechercher",
                        searchTooltip: "Rechercher",
                      },
                    }}
                    showTitle={' '}
                    columns={columns}
                    data={pages}
                    options={
                      {
                        pageSize: 5,
                        pageSizeOptions: [5, 10, 20, 50],
                        sorting: true,
                        searchPlaceholder: 'Rechercher'
                      }
                    }
                />
              </CardBody>

            </Card>
          </GridItem>
        </GridContainer>
      </>
  );
}
