import axios from 'axios';
import Global from "../global";

class productsService extends Global {


    sendProductToFrontOffice(id) {  // only use if product is valid
        return axios.get(this.getAPI_URL() + "products/" + id + "/send_new", this.getAuthHeader());
    }

    isProductValid(id) {
        return axios.get(this.getAPI_URL() + "products/" + id + "/valid", this.getAuthHeader());
    }

    setProductStatus(id, status) {
        return axios.put(this.getAPI_URL() + "products/" + id + "/status", {
            id: id,
            status: status
        }, this.getAuthHeader());
    }


    getProduct(id) {
        return axios.get(this.getAPI_URL() + "products/" + id, this.getAuthHeader());
    }


    newProduct(name, tel, url, geo) {
        let temp = Math.floor(Date.now() / 1000);
        let params = {
            label: name,
            tel: tel,
            site_url: url,
            id_siqo: `${temp}`,
            geojson_web: geo,
            uuid: `${temp}`,
            value: name,
            nid: temp,
            status: "PENDING"
        };

        return axios.post(this.getAPI_URL() + "products/new", params, this.getAuthHeader());
    }


    editProduct(id, value, id_siqo, geojson_web, uuid, nid, status) {

        let params = {
            id_siqo: `${id_siqo}`,
            geojson_web: geojson_web,
            uuid: uuid,
            value: value,
            nid: nid,
            id: id,
            status: status
        };

        return axios.put(this.getAPI_URL() + "products/" + id, params, this.getAuthHeader());

    }


    getAllProducts() {
        return axios.get(this.getAPI_URL() + "products", this.getAuthHeader());
    }

    getAllProductsForTable() {
        return axios.get(this.getAPI_URL() + "products/categories/signs", this.getAuthHeader());
    }

    getAllTerroirFromProduct(id) {

        return axios.get(this.getAPI_URL() + "products/" + id + "/terroirs", this.getAuthHeader());
    }


    getAllFlavorsFromProduct(id) {

        return axios.get(this.getAPI_URL() + "products/" + id + "/flavors", this.getAuthHeader());
    }


    getAllCertificationsFromProduct(id) {
        return axios.get(this.getAPI_URL() + "products/" + id + "/certifications", this.getAuthHeader());
    }

    getAllDescriptionsFromProduct(id) {

        return axios.get(this.getAPI_URL() + "products/" + id + "/descriptions", this.getAuthHeader());
    }


    getAllImagesFromProduct(id) {

        return axios.get(this.getAPI_URL() + "products/" + id + "/pictures", this.getAuthHeader());
    }


    getAllInfosFromProduct(id) {
        return axios.get(this.getAPI_URL() + "products/" + id + "/infos", this.getAuthHeader());
    }


    uploadPicture(filename, file, idProduct) {
        let formData = new FormData();

        formData.append('content', file);
        formData.append('title', filename);
        formData.append('alt', filename);
        formData.append('product_id', idProduct);
        formData.append('credit', "credit");

        return axios.post(this.getAPI_URL() + "products/" + idProduct + "/pictures/upload", formData, this.getAuthHearderFormMultipart());
    }


    addTerroir(idProduct, desc) {
        let params = {
            value: desc,
            type: 'null',
            process: desc,
            product_id: idProduct
        };

        return axios.post(this.getAPI_URL() + "products/" + idProduct + "/terroirs/new", params, this.getAuthHeader());
    }


    addFlavor(idProduct, desc) {
        let params = {
            value: desc,
            type: 'null',
            process: desc,
            product_id: idProduct
        };

        return axios.post(this.getAPI_URL() + "products/" + idProduct + "/flavors/new", params, this.getAuthHeader());
    }


    addCertification(idProduct, desc) {
        let params = {
            value: desc,
            type: 'null',
            process: desc,
            product_id: idProduct
        };

        return axios.post(this.getAPI_URL() + "products/" + idProduct + "/certifications/new", params, this.getAuthHeader());
    }


    addDescription(idProduct, desc) {
        let params = {
            value: desc,
            summary: desc, // TODO sumary
            type: 'null',
            process: desc,
            product_id: idProduct
        };

        return axios.post(this.getAPI_URL() + "products/" + idProduct + "/descriptions/new", params, this.getAuthHeader());
    }


    addInfo(idProduct, desc) {
        let params = {
            value: desc,
            type: 'null',
            process: desc,
            product_id: idProduct
        };

        return axios.post(this.getAPI_URL() + "products/" + idProduct + "/infos/new", params, this.getAuthHeader());
    }

    addCounty(idProduct, idCounty) {
        return axios.post(this.getAPI_URL() + "products/" + idProduct + "/counties/" + idCounty, undefined, this.getAuthHeader());
    }

    updateTerroir(id, idProduct, desc) {
        let params = {
            value: desc,
            type: 'null',
            process: desc,
            product_id: idProduct,
            id: id,
        };
        return axios.put(this.getAPI_URL() + "terroirs/" + id, params, this.getAuthHeader());
    }


    updateFlavor(id, idProduct, desc) {
        let params = {
            value: desc,
            type: 'null',
            process: desc,
            product_id: idProduct,
            id: `${id}`,
        };
        return axios.put(this.getAPI_URL() + "flavors/" + id, params, this.getAuthHeader());
    }


    updateCertification(id, idProduct, desc) {
        let params = {
            value : desc,
            type: 'null',
            process: desc,
            product_id: idProduct,
            id: id,
        };
        return axios.put(this.getAPI_URL() + "certifications/" + id, params, this.getAuthHeader());
    }


    updateDescription(id, idProduct, desc) {
        let params = {
            value: desc,
            type: 'null',
            process: desc,
            summary: desc,
            product_id: idProduct,
            id: id,
        };
        return axios.put(this.getAPI_URL() + "descriptions/" + id, params, this.getAuthHeader());
    }


    updateInfo(id, idProduct, desc) {
        let params = {
            value: desc,
            type: 'null',
            process: desc,
            product_id: idProduct,
            id: id,
        };
        return axios.put(this.getAPI_URL() + "infos/" + id, params, this.getAuthHeader());
    }


    deleteTerroir(idTerroir) {
        return axios.delete(this.getAPI_URL() + "terroirs/" + idTerroir, this.getAuthHeader());
    }

    deleteFlavors(idFlavor) {
        return axios.delete(this.getAPI_URL() + "flavors/" + idFlavor, this.getAuthHeader());
    }


    deleteCertification(id) {
        return axios.delete(this.getAPI_URL() + "certifications/" + id, this.getAuthHeader());
    }


    deleteDescription(id) {
        return axios.delete(this.getAPI_URL() + "descriptions/" + id, this.getAuthHeader());
    }


    deleteInfo(id) {
        return axios.delete(this.getAPI_URL() + "infos/" + id, this.getAuthHeader());
    }


    deleteProduct(id) {
        return axios.delete(this.getAPI_URL() + "products/" + id, this.getAuthHeader());
    }


    deleteImage(idImage) {
        return axios.delete(this.getAPI_URL() + "pictures/" + idImage, this.getAuthHeader());
    }

    deleteCounty(idProduct, idCounty) {
        return axios.delete(this.getAPI_URL() + "products/" + idProduct + "/counties/" + idCounty, this.getAuthHeader());
    }

    getTerroir(id) {
        return axios.get(this.getAPI_URL() + "terroirs/" + id, this.getAuthHeader());
    }


    getFlavor(id) {
        return axios.get(this.getAPI_URL() + "flavors/" + id, this.getAuthHeader());
    }


    getCertification(id) {
        return axios.get(this.getAPI_URL() + "certifications/" + id, this.getAuthHeader());
    }


    getDescription(id) {
        return axios.get(this.getAPI_URL() + "descriptions/" + id, this.getAuthHeader());
    }


    getInfo(id) {
        return axios.get(this.getAPI_URL() + "infos/" + id, this.getAuthHeader());
    }


    getProductMill(id) {
        return axios.get(this.getAPI_URL() + "products/" + id + "/mill", this.getAuthHeader());
    }

    sendProduct(id) {
        return axios.get(this.getAPI_URL() + "products/" + id + "/send_update", this.getAuthHeader());
    }

    createProductSEO(id, title, description, keywords) {
        let params = {
            product_id: id,
            title: title,
            description: description,
            keywords: keywords,
        };
        return axios.post(this.getAPI_URL() + "product_seo", params, this.getAuthHeader());
    }

    getProductSEO(id) {
        return axios.get(this.getAPI_URL() + "product_seo/" + id, this.getAuthHeader());
    }

    updateProductSEO(id, title, description) {
    let params = {
            product_id: id,
            title: title,
            description: description
        };
        return axios.put(this.getAPI_URL() + "product_seo", params, this.getAuthHeader());
    }

}

export default new productsService();