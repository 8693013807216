import axios from 'axios';
import Global from "../global";

class userService extends Global{

    getAll(){
        return axios.get(this.getAPI_URL() + "users", this.getAuthHeader());
    }

    getOthersUsers() {
        return axios.get(this.getAPI_URL() + "users/others", this.getAuthHeader());
    }

    /**
     *
     * @param name
     * @param email
     * @param password
     * @param c_password
     * @returns {Promise<AxiosResponse<T>>}
     */
    newUser(name, email, password, c_password) {
        let params = {
            name : name,
            email : email,
            password : password,
            password_confirmation : c_password
        };

        return axios.post(this.getAPI_URL() + "users/register", params, this.getAuthHeader());
    }

    /**
     *
     * @param id
     * @returns {Promise<AxiosResponse<T>>}
     */
    deleteUser(id) {
        return axios.delete(this.getAPI_URL() + "users/" + id, this.getAuthHeader());
    }

    /**
     *
     * @param name
     * @param email
     * @returns {Promise<AxiosResponse<T>>}
     */
    updateUserWithoutPassword(name, email) {
        let params = {
            name : name,
            email : email
        };
        return axios.put(this.getAPI_URL() + "users/me", params, this.getAuthHeader());
    }

    /**
     *
     * @param name
     * @param email
     * @param password
     * @returns {Promise<AxiosResponse<T>>}
     */
    updateUserWithPassword(name, email, password) {
        let params = {
            name : name,
            email : email,
            password: password
        };
        return axios.put(this.getAPI_URL() + "users/me", params, this.getAuthHeader());
    }
}

export default new userService();