/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a href="https://www.facebook.com/nosproduitsdequalite"
                 target="_blank"
                 className={classes.block}
              >
                FaceBook
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="https://www.instagram.com/nosproduitsdequalite/"
                 target="_blank"
                 className={classes.block}
              >
                Instagram
              </a>
            </ListItem>
          </List>
        </div>
        <p className={classes.right}>
          <span>
            &copy; {1900 + new Date().getYear()}{" "}
            <a
              href="https://www.nosproduitsdequalite.fr/"
              target="_blank"
              className={classes.a}
            >
              Nos Produits de qualité
            </a>
          </span>
        </p>
      </div>
    </footer>
  );
}
