import React, {useEffect, useState} from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import {Icon} from "@material-ui/core";
import Card from "../../components/Card/Card";
import CardTitleIconPlusTextRight from "../../components/CardHeader/CardTitleIconPlusTextRight";
import {Link} from "react-router-dom";
import MaterialTable from "material-table";
import serviceSigns from "../../services/signs/signs";
import Button from "../../components/CustomButtons/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Swal from "sweetalert2";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import Paper from "@material-ui/core/Paper";
import ErrorAlert from "../../components/Alerts/ErrorAlert";




export default function SignsHomeView() {
    const [signs, setSigns] = useState([{}]);
    const columns = [
        {
            title: "Abréviation",
            field: "short_form"
        },
        {
            title: "Nom",
            field: "value"
        },
        {
            title: "Actions",
            field: "id",
            render: (row) =>  <>
                <Tooltip
                  title="Éditer"
                >
                    <Link to={'/admin/edit/signs/' + row.id}>
                        <Button justIcon={true} round={true} color={'primary'}>
                            <Icon>
                                edit
                            </Icon>
                        </Button>
                    </Link>
                </Tooltip>
                <Tooltip
                  title="Supprimer"
                >
                            <span>
                                <Button
                                  justIcon={true}
                                  round={true}
                                  onClick={() => to_delete(row.id)}
                                  color={'danger'}
                                >
                                    <Icon>
                                        delete
                                    </Icon>
                                </Button>
                            </span>
                </Tooltip>
            </>,
            headerStyle: {textAlign: 'right'},
            cellStyle: {textAlign: 'right'}
        }
    ];
    useEffect(() => {
        serviceSigns.getAllSigns().then(res => {
            setSigns(res.data.data);
        })
    }, []);


    const to_delete = (id) => {
        Swal.fire({
            title: 'Voulez-vous vraiment suprimer ce signe ?',
            text: "Cette action est irreversible et peut impacter des produits !",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Suprimer!',
            cancelButtonText: 'Annuler',
        }).then((result) => {
            if (result.value) {
              serviceSigns.deleteSign(id).then(() => {
                    SuccessAlert({message: 'Signe supprimé avec succès !'});
                serviceSigns.getAllSigns().then(res => {
                        setSigns(res.data.data);
                    }).catch(() => {
                        ErrorAlert({message: 'Une erreur est survenue lors de la supression du sign'});
                    });
                }).catch(() => {
                    Swal.fire('Une erreur est survenue !').then();
                })
            }
        })
    };


    return (
        <>
            <GridContainer
                justify={'flex-end'}
            >
                <GridItem xs={4} md={4}>
                    <Link to={'/admin/new/sign'}>
                        <Button type={"button"} color={"success"}>
                            <Icon>add</Icon>
                            Ajouter un signe
                        </Button>
                    </Link>
                </GridItem>
                <GridItem xs={12} md={12} lg={12}>
                    <Card>
                        <CardTitleIconPlusTextRight
                            color={'primary'}
                            icon={'assignment'}
                            title={'Liste des signes'}
                        />
                        <MaterialTable
                            components={{
                                Container: props => <Paper {...props} elevation={0}/>
                            }}
                            title={' '}
                            localization={{
                                toolbar: {
                                    searchPlaceholder: "Rechercher",
                                    searchTooltip: "Rechercher",
                                },
                            }}

                            showTitle={' '}
                            columns={columns}
                            data={signs}
                            options={
                                {
                                    sorting: true,
                                    searchPlaceholder: 'Rechercher'
                                }
                            }
                        />

                    </Card>
                </GridItem>
            </GridContainer>
        </>
    );
}
