import React from "react";

import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card";
import CardTitleIconPlusTextRight from "../../components/CardHeader/CardTitleIconPlusTextRight";
import CardBody from "../../components/Card/CardBody";
import FormEditSign from "../../components/Forms/Signs/FormEditSign";
import FormEditSignPictures from "../../components/Forms/Signs/FormEditSignPictures";
import FormEditSignKeyNumber from "../../components/Forms/Signs/FormEditSignKeyNumber";
import Button from "../../components/CustomButtons/Button";
import {createBrowserHistory} from "history";

export default function EditSign(props) {

    const id = props.match.params.id;
    const hist = createBrowserHistory();

    return (
        <>
            <Button onClick={hist.goBack}> Retour </Button>
            <GridContainer>
                <GridItem xs={12} md={6} lg={8}>
                    <GridContainer>
                        <GridItem xs={12} md={12}>
                            <Card>
                                <CardTitleIconPlusTextRight
                                    color={"rose"}
                                    icon={"local_offer"}
                                    title={"Édition du Signe"}
                                />
                                <CardBody>
                                    <FormEditSign id={id}/>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12} md={6} lg={4}>
                    <GridContainer>
                        <GridItem xs={12} md={12}>
                            <Card>
                                <CardTitleIconPlusTextRight
                                    color={"success"}
                                    icon={"add_photo_alternate"}
                                    title={"Logo du signe"}
                                />
                                <CardBody>
                                    <FormEditSignPictures id={id}/>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} md={12}>
                            <Card>
                                <CardTitleIconPlusTextRight
                                    color={"warning"}
                                    icon={"vpn_key"}
                                    title={"Chiffres clés"}
                                />
                                <CardBody>
                                    <FormEditSignKeyNumber id={id}/>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        </>
    );

}