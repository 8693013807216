import React from "react";

import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card";
import CardTitleIconPlusTextRight from "../../components/CardHeader/CardTitleIconPlusTextRight";
import CardBody from "../../components/Card/CardBody";
import FormEditProduct from "../../components/Forms/Products/FormEditProduct";
import FormEditSigns from "../../components/Forms/Products/FormEditSigns";
import FormUploadImages from "../../components/Forms/Images/FormUploadImages";
import FormEditGeoJson from "../../components/Forms/Products/FormEditGeoJson";

import FormEditDescsProduct from "../../components/Forms/Products/FormEditDescsProduct";
import FormEditAdressProduct from "../../components/Forms/Products/FormEditAdressProduct";
import FormEditCounties from "../../components/Forms/Products/FormEditCounties";
import FormEditProductSEO from "../../components/Forms/Products/FormEditProductSEO";

import {createBrowserHistory} from "history";
import Button from "../../components/CustomButtons/Button";
import { Link } from "react-router-dom";
import { Icon } from "@material-ui/core";
import products from "../../services/products/products";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import Swal from "sweetalert2";


const hist = createBrowserHistory();


export default function EditProduit(params) {
    const id = params.match.params.id;

    const handleFormSubmit = () => {

        products.sendProduct(id).then(res => {
            SuccessAlert({message: res.data.message});
        }).catch(() => {
            Swal.fire('Une erreur est survenue !').then();
        });

    };

    return (
        <>
            <Button onClick={hist.goBack}> Retour </Button>
            <GridContainer>
                <GridItem
                    xs={12}
                    md={6}
                    lg={6}
                >
                    {/*General ,Descriptions  , addresses*/}
                    <GridContainer>
                        {/* General */}
                        <GridItem
                            xs={12}
                        >
                            <Card>
                                <CardTitleIconPlusTextRight
                                    color={'rose'}
                                    icon={'assignment'}
                                    title={'Présentation générale'}
                                />
                                <CardBody>
                                    <FormEditProduct id={id}/>
                                </CardBody>
                            </Card>
                        </GridItem>

                        <GridItem
                            xs={12}
                        >
                            <Card>
                                <CardTitleIconPlusTextRight
                                    color={'rose'}
                                    icon={'assignment'}
                                    title={'Tags SEO'}
                                />
                                <CardBody>
                                    <FormEditProductSEO id={id}/>
                                </CardBody>
                            </Card>
                        </GridItem>

                        {/* Descriptions  */}
                        <GridItem
                            xs={12}
                        >
                            <Card>
                                <CardTitleIconPlusTextRight
                                    color={'info'}
                                    icon={'short_text'}
                                    title={'Rubriques'}
                                />
                                <CardBody>
                                    <FormEditDescsProduct idProduct={id}/>
                                </CardBody>
                            </Card>
                        </GridItem>
                        {/* Adresse  */}
                        <GridItem
                            xs={12}
                        >
                            <Card>
                                <CardTitleIconPlusTextRight
                                    color={'rose'}
                                    icon={'account_box'}
                                    title={"Adresse de l'ODG"}
                                />
                                <CardBody>
                                    <FormEditAdressProduct idProduct={id}/>
                                </CardBody>
                            </Card>
                        </GridItem>

                    </GridContainer>

                </GridItem>
                {/*Signs, ODG , photos , geojson */}
                <GridItem
                    xs={12}
                    md={6}
                    lg={6}
                >
                    {/*Signs */}
                    <GridItem
                        xs={12}
                    >
                        <Card>
                            <CardTitleIconPlusTextRight
                                color={'primary'}
                                icon={'local_offer'}
                                title={'Choix des Signes'}
                            />
                            <CardBody>
                                <FormEditSigns id={id}/>
                            </CardBody>
                        </Card>
                    </GridItem>
                    {/*ODGs
                    <GridItem
                        xs={12}
                    >
                        <Card>
                            <CardTitleIconPlusTextRight
                                color={'warning'}
                                icon={'school'}
                                title={'Choix ODG'}
                            />
                            <CardBody>
                                <FormEditODGS id={id}/>
                            </CardBody>
                        </Card>
                    </GridItem>*/}

                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card>
                            <CardTitleIconPlusTextRight
                                color={'warning'}
                                icon={'map'}
                                title={'Choix du département'}
                            />
                            <CardBody>
                                <FormEditCounties id={id}/>
                            </CardBody>
                        </Card>
                    </GridItem>
                    {/*Photos */}
                    <GridItem
                        xs={12}
                    >
                        <Card>
                            <CardTitleIconPlusTextRight
                                color={'success'}
                                icon={'add_photo_alternate'}
                                title={'Coin photos'}
                            />
                            <CardBody>
                                <FormUploadImages id={id}/>
                            </CardBody>
                        </Card>
                    </GridItem>
                    {/* Geo json */}
                    <GridItem
                        xs={12}
                    >
                        <Card>
                            <CardTitleIconPlusTextRight
                                color={'primary'}
                                icon={'location_on'}
                                title={'Géolocalisation du produit'}
                            />
                            <CardBody>
                                <FormEditGeoJson idProduct={id}/>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridItem>
            </GridContainer>
        </>
    )
}


