import React, {useEffect, useState} from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import {Icon} from "@material-ui/core";
import CardBody from "../../components/Card/CardBody";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import {makeStyles} from "@material-ui/core/styles";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import Card from "../../components/Card/Card";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "../../components/CustomButtons/Button";
import {DropzoneArea} from "material-ui-dropzone";
import WarningAlert from "../../components/Alerts/WarningAlert";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
import serviceOdgs from "../../services/odgs/serviceOdgs";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import serviceGeneral from "../../services/general/serviceGeneral";
import {createBrowserHistory} from "history";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const styles = {
    ...dashboardStyle,
    cardHeader: {
        color: "#999",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        textAlign: 'left',
        padingBottom: "3px"
    },
};

const useStyles = makeStyles(styles);
const hist = createBrowserHistory();

export default function EditGeneral(props) {
    // style
    const classes = useStyles();
    // form data
    const [id, setId] = useState('');
    const [instagram_path, setInstagramPath] = useState('');
    const [facebook_page, setFacebookPage] = useState('');
    const [footer_address_path, setFooterAddressPath] = useState('');
    const [footer_phone_path, setFooterPhonePath] = useState('');
    const [footer_logo_five_signs_path, setFooterLogoFiveSignsPath] = useState('');
    const [footer_logo, setFooterLogo] = useState('');
    const [footer_mentions_path, setFooterMentionsPath] = useState('');
    const [menu_accueil, setMenuAccueil] = useState('');
    const [menu_tous_les_produits, setMenuTousLesProduits] = useState('');
    const [menu_carte, setMenuCarte] = useState('');
    const [menu_signes_officiels, setMenuSignesOfficiels] = useState('');
    const [menu_about, setMenuAbout] = useState('');
    const [menu_contact, setMenuContact] = useState('');
    const [google_anlytics_tracker_id, setGoogleAnlyticsTrackerId] = useState('');
    const [content_allow_signs, setContentAllowSigns] = useState('');
    const [content_allow_categories, setContentAllowCategories] = useState('');

    useEffect(() => {
        serviceGeneral.getGeneral().then(res => {
            setId(res.data.data.id);
            setInstagramPath(res.data.data.instagram_path);
            setFacebookPage(res.data.data.facebook_page);
            setFooterAddressPath(res.data.data.footer_address_path);
            setFooterPhonePath(res.data.data.footer_phone_path);
            setFooterLogoFiveSignsPath(res.data.data.footer_logo_five_signs_path);
            setFooterLogo(res.data.data.footer_logo);
            setFooterMentionsPath(res.data.data.footer_mentions_path);
            setMenuAccueil(res.data.data.menu_accueil);
            setMenuTousLesProduits(res.data.data.menu_tous_les_produits);
            setMenuCarte(res.data.data.menu_carte);
            setMenuSignesOfficiels(res.data.data.menu_signes_officiels);
            setMenuAbout(res.data.data.menu_about);
            setMenuContact(res.data.data.menu_contact);
            setGoogleAnlyticsTrackerId(res.data.data.google_anlytics_tracker_id);
            setContentAllowCategories(res.data.data.content_allow_categories);
            setContentAllowSigns(res.data.data.content_allow_signs);
        });
    }, []);

    const isFormValid = () => {
        if (!instagram_path) {
            WarningAlert({
                message: "Le compte Instagram doit être renseigné",
            });
            return false;
        }
        if (!facebook_page) {
            WarningAlert({
                message: "Le compte Facebook doit être renseigné",
            });
            return false;
        }
        if (!footer_address_path) {
            WarningAlert({
                message: "L'adresse doit être renseignée",
            });
            return false;
        }
        if (!footer_phone_path) {
            WarningAlert({
                message: "Le numéro de téléphone doit être renseigné",
            });
            return false;
        }
        if (!footer_logo_five_signs_path) {
            WarningAlert({
                message: "Le chemin de l'image doit être renseigné",
            });
            return false;
        }
        if (!footer_mentions_path) {
            WarningAlert({
                message: "Le chemin des mentions doit être renseigné",
            });
            return false;
        }
        if (!menu_accueil) {
            WarningAlert({
                message: "Le nom du menu 'Accueil' doit être renseigné",
            });
            return false;
        }
        if (!menu_carte) {
            WarningAlert({
                message: "Le nom du menu 'Carte' doit être renseigné",
            });
            return false;
        }
        if (!menu_signes_officiels) {
            WarningAlert({
                message: "Le nom du menu '5 Signes officiels' doit être renseigné",
            });
            return false;
        }
        if (!menu_tous_les_produits) {
            WarningAlert({
                message: "Le nom du menu 'Tous les produits' doit être renseigné",
            });
            return false;
        }
        if (!menu_contact) {
            WarningAlert({
                message: "Le nom du menu 'Contact' doit être renseigné",
            });
            return false;
        }
        return true;
    };

    const handleSave = async () => {
        // valid
        if (isFormValid()) {
            await serviceGeneral.updateGeneral(id, instagram_path, facebook_page, footer_address_path, footer_phone_path, footer_logo_five_signs_path, footer_mentions_path, menu_accueil, menu_tous_les_produits, menu_carte, menu_signes_officiels, menu_about, menu_contact, content_allow_signs, content_allow_categories, footer_logo).then(async (res) => {
                SuccessAlert({message: "Informations modifiées avec succès"});
            }).catch((err) => {
                    ErrorAlert({message: 'Une erreur est survenue'});
                    console.log(err);
                }
            );
        }
    };

    return (
        <>
            <Button onClick={hist.goBack}> Retour </Button>
            <GridContainer>
                {/* <GridItem xs={12} sm={12} md={12} lg={6}>
                    <GridContainer>
                        Réseaux Sociaux
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color={"rose"} icon>
                                    <CardIcon color={"rose"}>
                                        <Icon>
                                            store
                                        </Icon>
                                    </CardIcon>
                                    <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                        Réseaux Sociaux
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField multiline label="Instagram" value={instagram_path}
                                                   onChange={(event) => setInstagramPath(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField multiline label="Facebook" value={facebook_page}
                                                   onChange={(event) => setFacebookPage(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                </CardBody>
                            </Card>
                        </GridItem>
                        Menu
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color={"info"} icon>
                                    <CardIcon color={"info"}>
                                        <Icon>
                                            short_text
                                        </Icon>
                                    </CardIcon>
                                    <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                        Menu Navigation
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField multiline label="Accueil" value={menu_accueil}
                                                   onChange={(event) => setMenuAccueil(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField multiline label="Tous les produits" value={menu_tous_les_produits}
                                                   onChange={(event) => setMenuTousLesProduits(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField multiline label="Carte" value={menu_carte}
                                                   onChange={(event) => setMenuCarte(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField multiline label="5 signes officiel" value={menu_signes_officiels}
                                                   onChange={(event) => setMenuSignesOfficiels(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField multiline label="À propos" value={menu_about}
                                                   onChange={(event) => setMenuAbout(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField multiline label="Contact" value={menu_contact}
                                                   onChange={(event) => setMenuContact(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </GridItem>*/}
                <GridItem xs={12} sm={12} md={12} lg={6}>
                    <GridContainer>
                        {/*Footer*/}
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color={"info"} icon>
                                    <CardIcon color={"info"}>
                                        <Icon>
                                            short_text
                                        </Icon>
                                    </CardIcon>
                                    <h5 className={classes.cardHeader} style={{color: '#999'}}>
                                        Coordonnées
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <label style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                            Adresse
                                        </label>
                                        <CKEditor
                                            data={footer_address_path}
                                            editor={ClassicEditor}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setFooterAddressPath(data);
                                            }}
                                            onBlur={(event, editor) => {
                                                setFooterAddressPath(editor.getData());
                                            }}
                                            onFocus={(event, editor) => {
                                                setFooterAddressPath(editor.getData());
                                            }}
                                        />
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField multiline label="Téléphone" value={footer_phone_path}
                                                   onChange={(event) => setFooterPhonePath(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    {/*<FormControl fullWidth className={classes.formControl}>
                                    <TextField multiline label="Chemin du logo NOS" value={footer_logo}
                                                   onChange={(event) => setFooterLogo(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                    <TextField multiline label="Chemin du logo 5 signes" value={footer_logo_five_signs_path}
                                                   onChange={(event) => setFooterLogoFiveSignsPath(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl>*/}
                                    {/* <FormControl fullWidth className={classes.formControl}>
                                    <TextField multiline label="Chemin" value={footer_mentions_path}
                                                   onChange={(event) => setFooterMentionsPath(event.target.value)}/>
                                        <FormHelperText>*</FormHelperText>
                                    </FormControl> */}
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </GridItem>
                {/*Submit button*/}
                <GridItem xs={12}>
                    <Button
                        fullWidth
                        color={'info'}
                        onClick={handleSave}
                    >
                        <Icon>check</Icon>&nbsp;Sauvegarder
                    </Button>
                </GridItem>
            </GridContainer>
        </>
    );
}
