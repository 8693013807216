import axios from 'axios';
import Global from "../global";

class serviceCounties extends Global {
    /**
     *
     * @param id
     * @returns {Promise<AxiosResponse<T>>}
     */
    getListCountiesProductFromProduct(id) {
        return axios.get(this.getAPI_URL() + "products/" + id + "/counties" , this.getAuthHeader());

    }

    /**
     *
     * @param county_id
     * @param idProduct
     * @returns {Promise<AxiosResponse<T>>}
     */
    linkCountiesProduct(county_id, idProduct) {

        return axios.post(this.getAPI_URL() + "products/" + idProduct  + "/counties/" + county_id ,{product_id : idProduct, county_id: county_id} , this.getAuthHeader());
    }

    /**
     *
     * @param county_id
     * @param idProduct
     * @returns {Promise<AxiosResponse<T>>}
     */
    unLinkCountiesProduct(county_id, idProduct) {

        return axios.delete(this.getAPI_URL() + "products/" + idProduct  + "/counties/" + county_id, this.getAuthHeader());
    }

    /**
     *
     * @returns {Promise<AxiosResponse<T>>}
     */
    getAllCounties() {
        return axios.get(this.getAPI_URL() + "counties", this.getAuthHeader());
    }

    /**
     *
     * @param label
     * @returns {Promise<AxiosResponse<T>>}
     */
    newCounty(label, code) {
        let params = {
            value : label,
            code: code,
        };
        return axios.post(this.getAPI_URL() + "counties/new", params, this.getAuthHeader());
    }

    /**
     *
     * @param id
     * @returns {Promise<AxiosResponse<T>>}
     */
    deleteCounty(id) {
        return axios.delete(this.getAPI_URL() + "counties/" + id, this.getAuthHeader());
    }

    /**
     *
     * @param id
     * @param label
     * @returns {Promise<AxiosResponse<T>>}
     */
    editCounty(id, label, code) {
        let params = {
            value : label,
            code: code,
            id : id
        };
        return axios.put(this.getAPI_URL() + "counties/" + id, params, this.getAuthHeader());
    }

    /**
     *
     * @param id
     * @returns {Promise<AxiosResponse<T>>}
     */
    getCounty(id) {
        return axios.get(this.getAPI_URL() + "counties/" + id , this.getAuthHeader());
    }

}

export default new serviceCounties();