import React, {useEffect, useState} from "react";

// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import serviceSigns from "../../services/signs/signs";
import serviceProducts from "../../services/products/products";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardIcon from "../../components/Card/CardIcon.js";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import {makeStyles} from "@material-ui/core/styles";
import {Icon} from "@material-ui/core";
import CardFooter from "../../components/Card/CardFooter";
import {Link} from "react-router-dom";
import serviceCategories from "../../services/categories/serviceCategories";
import serviceOdgs from "../../services/odgs/serviceOdgs";
import serviceAddresses from "../../services/addresses/serviceAddresses";

const styles = {
    ...dashboardStyle,
    cardHeaderTitle: {
        color: "#999",
        marginTop: "0",
        minHeight: "auto",
        fontWeight: "200",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        textAlign: 'right'
    },
    cardCategory: {
        color: "#000",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        paddingTop: "0",
        marginBottom: "0",
        textAlign: 'right'
    }
};

const useStyles = makeStyles(styles);

export default function Dashboard() {
    const classes = useStyles();
    const [products, setProducts] = useState(0);
    const [signs, setSigns] = useState(0);
    const [, setAdresses] = useState(0);
    const [cat, setCat] = useState(0);
    const [, setOdgs] = useState(0);

    useEffect(() => {
        serviceProducts.getAllProductsForTable().then(res => {
            setProducts(
                res.data.data.length
            );
        }).catch(e => console.log(e));

        serviceSigns.getAllSigns().then(res => {
            setSigns(
                res.data.data.length
            );
        });

        serviceCategories.getAllCategories().then(res => {
            setCat(res.data.data.length);
        });

        serviceAddresses.getAllAddresses().then(res => {
            setAdresses(res.data.data.length);
        });

        serviceOdgs.getAllOdgs().then(res => {
            setOdgs(res.data.data.length);
        });
    }, []);

    return (
        <>
            <GridContainer>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                    <Card>
                        <CardHeader color={"success"} icon>
                            <CardIcon color={"success"}>
                                <Link to={"/admin/products"} style={{color: '#FFF'}}>
                                    <Icon>
                                        store
                                    </Icon>
                                </Link>
                            </CardIcon>
                            <h5 className={classes.cardHeaderTitle}>
                                Produits
                            </h5>
                            <p className={classes.cardCategory}>
                                {products}
                            </p>
                        </CardHeader>
                        <CardFooter chart>
                            <div className={classes.stats}>

                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                    <Card>
                        <CardHeader color={"info"} icon>
                            <CardIcon color={"info"}>
                                <Link to={"/admin/categories"} style={{color: '#FFF'}}>
                                    <Icon>
                                        folder
                                    </Icon>
                                </Link>
                            </CardIcon>
                            <h5 className={classes.cardHeaderTitle}>
                                Categories
                            </h5>
                            <p className={classes.cardCategory}>
                                {cat}
                            </p>
                        </CardHeader>
                        <CardFooter chart>
                            <div className={classes.stats}>

                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                {/*<GridItem xs={12} sm={6} md={4} lg={3}>
                    <Card>
                        <CardHeader color={"warning"} icon>
                            <CardIcon color={"warning"}>
                                <Link to={"/admin/addresses"} style={{color: '#FFF'}}>
                                    <Icon>
                                        account_box
                                    </Icon>
                                </Link>
                            </CardIcon>
                            <h5 className={classes.cardHeaderTitle}>
                                Producteurs
                            </h5>
                            <p className={classes.cardCategory}>
                                { addresses }
                            </p>
                        </CardHeader>
                        <CardFooter chart>
                            <div className={classes.stats}>

                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>*/}
                <GridItem xs={12} sm={6} md={4} lg={3}>
                    <Card>
                        <CardHeader color={"rose"} icon>
                            <CardIcon color={"rose"}>
                                <Link to={"/admin/signs"} style={{color: '#FFF'}}>
                                    <Icon>
                                        local_offert
                                    </Icon>
                                </Link>
                            </CardIcon>
                            <h5 className={classes.cardHeaderTitle}>
                                Signes
                            </h5>
                            <p className={classes.cardCategory}>
                                {signs}
                            </p>
                        </CardHeader>
                        <CardFooter chart>
                            <div className={classes.stats}>

                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                {/* ODG
                <GridItem xs={12} sm={6} md={4} lg={3}>
                    <Card>
                        <CardHeader color={"primary"} icon>
                            <CardIcon color={"primary"}>
                                <Link to={"/admin/odgs"} style={{color: '#FFF'}}>
                                    <Icon>
                                        local_offert
                                    </Icon>
                                </Link>
                            </CardIcon>
                            <h5 className={classes.cardHeaderTitle}>
                                ODG
                            </h5>
                            <p className={classes.cardCategory}>
                                {odgs}
                            </p>
                        </CardHeader>
                        <CardFooter chart>
                            <div className={classes.stats}>

                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>*/}
            </GridContainer>
        </>
    );
}
