import React, {useEffect, useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle";
import {makeStyles} from "@material-ui/core/styles";
import Button from "../../CustomButtons/Button";
import serviceAddresses from "../../../services/addresses/serviceAddresses";
import SuccessAlert from "../../Alerts/SuccessAlert";
const styles = {
    ...dashboardStyle,
    cardHeader: {
        color: "#999",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        textAlign: 'left',
        padingBottom: "3px"
    },
};

const useStyles = makeStyles(styles);


export default function FormEditAdressProduct({idProduct}) {
    // style
    const classes = useStyles();
    // form
    const [idAddress, setIdAddress] = useState(null);
    const [nid, setNid] = useState(null);
    const [uuid, setUuid] = useState(null);
    const [headoffice, setHeadoffice] = useState(false);
    const [address, setAddress] = useState('');
    const [addressNmae, setAddressName] = useState('');
    const [cp, setCp] = useState('');
    const [pays, setPays] = useState('');
    const [city, setCity] = useState('');
    const [email, setMail] = useState('');
    const [tel, setTel] = useState('');
    const [url, setUrl] = useState('');

    useEffect(() => {
        serviceAddresses.getAddressFromProduct(idProduct).then(res => {
            if (res.data.data[0]) {
                setIdAddress(res.data.data[0].id);
                setNid(res.data.data[0].nid);
                setUuid(res.data.data[0].uuid);
                setHeadoffice(res.data.data[0].headoffice);
                setAddressName(res.data.data[0].name);
                setAddressName(res.data.data[0].name);
                setAddress(res.data.data[0].address);
                setCity(res.data.data[0].city);
                setPays(res.data.data[0].country);
                setCp(res.data.data[0].postal_code);
                setMail(res.data.data[0].email);
                setTel(res.data.data[0].phone);
                setUrl(res.data.data[0].website);
            }
        })
    }, [idProduct]);

    const handleFormSubmit = () => {
        serviceAddresses.updateAddress(idAddress, nid, uuid, addressNmae, address,city,pays, cp, tel, url, email, headoffice, idProduct, true).then(() => {
            SuccessAlert({message: 'Adresse modifiée avec succès'});
            }
        )
    };

    return (
        <>
            <FormControl fullWidth className={classes.formControl}>
                <TextField label="Intitulé" value={addressNmae}
                           onChange={(event) => setAddressName(event.target.value)}/>
                <FormHelperText>*</FormHelperText>
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
                <TextField label="Adresse" value={address}
                           onChange={(event) => setAddress(event.target.value)}/>
                <FormHelperText>*</FormHelperText>
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
                <TextField label="Ville" value={city}
                           onChange={(event) => setCity(event.target.value)}/>
                <FormHelperText>*</FormHelperText>
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
                <TextField label="Code Postal" value={cp}
                           onChange={(event) => setCp(event.target.value)}/>
                <FormHelperText>*</FormHelperText>
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
                <TextField label="Pays" value={pays}
                           onChange={(event) => setPays(event.target.value)}/><FormHelperText>*</FormHelperText>
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
                <TextField label="Email" value={email}
                           onChange={(event) => setMail(event.target.value)}/>
              <FormHelperText>*</FormHelperText>
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
                <TextField label="Site Web" value={url}
                           onChange={(event) => setUrl(event.target.value)}/>
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
                <TextField label="Téléphone" value={tel}
                           onChange={(event) => setTel(event.target.value)}/>
            </FormControl>
            <Button
                color={"success"}
                disabled={!cp && !address && !addressNmae && !city && !email}
                onClick={handleFormSubmit}
            >
                Modifier
            </Button>
        </>
    );
}