import axios from 'axios';
import Global from "../global";

class accueilService extends Global{
    getAccueil(){
        return axios.get(this.getAPI_URL() + "accueil1", this.getAuthHeader());
    }
    updateAccueil(id, accueil_header_intro, accueil_social_title, accueil_key_numbers_background, accueil_key_numbers_one, accueil_key_numbers_two, accueil_key_numbers_three, accueil_key_numbers_title,
                  accueil_search_map_sub_title, accueil_search_title, accueil_search_form_sub_title, accueil_tooltip_label_aop, accueil_tooltip_label_igp, accueil_tooltip_label_stg,accueil_tooltip_label_lr, accueil_tooltip_label_ab,
                  accueil_key_numbers_one_info_text, accueil_key_numbers_two_info_text, accueil_key_numbers_three_info_text, content_title_slider, title_accueil, desc_seo_accueil){

        let params = {
            id: id,
            accueil_header_intro : accueil_header_intro,
            accueil_social_title : accueil_social_title,
            accueil_key_numbers_background : accueil_key_numbers_background,
            accueil_key_numbers_one : accueil_key_numbers_one,
            accueil_key_numbers_two : accueil_key_numbers_two,
            accueil_key_numbers_three : accueil_key_numbers_three,
            accueil_key_numbers_title : accueil_key_numbers_title,
            accueil_search_map_sub_title : accueil_search_map_sub_title,
            accueil_search_title: accueil_search_title,
            accueil_search_form_sub_title: accueil_search_form_sub_title,
            accueil_tooltip_label_aop : accueil_tooltip_label_aop,
            accueil_tooltip_label_igp : accueil_tooltip_label_igp,
            accueil_tooltip_label_stg : accueil_tooltip_label_stg,
            accueil_tooltip_label_lr : accueil_tooltip_label_lr,
            accueil_tooltip_label_ab : accueil_tooltip_label_ab,
            accueil_key_numbers_one_info_text : accueil_key_numbers_one_info_text,
            accueil_key_numbers_two_info_text : accueil_key_numbers_two_info_text,
            accueil_key_numbers_three_info_text : accueil_key_numbers_three_info_text,
            content_title_slider: content_title_slider,
            title_accueil: title_accueil,
            desc_seo_accueil: desc_seo_accueil
        }
        return axios.put(this.getAPI_URL()+ "accueil", params, this.getAuthHeader())
    }

}

export default new accueilService();
