import React from "react";

import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import {Card} from "@material-ui/core";
import CardTitleIconPlusTextRight from "../../components/CardHeader/CardTitleIconPlusTextRight";
import CardBody from "../../components/Card/CardBody";
import FormModifDescriptions from "../../components/Forms/Descriptions/FormModifDescriptions";

export default function EditDescription(params) {

    const id = params.match.params.id;


    return (
        <>
            <br />
            <GridContainer>
                <GridItem xs={12} md={6}>
                    <Card style={{overflow: 'unset'}}>
                        <CardTitleIconPlusTextRight
                            color={'warning'}
                            icon={'forum'}
                            title={'Modification Description Label'}
                        />
                        <CardBody>
                            <FormModifDescriptions id={id} />
                        </CardBody>

                    </Card>
                </GridItem>
            </GridContainer>
        </>
    )
}


