import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import Button from "../../../components/CustomButtons/Button.js";
import {Link} from "react-router-dom";
import signsServices from "../../../services/signs/signs";
import SuccessAlert from "../../Alerts/SuccessAlert";
import GridItem from "../../Grid/GridItem.js";
import GridContainer from "../../Grid/GridContainer";
import ErrorAlert from "../../Alerts/ErrorAlert";

export default function FormNewSign(props) {
    const [label, setLabel] = useState("");
    const [abreviation, setAbreviation] = useState("");


    const handleFormSubmit = () => {
        signsServices.newSign(label, abreviation).then(res => {
            SuccessAlert({message: res.data.message});
            props.props.history.push("/admin/signs");
        }).catch(() => {
            ErrorAlert({});
        });
    };

    return (
        <>
            <GridContainer
                justify="center"
                alignItems="center"
            >
                <GridItem xs={12}>
                    <TextField
                        label="Label"
                        error={!label}
                        style={{margin: 8}}
                        placeholder="Label"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={e => {
                            setLabel(e.target.value);
                        }}
                    />
                    <TextField
                      label="Abréviation"
                      error={!abreviation}
                      style={{margin: 8}}
                      placeholder="Abréviation"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                          shrink: true
                      }}
                      onChange={e => {
                          setAbreviation(e.target.value);
                      }}
                    />
                </GridItem>
            </GridContainer>
            <GridContainer
                justify="center"
                alignItems="center"
            >
                <GridItem>
                    <Button
                        color={"success"}
                        disabled={!label || !abreviation}
                        onClick={handleFormSubmit}
                    >
                        Ajouter
                    </Button>
                </GridItem>
                <GridItem>
                    <Link to={"/admin/signs"}>
                        <Button
                            color={"danger"}
                        >
                            Annuler
                        </Button>
                    </Link>
                </GridItem>
            </GridContainer>
        </>
    );
}
