import React from "react";

import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card";
import CardTitleIconPlusTextRight from "../../components/CardHeader/CardTitleIconPlusTextRight";
import CardBody from "../../components/Card/CardBody";
import FormEditOdg from "../../components/Forms/Odgs/FormEditOdg";

export default function EditOdg(props) {

    const id = props.match.params.id;

    return (
        <>
            <GridContainer>
                <GridItem xs={12} md={6}>
                    <Card>
                        <CardTitleIconPlusTextRight
                            color={"info"}
                            icon={"list_alt"}
                            title={"Édition de l'ODG"}
                        />
                        <CardBody>
                            <FormEditOdg id={id}/>
                        </CardBody>
                    </Card>
                </GridItem>
               {/* <GridItem xs={12} md={6}>
                    <Card>
                        <CardTitleIconPlusTextRight
                            color={"warning"}
                            icon={"format_align_left"}
                            title={"Catégories de l'ODG"}
                        />
                        <CardBody>
                            <FormEditOdgCat id={id}/>
                        </CardBody>
                    </Card>
                </GridItem>*/}
            </GridContainer>
        </>
    );

}
