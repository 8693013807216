import React from "react";
//core components
import FormGroup from "@material-ui/core/FormGroup";
import {DropzoneArea} from "material-ui-dropzone";
import products from "../../../services/products/products";
import SuccessAlert from "../../Alerts/SuccessAlert";



export default function FormEditGeoJson({idProduct}) {


    const handleChangeGeo = (files) => {
        try {
            let reader = new FileReader();
            reader.readAsText(files[0]);
            reader.onload = function () {
                products.getProduct(idProduct).then(res => {
                    products.editProduct(idProduct, res.data.data.value, res.data.data.id_siqo, reader.result, res.data.data.uuid, res.data.data.nid, res.data.data.status).then(() => {
                            SuccessAlert({message: 'Géojson mis à jour avec succès'});
                        }
                    )
                });
            };
        } catch (error) {
        }

    };


    return (
        <FormGroup>
            <DropzoneArea
                acceptedFiles={['text/*', 'application/*', '']}
                dropzoneText={'Déposer un fichier ici'}
                onChange={handleChangeGeo}
                showPreviewsInDropzone={true}
                showPreviews={false}
                filesLimit={1}
            />
        </FormGroup>
    );
}
