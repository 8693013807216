import axios from 'axios';
import Global from '../global';


class serviceOdgs extends Global {


    createOdg(label){
        return axios.post(this.getAPI_URL() + "odgs/new", {name: label} , this.getAuthHeader());
    }


    updateOdg(product_id, idOdg, label){
        return axios.put(this.getAPI_URL() + "odgs/"+ idOdg, {id: idOdg, name: label} , this.getAuthHeader());
    }


    createOdgProduct(product_id, odg_id) {
        return axios.post(this.getAPI_URL() + "products/" + product_id + "/odgs/"+ odg_id, {odg_id: odg_id, product_id: product_id} , this.getAuthHeader());
    }


    getOdgFromProduct(product_id) {
        return axios.get(this.getAPI_URL() + "products/" + product_id + "/odgs", this.getAuthHeader());
    }

    getCategoriesFromOdg(id) {
        return axios.get(this.getAPI_URL() + "odgs/" + id + "/categories", this.getAuthHeader());
    }


    getOdg(odg_id) {
        return axios.get(this.getAPI_URL() + "odgs/" + odg_id, this.getAuthHeader());
    }


    getAllOdgs() {
        return axios.get(this.getAPI_URL() + "odgs", this.getAuthHeader());
    }


    newOdg(label) {
        let params = {
            name : label,
        };
        return axios.post(this.getAPI_URL() + "odgs/new", params, this.getAuthHeader());
    }


    deleteOdg(id) {
        return axios.delete(this.getAPI_URL() + "odgs/" + id, this.getAuthHeader());
    }

    deleteOdgsFromProduct(id) {
        return axios.delete(this.getAPI_URL() + `products/${id}/odgs`, this.getAuthHeader());
    }


    editOdg(id, label, uuid, target_id) {
        let params = {
            name : label,
            uuid : uuid,
            target_id: target_id,
            id : id
        };
        return axios.put(this.getAPI_URL() + "odgs/" + id, params, this.getAuthHeader());
    }

}

export default new serviceOdgs();