import {Dashboard, HomeRounded, MeetingRoom, Person, Settings, Store} from "@material-ui/icons";
import EditMetaSEO from "./views/MetaSEO/EditMetaSEO";
import DashboardPage from "./views/Dashboard/Dashboard";
import AccueilView from "./views/Accueil/AccueilView";
import PagesHome from "./views/Pages/PagesHome";
import Logout from "./views/Logout/Lougout";

const dashboardSections = [
    {
        path: "/dashboard",
        name: "DASHBOARD",
        nameDisplay:"DASHBOARD",
        desc: "Dashboard",
        display: true,
        icon: Dashboard,
        component: DashboardPage,
        layout: "/admin",
        section : "",
    },
    {
        name: "FicheProduits",
        nameDisplay: "GESTION DES DONNEES",
        icon: Dashboard
    },
    {
        path: "/pages",
        name: "GESTION DES PAGES",
        nameDisplay:"GESTION DES PAGES",
        desc: "Gestion des Pages",
        display: true,
        icon: Dashboard,
        component: PagesHome,
        layout: "/admin",
        section : "pagesDisplay"
    },
    /*    {
            path: "/accueil",
            name: "ACCUEIL",
            nameDisplay: "ACCUEIL",
            icon: HomeRounded,
            desc: "Gestion de la page d'accueil",
            display: true,
            component: AccueilView,
            layout: "/admin",
            section : ""
        },*/
    {
        name: "Parametres",
        nameDisplay: "PARAMETRES",
        icon: Settings
    },
    {
        path: "/logout",
        name: "Se déconnecter",
        nameDisplay:"SE DECONNECTER",
        icon: MeetingRoom,
        display: true,
        component: Logout,
        layout: "/admin",
        section : ""
    },
    /*{
        name: "pagesDisplay",
        nameDisplay: "GESTION DES PAGES",
        icon: Person
    }*/
]

export default dashboardSections;
