import React, {useEffect, useState} from "react";
import serviceProducts from "../../services/products/products";
import Button from "../../components/CustomButtons/Button";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import {Icon, makeStyles} from "@material-ui/core";
import {Link} from 'react-router-dom';
import MaterialTable from "material-table";
import Tooltip from "@material-ui/core/Tooltip";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import Swal from "sweetalert2";
import Paper from "@material-ui/core/Paper";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import {Assignment} from "@material-ui/icons";
import CardIcon from "../../components/Card/CardIcon";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import CardBody from "../../components/Card/CardBody";

const styles = {
  ...dashboardStyle,
  cardTitle: {
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    color: "#999"
  }
};

const useStyles = makeStyles(styles);

export default function ProductsHome() {
  const [products, setProducts] = useState([]);
  const classes = useStyles();
  const columns = [
    {
      title: 'Nom',
      field: 'value',
      render: (row) => row.value.replace(/['"'{'}]+/g, ''),
      defaultSort: "asc"
    },
    {
      title: 'Catégories',
      field: 'categories',
      render: (row) => row.categories.replace(/['"'{'}]+/g, ''),
    },
    {
      title: 'Signes',
      field: 'signs',
      render: (row) => row.signs.replace(/['"'{'}]+/g, ''),
    },
    {
      title: 'Actions',
      field: 'id',
      export: false,
      render: (row) => {
        return (
          <>
            <Tooltip
              title="Visualiser"
            >
              <Link to={'/admin/show/' + row.id + '/product'}>
                <Button justIcon={true} round={true} color={'info'}>
                  <Icon>
                    remove_red_eye
                  </Icon>
                </Button>
              </Link>
            </Tooltip>
            <Tooltip
              title="Éditer"
            >
              <Link to={'/admin/edit/products/' + row.id}>
                <Button justIcon={true} round={true} color={'primary'}>
                  <Icon>
                    edit
                  </Icon>
                </Button>
              </Link>
            </Tooltip>
            <Tooltip
              title="Supprimer"
            >
               <span>
                   <Button
                     justIcon={true}
                     round={true}
                     onClick={() => deleteAction(row.id)}
                     color={'danger'}
                   >
                       <Icon>
                           delete
                       </Icon>
                   </Button>
               </span>
            </Tooltip>
          </>
        )
      },
      headerStyle: {textAlign: 'right'},
      cellStyle: {textAlign: 'right'},
    },
  ];
  const deleteAction = (id) => {
    Swal.fire({
      title: 'Voulez-vous vraiment supprimer ce produit?',
      text: "Cette action est irreversible !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Supprimer!',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.value) {
        serviceProducts.deleteProduct(id).then(() => {
          SuccessAlert({message: 'Produit supprimé avec succès !'});
          serviceProducts.getAllProductsForTable().then(res => {
            setProducts(
              res.data.data
            );
          }).catch(() => {
            setProducts(
              []
            );
          });
        }).catch(() => {
          Swal.fire('Une erreur est survenue !').then();
        })
      }
    })
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {

    serviceProducts.getAllProductsForTable().then(res => {
      setProducts(res.data.data);
    }).catch(() => {
      setProducts([]);
    });
  };


  return (
    <>
      <GridContainer
          justify={'flex-end'}
      >
          <GridItem xs={12} md={4} lg={4}>
              <Link to={'/admin/products/new'}>
                  <Button type={"button"} color={"success"}>
                      <Icon>add</Icon>
                      Ajouter un produit
                  </Button>
              </Link>
          </GridItem>
          <GridItem xs={12} md={12} lg={12}>
              <Card>
                  <CardHeader
                      icon
                      color={'primary'}
                  >
                    <CardIcon color="primary">
                      <Assignment />
                    </CardIcon>
                    <h4 className={classes.cardTitle}>Liste des produits</h4>
                  </CardHeader>
                <CardBody>
                  <MaterialTable
                    components={{
                      Container: props => <Paper {...props} elevation={0}/>
                    }}
                    title={''}
                    localization={{
                      toolbar: {
                        searchPlaceholder: "Rechercher",
                        searchTooltip: "Rechercher",
                      },
                    }}
                    showTitle={' '}
                    columns={columns}
                    data={products}
                    options={
                      {
                        pageSize: 50,
                        pageSizeOptions: [5, 10, 20, 50],
                        sorting: true,
                        searchPlaceholder: 'Rechercher'
                      }
                    }
                  />
                </CardBody>

              </Card>
          </GridItem>
      </GridContainer>
    </>
  );
}
